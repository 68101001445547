const InstantiationSnippet = `
window.addEventListener('load', ()=>{
  const highjacked = new rwxSkrollHighjack(
    true,
    [...document.querySelectorAll('.ignore-my-scroll')],
    (scrollTop)=>console.log("Scroll Top", scrollTop)
  );
});
`

const HTMLSnippet = `
<div style="height:100vh" id="{{ UNIQUE ID }}"  data-rwx-skroll-highjack-section  data-rwx-skroll-highjack-section-title="{{ SECTION TITLE }}">
  {{ ANY CONTENT }}
</div>
<div style="height:100vh" id="{{ UNIQUE ID }}"  data-rwx-skroll-highjack-section  data-rwx-skroll-highjack-section-title="{{ SECTION TITLE }}">
  {{ ANY CONTENT }}
</div>
<div style="height:100vh" id="{{ UNIQUE ID }}"  data-rwx-skroll-highjack-section  data-rwx-skroll-highjack-section-title="{{ SECTION TITLE }}">
  <div style="overflow:scroll;height:300px" class=".ignore-my-scroll">
    <!--This content is likely to be scrollable-->
  </div>
</div>
<div style="height:100vh" id="{{ UNIQUE ID }}"  data-rwx-skroll-highjack-section  data-rwx-skroll-highjack-section-title="{{ SECTION TITLE }}">
  {{ ANY CONTENT }}
</div>
`;

const GoToSnippet = `
highjacked.goTo(1);
highjacked.goTo('page3');
`;

const UnhookSnippet = `
highjacked.unhook();
`;


const SkrollHighjackData = {
  resourceName: 'rwxSkrollHighjack',
  roseworxPath: 'modules',
  componentType: 'individualJS',
  manualControl: false,
  roseworxCore: false,
  renew: false,
  description: [
    "The rwxSkrollHighjack module allows you, as the name suggests, to highjack the scroll away from the user and control it yourself to create a neat, modern & seamless experience that guides the user through your content.",
    "Supply a list of html elements and whenever the page is scrolled, rwxSkrollHighjack will takeover and scroll smoothly from element to element (based on the direction of the scroll), like this page."
  ],
  methods: [
    {
      name: 'Constructor',
      returnType: 'Class',
      description: 'Instantiate Skroll Highjack. Must be called on or after DOMContentLoaded event.',
      parameters: [
        {
          name: 'Has navigation',
          type: 'Boolean'
        },
        {
          name: 'Ignore scroll on elements',
          type: 'Array of elements'
        },
        {
          name: 'Scroll callback',
          type: 'Function',
        },
      ],
      example: InstantiationSnippet
    },
    {
      name: 'goTo',
      returnType: 'Void',
      description: 'Programatically scroll to element. The id supplied must be on an element with the [data-rwx-skroll-highjack-section] attribute. The number equates to the nth element with the [data-rwx-skroll-highjack-section] attribute.',
      parameters: [
        {
          name: 'id of element OR number',
          type: 'String || Number'
        }
      ],
      example: GoToSnippet
    },
    {
      name: 'unhook',
      returnType: 'Void',
      description: 'Completely detaches the skroll highjack and returns the page to normal.',
      example: UnhookSnippet
    }
  ],
  steps: [
    {
      heading: "HTML example",
      snippet: HTMLSnippet,
      language: "html",
      extra: [
        "Assume this html example is in your document, Replacing any {{ }} values with your personal preferences.",
        "The data-rwx-skroll-highjack-section denotes that this element will be used as a scroll point.",
        "The data-rwx-skroll-highjack-section-title will be the title which appears in the created navigation, providing the first parameter in the constructor (has navigation) is set to true.",
        "Notice the .ignore-my-scroll class, this will be used in the next step to tell rwxSkrollHighjack to not highjack scroll for this particular element as it has, or is likely to have, its own overflow and scroll.",
        "The id attribute can be used to programatically scroll to a particular section using the goTo method."
      ]
    },
    {
      heading: "Instantiate",
      snippet: InstantiationSnippet,
      extra: [
        "Must be called on or after DOMContentLoaded event. The Constructor accepts 3 parameters;",
        "The first is a Boolean value to state wheather to insert a navigational panel so scrolling is not the only way to navigate.",
        "The second is an array of elements that this module will ignore the scroll event on. These elements can then be scrolled normally without the event being highjacked.",
        "The third is a callback that will get called during scrolling with the Y value of the current scroll."
      ]
    }
  ],
  notes: [
    {
      note: "This module only works for scrolling on the document body element. This will not work for Interent Explorer.",
      icon: "warning"
    },
    {
      note: "Skroll Highjacking has no effect on other Roseworx components that rely on scroll to commence. They will still get executed normally when this module is in use.",
      icon: "star"
    },
    {
      note: "If a [data-rwx-skroll-highjack-section] element is too close to the bottom of the page to be scrolled to the top of the viewport, the page will get scrolled to however far it can go.",
      icon: "note"
    },
    {
      note: "This module is accessible to an extent. If an element inside a [data-rwx-skroll-highjack-section] receives focus via keyboard tabbing, this will become the active section and thus scrolling up or down will transition from this section.",
      icon: "star"
    }
  ]
}

export default SkrollHighjackData