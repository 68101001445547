import React,{useState} from 'react';

function RadioGroup({data, name, classes}) {
	const [v, setv] = useState(null);

	const click = (i) =>{
		if(v===i)setv(null)
	}

	return (
		<div className="rwx-form-radio-group --inline">
			{
				data.map((rg, i)=>
					<div className={`rwx-form-item rwx-radio ${classes}`} key={`radio-group-item-${i}`} >
						<input type="radio" onClick={()=>click(i)} name={`radio-group-${name}`} id={`radio-group-item-${rg.key}-${i}`} onChange={()=>setv(i)} value={v!==null ? data[v].key : ""} checked={v===i}/>
						{rg.display && <label htmlFor={`radio-group-item-${rg.key}-${i}`} title={rg.description}>{rg.display}</label>}
					</div>					
				)
			}
		</div>
	)
}

function RadioGroupNoFunctionality({name, data, classes, readonly, disabled, groupName}) {
	return (
		<div className={`rwx-form-radio-group ${classes}`}>
			<p>{groupName}</p>
			{
				data.map((rg, i)=>
					<div className={`rwx-form-item rwx-radio ${classes}`} key={`radio-group-item-${i}`} >
						<input type="radio" name={`radio-group-${name}`} id={`radio-group-item-${rg.key}-${i}`} readOnly={readonly} disabled={disabled}/>
						<label htmlFor={`radio-group-item-${rg.key}-${i}`} title={rg.description}>{rg.display}</label>
					</div>					
				)
			}
		</div>
	)
}

export default RadioGroup;
export {RadioGroupNoFunctionality}