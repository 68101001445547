import React from 'react';

import rwxGridGalleries from 'roseworx/js/components/rwxGridGalleries';

import StandardLayout from '../../layouts/StandardLayout';

function GridGalleriesPage({data}) {
	const ggitems = Array.from(Array(14).keys());

	const GGItem = ({index}) => {
		return (
			<div className="rwx-grid-gallery-item">
				<h5 className="rwx-grid-gallery-heading">Heading {index+1}</h5>
				<div role="img" className="rwx-grid-gallery-overlay" style={{backgroundImage: `url('https://picsum.photos/500/500?v=${index+1}')`}}></div>
			</div>
		)
	}

	const HTML = ({id, config}) =>{
		return (
			<div className="rwx-grid-gallery" id={id}>
				{
					ggitems.map((i)=><GGItem index={i} key={`grid-gallery-item-demo-${i}`}/>)
				}
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxGridGalleries} 
			LIVEHTML={HTML}
		/>
	)
}

export default GridGalleriesPage;