import React, {useContext} from 'react';

import {DataContext} from '../../index';

import Back from '../../components/Back';

import Description from '../../structural/Description';
import PageBreak from '../../structural/PageBreak';
import Table from '../../structural/Table';

function ThemesPage({data}) {

  const SiteData = useContext(DataContext);

  SiteData.allSCSS && data.scssVariables.map((sv)=>{
    sv.variables = [];
    data.themeMappings[sv.key].map((m)=>{
      Object.keys(SiteData.allSCSS).map((k)=>{
        if(SiteData.allSCSS[k].variables)
        {
          const found = SiteData.allSCSS[k].variables.find((f)=>f.key===m);
          if(found)
          {
            sv.variables.push(found);
          }
        }
        return false;
      });
      return false;
    });
    return false;
  });

  return SiteData.allSCSS ?  <>
      <Back />
      <PageBreak />
      <div className="rwx-split explicit non-fluid">
        <div className="rwx-split-full">
          <Description paragraphs={data.description} />
          <PageBreak />
          {
            data.scssVariables.map((sv,i)=><div className={`${i>0 ? 'rwxs-m-t-xl rwxs-p-t-xl' : ''}`} key={`toolbox-themes-scss-variable-${sv.key}-${i}`}>
                <p><strong className="impact">{sv.key}: {sv.value}</strong></p>
                <p className="rwxs-m-b-lg">{data.sentence1}</p>
                <Table name="SCSS Variable" values={sv.variables} id={`toolbox-themes-scss-variable-${sv.key}`}/>
                <p className="rwxs-m-t-lg">{data.sentence2}</p>
              </div>
            )
          }
        </div>
      </div>
    </>
  :
    null
}

export default ThemesPage;