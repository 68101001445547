const LoadingSnippet = `
<div class="rwx-loading"></div>
<p rwx-loading></p>
`;

const LoadingsChangeLoadingMixinSnippet = `
div.change-loading-color
{
  @include change-loading-color($black, $orange);
}
`;

const LoadingData = {
  selector: 'rwx-loading',
  roseworxPath: 'effects',
  componentType: 'coreSCSS',
  snippet: LoadingSnippet,
  manualControl: false,
  roseworxCore: false,
  renew: false,
  description: [
    "Visually indicate that content is loading.",
    "Add a .rwx-loading class OR a [rwx-loading] attribute to any element."
  ],
  scssMixins: [
    {
      snippet: LoadingsChangeLoadingMixinSnippet,
      title: "Change Loading Color",
      description: "Change the color of indiviudal loadings. This example will change the color of the loading on a <div> with class .change-loading-color using $black as the background and $orange as the color."
    }
  ],
  scssVariables: [
    {
      key: "$rwx-loading-background-color",
      description: "The background color of the loading effect. This will be 0.5 opaque.",
      value: "$grey",
      options: ["Any valid CSS color"]
    },
    {
      key: "$rwx-loading-color",
      description: "The color of the loading bar.",
      value: "$white",
      options: ["Any valid CSS color"]
    },
  ]
}

export default LoadingData;