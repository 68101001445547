import React from 'react';

import StandardLayout from '../../layouts/StandardLayout';

import {parseClasses} from '../../helpers';

function ButtonsPage({data}) {
	const HTML = ({id, config}) =>{
		const d = config.demo.find((da)=>da.key==="disabled").value;
		return (
			<div className="rwxu-flex-center">
				<button className={`${parseClasses(config.classes)}`} disabled={d==="true"}>Button</button>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={null} 
			LIVEHTML={HTML}
			useExamples={true}
		/> 
	)
}

export default ButtonsPage;