import React from 'react';

function Input({data, classes, readonly, disabled, invalidMessage}) {

	return (
		<div className={`rwx-form-item ${classes===undefined ? '--inline valid' : classes}`}>
			<input type={data.type} name={data.key} id={data.key} key={data.value} defaultValue={data.value} readOnly={readonly} disabled={disabled}/>
			{data.display && <label htmlFor={data.key} title={data.description}>{data.display}</label>}
			{
				invalidMessage && <p className="invalid-message">{invalidMessage}</p>
			}
		</div>
	)
}

export default Input;