const SlideSnippet = `
const element = document.getElementById('slide');
rwxSlide.slideDown(element);
element.addEventListener('click', ()=>{
  rwxSlide.slideUp(element);
});
`;

const SlideData = {
  description: ["Slide up / down / toggle any content."],
  selector: 'rwx-slide',
  roseworxPath: 'effects',
  componentType: 'individualJS',
  manualControl: false,
  roseworxCore: false,
  renew: false,
  snippet: SlideSnippet,
  methods: [
    {
      name: 'slideDown',
      returnType: 'void',
      description: 'Slide an element down. Supply a callback and it will be ran once the content has been slid.',
      example: ".slideDown({HTMLElement}, {duration}, {callback});",
      interactable: true,
      useElement:true,
      interactableInputs: [
        {
          key: 'getDuration',
          type: 'number',
          value: "1",
          display: "Duration"
        },
      ],
      parameters: [
        {
          name: 'element',
          type: 'HTMLElement'
        },
        {
          name: 'duration',
          type: 'Number'
        },
        {
          name: 'callback',
          type: 'Function'
        },
      ]
    },
    {
      name: 'slideUp',
      returnType: 'void',
      description: 'Slide an element up. Supply a callback and it will be ran once the content has been slid.',
      example: ".slideUp({HTMLElement}, {duration}, {callback});",
      interactable: true,
      useElement:true,
      interactableInputs: [
        {
          key: 'getDuration2',
          type: 'number',
          value: "1",
          display: "Duration"
        },
      ],
      parameters: [
        {
          name: 'element',
          type: 'HTMLElement'
        },
        {
          name: 'duration',
          type: 'Number'
        },
        {
          name: 'callback',
          type: 'Function'
        },
      ]
    },
    {
      name: 'slideToggle',
      returnType: 'void',
      description: 'Determines wheather to slide down / up content depending on of its currently visible or not. Supply a callback and it will be ran once the content has been slid.',
      example: ".slideToggle({HTMLElement}, {duration}, {callback});",
      interactable: true,
      useElement:true,
      interactableInputs: [
        {
          key: 'getDuration3',
          type: 'number',
          value: "1",
          display: "Duration"
        },
      ],
      parameters: [
        {
          name: 'element',
          type: 'HTMLElement'
        },
        {
          name: 'duration',
          type: 'Number'
        },
        {
          name: 'callback',
          type: 'Function'
        },
      ]
    }
  ]
}

export default SlideData;