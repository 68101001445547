import React,{useContext} from 'react';

import {DataContext} from '../../index';

import Back from '../../components/Back';

import Table from '../../structural/Table';
import PageBreak from '../../structural/PageBreak';

import TabbedLayout from '../../layouts/TabbedLayout';

function CssPage({data}) {
  const SiteData = useContext(DataContext);

  const tabcontent = [];

  SiteData.allClasses && Object.keys(SiteData.allClasses).map((k)=>{
    const a = SiteData.allClasses[k];
    if(a.utilityClasses.length === 0 && a.modifierClasses.length ===0)return false
    tabcontent.push({
      title: k,
      id: `css-${k}`,
      content: <div className="rwx-split explicit non-fluid">
        <div className="rwx-split-full">
          { a.utilityClasses.length > 0 && <>
              <p><strong className="impact">Utility Classes</strong></p>
              <Table name="Class" values={a.utilityClasses} rootElement={a.rootElement} noDefault={true} id={`toolbox-css-${k}-utilityclasses`}/>
            </>
          }
          { a.modifierClasses.length > 0 && <div className="rwxs-m-t-xl">
              <p><strong className="impact">Modifier Classes</strong></p>
              <Table name="Class" values={a.modifierClasses} rootElement={a.rootElement} noDefault={true} id={`toolbox-css-${k}-modifierclasses`}/>
            </div>
          }
        </div>
      </div>
    });
    return false;
  })

  return SiteData.allClasses ? <>
      <Back />
      <PageBreak />
      <TabbedLayout tabs={tabcontent} description={data.description} />
    </>
  :
    null
}

export default CssPage;