  const TimelinesSnippet = `
<div class="rwx-timeline" rwx-timeline id="{{ UNIQUE ID }}">
  <div class="rwx-timeline-tracker">
    {{ ANY HTML }}
  </div>
  <div class="rwx-timeline-tracker">
    {{ ANY HTML }}
  </div>
  <div class="rwx-timeline-tracker">
    {{ ANY HTML }}
  </div>
</div>
  `;

const TimelinesData = {
  selector: "rwx-timeline",
  roseworxPath: "components",
  componentType: "individualJSAndSCSS",
  manualControl: true,
  roseworxCore: true,
  renew: true,
  snippet: TimelinesSnippet,
  scssVariables: [
    {
      key: "$rwx-timeline-ring-color",
      description: "Color of the ring.",
      value: "$blue",
      options: ["Any valid CSS color"]
    },
    {
      key: "$rwx-timeline-line-color",
      description: "Color of the timeline.",
      value: "$grey",
      options: ["Any valid CSS color"]
    },
    {
      key: "$rwx-timeline-fill-color",
      description: "Color for the fill of the circle.",
      value: "$grey",
      options: ["Any valid CSS color"]
    },
  ],
  notes: [
    {
      note: "The speed that the tracker moves down the timeline is not constant, it is calculated based on the height of the content.",
      icon: "star"
    }
  ]
}

export default TimelinesData;