import React from 'react';

import useRoseworx from 'roseworx/js/useRoseworx';
import rwxMisc from 'roseworx/js/helpers/rwxMiscHelpers';

function DemoBox({children, small, noParallax, isChild}) {
	const id = rwxMisc.uniqueId();
	useRoseworx(window.rwx.skrollx, `demo-box-${id}`)

	return (
		<div className={`rwx-split explicit ${isChild ? 'align' : 'non-fluid'}`} style={{marginBottom: isChild ? "": "64px"}}>
			<div className="rwx-split-full">
				<div className={`radius demo-box rwxs-p-lg ${small ? 'small rwxs-m-t-lg' : ''}`} rwxpx={noParallax ? "false" : "true"} data-rwxpx-speed="5" id={`demo-box-${id}`}>
					<div>
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}

export default DemoBox;