import {FullSizeParentNote, AccesibilityNote, AddFixedForFullScreen} from '../../data';

const OptionSelectorsSnippet = `
<div class="rwx-option-selector" rwx-option-selector id="{{ UNIQUE ID }}">
  <div class="rwx-option-selector-item" data-rwx-option-selector-value="{{ OPTION VALUE }}"><span>{{ DISPLAY NAME }}</span></div>
  <div class="rwx-option-selector-item" data-rwx-option-selector-value="{{ OPTION VALUE }}"><span>{{ DISPLAY NAME }}</span></div>
</div>
`;

const OptionSelectorsData = {
  selector: "rwx-option-selector",
  componentType: "individualJSAndSCSS",
  roseworxPath: "components",
  manualControl: true,
  roseworxCore: true,
  renew: true,
  snippet: OptionSelectorsSnippet,
  dependancy: {
    type: "scss",
    dependancy: "rwxAnimatedBorders",
    link: "/effects/animatedborders",
    roseworxPath: "effects"
  },
  config: {
    demo: [
      {
        key:"number-of-options",
        value: "2",
        type: "select",
        options: ["1", "2", "3", "4", "5", "6"],
        description: "The number of options to display in the demonstration.",
        display: "Number of options"
      }
    ],
    dataAttributes: [
      {
        key: "data-rwx-option-selector-value",
        applyToSelector: "[rwx-option-selector] .rwx-option-selector-item",
        value: "N/A",
        type: "hidden",
        options: ["Any string"],
        description: "The value to report to the onSelected callback when the particular option is selected."
      },
      {
        key: "data-rwx-option-selector-closeable",
        value: "false",
        type: "checkbox",
        options: ["false", "true"],
        display: "Closeable",
        description: "If set to true, the option selector can be closed with the ESC key when one of it's buttons is in focus. Pressing the ESC will not run any method specified on the onSelected custom event."
      }
    ]
  },
  methods: [
    {
      name: 'onSelected',
      returnType: 'Void',
      description: 'Sets a callback to call when an option has been selected with the options value as a parameter to the function.',
      example: ".onSelected('{ID}', (val)=>console.log(val))",
      interactable: false,
      parameters: [
        {
          name: 'ID',
          type: 'String'
        },
        {
          name: 'Callback',
          type: 'Function'
        }
      ]
    }
  ],
  scssVariables: [
    {
      key: "$rwx-option-selectors-colors",
      value: "( $orange, $light-blue, $light-green, $yellow, $pink, $purple )",
      description: "SCSS map of HEX colors, the index of the color corresponds to the index of the .rwx-option-selector-item.",
      options: ["SCSS Map"]
    }
  ],
  notes: [
    FullSizeParentNote,
    AccesibilityNote,
    AddFixedForFullScreen('[rwx-option-selector]'),
    {
      note: "You can use between 1-6 .rwx-option-selector-item elements. Any more than 6 will get hidden.",
      icon: "star"
    }
  ]
}

export default OptionSelectorsData;