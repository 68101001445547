import {ToManyBitFxNote, MouseMoveNote, FullSizeParentNote, InfiniteAnimationMethods} from '../../data';

import getBitFxConfig,{BitFxSnippet} from './bitfx';

const BitSwarmsConfig = getBitFxConfig('data-rwx-bit-swarm');

const BitSwarmsData = {
  selector: "rwx-bit-swarm",
  config: BitSwarmsConfig,
  componentType: "individualJS",
  roseworxPath: "bitfx",
  manualControl: true,
  roseworxCore: true,
  renew: true,
  notes: [
    ToManyBitFxNote, 
    MouseMoveNote,
    FullSizeParentNote,
    {
      note: "This component will run random 'reshuffle' animations at regular intervals, bash the particles around with the mouse!",
      icon: "hint"
    }
  ],
  methods: InfiniteAnimationMethods
}

BitSwarmsData.snippet = BitFxSnippet(BitSwarmsData.selector, BitSwarmsData.config.dataAttributes);

export default BitSwarmsData;