import React from 'react';

import HelperLayout from '../../layouts/HelperLayout';

function GeometryHelpersPage({data}) {

  return (
    <HelperLayout data={data} />
  )
}

export default GeometryHelpersPage;