import React from 'react';
import Section from '../structural/Section';
import Method from '../structural/Method';
import {CoreMethods, CoreAccessMessage, AfterPageLoad, IDRecommendation, ManualControlMethod, RenewMethod} from '../data';

function Methods({methods, component, coreAccess, manualControl, roseworxCore, renew, hashPrepend}) {

	const joinComponentToMethod = (m) => {
		return m ? `${component}${m}` : m;
	}

	return (
		<Section title="Methods" hashPrepend={hashPrepend} classes="methods-section">
			<div className="rwx-split non-fluid explicit rwxs-p-t-sm">
				<div className="rwx-split-full">
					<p className="warning">{AfterPageLoad}</p>
					{
						roseworxCore && <p className="note">{IDRecommendation}</p>
					}
					{
						coreAccess && <p className="note">{CoreAccessMessage(coreAccess, component)}</p>
					}
					{
						methods && methods.map((m, i)=><Method hashPrepend={hashPrepend} key={`additional-method${i}`} {...m} example={component ? joinComponentToMethod(m.example) : m.example}/>)
					}
					{
						roseworxCore && CoreMethods.map((cm, i)=> <Method hashPrepend={hashPrepend} {...cm} example={joinComponentToMethod(cm.example)} key={`core-method${i}`}/>)
					}
					{
						manualControl && <Method hashPrepend={hashPrepend} {...ManualControlMethod} example={joinComponentToMethod(ManualControlMethod.example)} />
					}
					{
						renew && <Method hashPrepend={hashPrepend} {...RenewMethod} example={joinComponentToMethod(RenewMethod.example)} />
					}
				</div>
			</div>
		</Section>
	)
}

export default Methods;