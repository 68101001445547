import React from 'react';

import StandardLayout from '../../layouts/StandardLayout';

import PageBreak from '../../structural/PageBreak';

import {parseDataAttributes} from '../../helpers';

function LavaLampsPage({data}){
  const HTML = ({id, config}) =>{
    return (
      <div className="rwxu-full-size"><div id={id} className="rwx-lava-lamp" {...parseDataAttributes(config.dataAttributes)}></div></div>
    )
  }

  return (
    <>
      <StandardLayout 
        data={data} 
        rwxComponent={window.rwx.lavaLamps}
        LIVEHTML={HTML}
      />
      <PageBreak />
      <div className="rwx-split explicit non-fluid">
        <div className="rwx-split-full">
          <p className="note">You can change the color of the bubbles by setting the <i>fill</i> property on the CSS selector <i>.rwx-lava-lamp path</i></p>
        </div>
      </div>
    </>
  )
}

export default LavaLampsPage;