import Prism from 'prismjs';
var HTMLFormatter = require('html-formatter');
var JSFormatter = require('js-beautify').js;
require('prismjs/plugins/normalize-whitespace/prism-normalize-whitespace');
Prism.hooks.add('before-sanity-check', (env) => {
  env.code = env.element.textContent;
});
Prism.manual = true;

export function useCopy() {
	if(!window.copyClipboard)
	{
		let copyClipboard = document.createElement('textarea');
		copyClipboard.id = "copy-to-clipboard";
		copyClipboard.style.position = "fixed";
		copyClipboard.style.width = "0px";
		copyClipboard.style.height = "0px";
		document.body.appendChild(copyClipboard);
		window.copyClipboard = copyClipboard;
	}
	return (data, language)=>{
		let touse = data;
		if(language === 'javascript')touse = JSFormatter(data,{brace_style:"collapse,preserve-inline"});
		else if(language === 'html')touse = HTMLFormatter.render(data);
		window.copyClipboard.value = touse;
		let copyText = window.copyClipboard;
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand("copy");
	}
}

export function useCodeHighlight(data, language){

	return data ? Prism.highlight(data.trim(), Prism.languages[language], language) : null;
}