import React from 'react';

import {parseDataAttributes, parseClasses} from '../../helpers';

import StandardLayout from '../../layouts/StandardLayout';

function SlidersPage({data}) {

	const demoData = ['green', 'red', 'blue', 'orange', 'light-blue'];

	const HTML = ({id, config}) => {
		const parsed = parseClasses(config.classes);
		return (
			<div className={`rwx-slider ${parsed}`} id={id} {...parseDataAttributes(config.dataAttributes)}>
				{
					demoData.map((c, i)=>
						<div className="rwx-slider-slide rwxu-flex-center" key={`demo-slide-data-${i+1}`}>
							<div style={{padding: "150px"}} className={`rwxc-background-${c}`}>
								<h5 className="rwxs-m-b-no rwxc-color-white">Slide {i+1}</h5>
							</div>
						</div>						
					)
				}
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={window.rwx.sliders} 
			LIVEHTML={HTML}
		/> 
	)
}

export default SlidersPage;