import {colors} from '../../data';
import rwxMisc from 'roseworx/js/helpers/rwxMiscHelpers';

const sizes = ['small', 'large'];
const iconsizes = ['xs', 'sm', 'lg', 'xl'];
const cards = ['help', 'note', 'warning', 'hint', 'feature'];
const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const weights = ['light', 'normal', 'semi-bold', 'bold', 'extra-bold'];
const icons = [
  'circle',
  'circle-fill',
  'mouse',
  'mouse-fill',
  'star',
  'star-fill',
  'cog',
  'cog-fill',
  'cogs',
  'cogs-fill',
  'help',
  'note',
  'warning',
  'warning-fill',
  'hint',
  'hint-fill',
  'uchev',
  'dchev',
  'rchev',
  'lchev',
  'tick',
  'select-list',
  'copy',
  'copy-fill',
  'search',
  'search-fill',
  'dart',
  'heart',
  'heart-fill',
  'scroll',
  'scroll-fill',
  'cross',
  'home',
  'home-fill',
  'location',
  'profile',
  'code',
  'wheel',
  'spanner',
  'download',
  'dependancy',
  'personalise',
  'accessibility'
];


const cardclasses = [];
cards.map((c)=>cardclasses.push({
    key: c,
    display: c.charAt(0).toUpperCase() + c.slice(1),
    type: "radio",
    description: `${c.charAt(0).toUpperCase() + c.slice(1)} style card.`,
    applyToSelector: "p"
  })
);

  const TypeChangeLinkColorMixinSnippet = `
a.change-color
{
  @include change-link-color($black);
}
`;

  const TypeChangeListColorMixinSnippet = `
ul.change-color
{
  @include change-list-color($black);
}
`;

const TypeSnippet = `
<p class="rwxt-small"></p>
<p class="rwxt-underline h2"></p>
<h4 class="rwxt-base"></h4>
<span class="rwxt-uppercase"></span>
<p class="rwxt-text-center rwxt-large"></p>
`;

const LinksSnippet = `
<a class="no-decoration"></a>
<a class="--inverted"></a>
`;

const ListsSnippet = `
<ul class="no-decoration">
  <li></li>
</ul>
<ul class="--numbered">
  <li></li>
</ul>
<ul class="--dotted">
  <li></li>
</ul>
<ul class="--inline">
  <li></li>
</ul>
`;

const CardsSnippet = `
<p class="help"></p>
<p class="warning"></p>
<p class="hint"></p>
`;

const IconsSnippet = `
<i class="rwxi-${rwxMisc.randomValueFromArray(icons)}"></i>
<i class="rwxi-${rwxMisc.randomValueFromArray(icons)} --sm"></i>
<i class="rwxi-${rwxMisc.randomValueFromArray(icons)} --orange"></i>
<i class="rwxi-${rwxMisc.randomValueFromArray(icons)} --xl --red-fill"></i>
<i class="rwxi-${rwxMisc.randomValueFromArray(icons)} --lg --green --blue-fill"></i>
`;

const FontSizesScssMapOveride = `
$fontsizes: (
  "extra-small": ("size": "6px", "lh": "8px"),
  "large": ("size": "30px", "lh": "32px"),
  "extra-large": ("size": "60px", "lh": "70px")
);
`;

const TypeData = {
  flatten: ['type', 'links', 'lists', 'cards', 'icons'],
  type: {
    selector: "type",
    componentType: "coreSCSS",
    manualControl: false,
    roseworxCore: false,
    renew: false,
    snippet: TypeSnippet,
    config: {
      classes: [
        {
          key: "{heading}",
          value: "false",
          options: ['N/A', ...headings],
          display: "As heading",
          applyToSelector: "Any element",
          description: `Apply specified heading style to the element.`,
          type: "select",
        },
        {
          key: "rwxt-{weight}",
          value: "false",
          options: ['N/A', ...weights],
          display: "Font weight",
          applyToSelector: "Any element",
          description: `Apply the specific font weight to the element.`,
          type: "select",
        },
        {
          key: "rwxt-{size}",
          value: "false",
          options: ['N/A', ...sizes],
          display: "Font size",
          applyToSelector: "Any element",
          description: `Apply the specific font size to the element.`,
          type: "select",          
        },
        {
          key: "rwxt-base",
          display: "Base Font",
          description: "Base font family",
          type: "checkbox",
          value:"false",
          applyToSelector: "Any Element"
        },
        {
          key: "rwxt-uppercase",
          display: "Uppercase",
          description: "Text to uppercase",
          type: "checkbox",
          value:"false",
          applyToSelector: "Any Element"
        },
        {
          key: "rwxt-text-center",
          display: "Center align",
          description: "Align text center",
          type: "checkbox",
          value:"false",
          applyToSelector: "Any Element"
        },
        {
          key: "rwxt-text-right",
          display: "Right align",
          description: "Align text to the right",
          type: "checkbox",
          value:"false",
          applyToSelector: "Any Element"
        },
        {
          key: "rwxt-text-left",
          display: "Left align",
          description: "Align text to the left",
          type: "checkbox",
          value:"false",
          applyToSelector: "Any Element"
        },
        {
          key: "rwxt-underline",
          display: "Underline",
          description: "Underlines",
          type: "checkbox",
          value:"false",
          applyToSelector: "Any Element"
        },
        {
          key: "rwxt-no-underline",
          display: "No Underline",
          description: "Removes underline",
          type: "checkbox",
          value:"false",
          applyToSelector: "Any Element"
        }
      ]
    },
    notes: [
      {
        note: "By default, headings sizes will scale down when the viewport goes below the 'md' breakpoint. This can be changed using the $responsiveheadings SCSS variable.",
        icon: "note"
      }
    ],
    scssVariables: [
      {
        key: "$responsiveheadings",
        description: "If set to a number, heading sizes will be divised by that number when going below 'md' breakpoint, if set to false they wont. E.G set to 0.75, h1 elements will be 75% of $h1-size when below 'md' breakpoint.",
        value: "75",
        options: ["Number", "False"]
      },
      {
        key: "$h1-size",
        description: "Font size for h1 elements. Line height will be this value + 0.5rem.",
        value: "3.3rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$h2-size",
        description: "Font size for h2 elements. Line height will be this value + 0.5rem.",
        value: "2.7rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$h3-size",
        description: "Font size for h3 elements. Line height will be this value + 0.5rem.",
        value: "2.2rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$h4-size",
        description: "Font size for h4 elements. Line height will be this value + 0.5rem.",
        value: "1.8rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$h5-size",
        description: "Font size for h5 elements. Line height will be this value + 0.5rem.",
        value: "1.5rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$h6-size",
        description: "Font size for h6 elements. Line height will be this value + 0.5rem.",
        value: "1.3rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$underline-headings",
        description: "Whether or not to underline all headings by default.",
        value: "false",
        options: ["true", "false"]
      },
      {
        key: "$font-heading",
        description: "Font family for headings.",
        value: "Josefin Sans",
        options: ["Any valid CSS font family"]        
      },
      {
        key: "$font-color-base",
        description: "Default color for all typography.",
        value: "$black",
        options: ["Any valid CSS color"]        
      },
      {
        key: "$font-base",
        description: "Font family for global body copy.",
        value: "Roboto Mono",
        options: ["Any valid CSS font family"]        
      },
      {
        key: "$font-size-base",
        description: "Font size for global body copy.",
        value: "1rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$line-height-base",
        description: "Line height for global body copy.",
        value: "1.5rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$font-size-small",
        description: "Font size when the .rwxt-small utility class is applied.",
        value: "0.75rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$line-height-small",
        description: "Line height when the .rwxt-small utility class is applied.",
        value: "1.125rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$font-size-large",
        description: "Font size when the .rwxt-large utility class is applied.",
        value: "1.25rem",
        options: ["Any valid CSS length"]
      },
      {
        key: "$line-height-large",
        description: "Line height when the .rwxt-large utility class is applied.",
        value: "1.625rem",
        options: ["Any valid CSS length"]
      },
    ],
    scssMaps: [
      {
        snippet: FontSizesScssMapOveride,
        title: "Font Sizes",
        description: [
          "There is a $fontsizes variable containing an array of font sizes and line heights.",
          "You can specify your own SCSS map of fontsizes using the $fontsize scss variable and it will get merged with the default one. If you put a key with the same name, it will override the default.",
          "This example will create 2 new font sizes (extra-small, extra-large) and will override the font size value of large. All other sizes from the original map will still be available.",
          "You can then use these font sizes as you would the defaults - e.g .rwxt-extra-small, .rwxt-extra-large"
        ]
      }
    ]
  },
  links: {
    selector: "links",
    componentType: "coreSCSS",
    snippet: LinksSnippet,
    manualControl: false,
    roseworxCore: false,
    renew: false,
    config: {
      classes: [
        {
          key: '--inverted',
          display: 'Inverted',
          description: "Invert the underline from showing naturally to showing on hover.",
          value: "false",
          type: "checkbox",
          applyToSelector: "a || .rwx-link"
        },
        {
          key: 'no-decoration',
          display: 'No Decoration',
          description: "Display a link with browser default styles.",
          value: "false",
          type: "checkbox",
          applyToSelector: "a || .rwx-link"
        }
      ]
    },
    scssVariables: [
      {
        key: "$font-link-color",
        description: "Default color for <a> tags.",
        value: "$light-blue",
        options: ["Any valid CSS color"]
      },
    ],
    scssMixins: [
      {
        title: "Change Link Color",
        snippet: TypeChangeLinkColorMixinSnippet,
        description: "Change colors of individual links. This example will change the color of an <a> tag with class .change-color using $black as the color."        
      }
    ],
    notes: [
      {
        note: "You can use the .rwx-link class on almost any element (<button>, <div>, <span>, etc..) to style it like a link.",
        icon: "feature"
      },
      {
        note: "Use the .rwx-button class on a link to turn it into a button.",
        icon: "hint"
      }
    ]
  },
  lists: {
    selector: "lists",
    componentType: "coreSCSS",
    snippet: ListsSnippet,
    manualControl: false,
    roseworxCore: false,
    renew: false,
    config: {
      classes: [
        {
          key: '--numbered',
          display: 'Numbered',
          description: "Numbered List.",
          value: "false",
          type: "radio",
          applyToSelector: "ul",
        },
        {
          key: '--dotted',
          display: 'Dotted',
          description: "Dotted List.",
          value: "false",
          type: "radio",
          applyToSelector: "ul",
        },
        {
          key: '--inline',
          display: 'Inline',
          description: "Inline list (use .no-decoration for no dots).",
          value: "false",
          type: "radio",
          applyToSelector: "ul",
        },
        {
          key: 'no-decoration',
          display: 'No decoration',
          description: "Displays a list with no added styles.",
          value: "false",
          type: "checkbox",
          applyToSelector: "ul"
        }
      ]
    },
    scssVariables: [
      {
        key: "$list-item-counter-color",
        description: "The color of the list counters.",
        value: "$light-blue",
        options: ["Any valid CSS color"]
      }
    ],
    scssMixins: [
      {
        title: "Change List",
        snippet: TypeChangeListColorMixinSnippet,
        description: "Change color of individual lists. This example will change the color of a <ul> tag with class .change-color using $black as the color."
      }
    ]
  },
  cards: {
    selector: "cards",
    componentType: "coreSCSS",
    snippet: CardsSnippet,
    config: {
      dataAttributes: [
        {
          key: 'data-rwx-dismissable',
          type: 'checkbox',
          options: ["false", "true"],
          display: "Dismissable",
          value: "false",
          description: "Makes a card dismissable. The dismiss button appears when the card is hovered or focused."
        },
      ],
      classes: cardclasses
    },
    scssVariables: [
      {
        key: "$card-background-color",
        description: "The color of the card backgroud.",
        value: "rgba($grey, 0.4)",
        options: ["Any valid CSS color"]
      },
      {
        key: "$card-border-color",
        description: "The color of the card border.",
        value: "$dark-grey",
        options: ["Any valid CSS color"]
      }
    ],
    notes: [
      {
        note: "Only applicable to <p> tags.",
        icon: "note"
      },
      {
        note: "You must have imported the core rwx javascript file for the dismissable cards to work.",
        icon: "warning"
      },
      {
        note: "If appending dismissable cards to the DOM after the page load event use the method window.rwx.globals.dismissableCards() to instantiate them and window.rwx.globals.undismissableCards() to uninstantiate them.",
        icon: "star"
      }
    ]
  },
  icons: {
    selector: "icons",
    componentType: "coreSCSS",
    snippet: IconsSnippet,
    manualControl: false,
    roseworxCore: false,
    renew: false,
    config: {
      classes: [
        {
          key: "--{size}",
          type: "select",
          options: iconsizes,
          description: `Icon size.`,
          value: "xl",
          applyToSelector: "i",
          display: "Size"
        },
        {
          key: "--{color}",
          type: "color-picker",
          options: colors,
          description: `Icon color.`,
          value: "false",
          applyToSelector: "i",
          display: "Color"
        }
      ]
    },
    scssVariables: [
      {
        key: "$icon-color-base",
        description: "The default color of an icon if no color modifier class is added.",
        value: "$dark-grey",
        options: ["true", "false"]      
      },
      {
        key: "$allow-icons",
        description: "If you have no intention of using icons, you can set this to false and no icons will be added to the final css file, making it smaller.",
        value: "true",
        options: ["true", "false"]
      },
      {
        key: "$allow-colored-icons",
        description: "If you dont need colored icons, set this to false to make the final compiled css file smaller.",
        value: "true",
        options: ["true", "false"]
      },
      {
        key: "$allow-sized-icons",
        description: "If you prefer to set your own widths and heights for icons, set this to false to turn off icons size modifier classes.",
        value: "true",
        options: ["true", "false"]
      },
    ],
    notes: [
      {
        note: "You can manually give an icon a specific width and height, you dont have to use the size modifiers.",
        icon: "hint"
      }
    ]
  }
}

export default TypeData;
export {icons};