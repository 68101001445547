const CountOMetersSnippet = `
<div rwx-countometer data-rwx-countometer-value="{{ VALUE }}" id="{{ UNIQUE ID }}"></div>
`;

const CountOMetersData = {
  selector: "rwx-countometer",
  roseworxPath: "components",
  componentType: "individualJS",
  manualControl: true,
  roseworxCore: true,
  renew: true,
  snippet: CountOMetersSnippet,
  config: {
    dataAttributes: [
      {
        key: 'data-rwx-countometer-value',
        type: 'number',
        options: ['Any valid number between 0-100'],
        display: "Value",
        value: "100",
        description: "Sets the value for the component to count up to."
      },
    ]
  },
  notes: [
    {
      note: "The data-rwx-countometer-value must be a valid number between 0-100.",
      icon: "warning"
    }
  ]
}

export default CountOMetersData;