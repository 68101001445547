import {InfiniteAnimationMethods, FullSizeParentNote} from '../../data';

const LavaLampsSnippet = `
<div rwx-lava-lamp class="rwx-lava-lamp" id="{{ UNIQUE ID }}"></div>
`

const LavaLampsData = {
  selector: 'rwx-lava-lamp',
  roseworxPath: 'effects',
  componentType: 'individualJS',
  snippet: LavaLampsSnippet,
  manualControl: false,
  roseworxCore: true,
  renew: true,
  config: {
    dataAttributes: [
      {
        key: 'data-rwx-lava-lamp-number-of-bubbles',
        type: 'number',
        display: "Bubbles",
        value: "33",
        description: "Specify the number of bubbles inside the lava lamp."
      },
    ]
  },
  methods: InfiniteAnimationMethods,
  notes: [
    {
      note:"If applying this effect to something which has other content in, make sure to give the other content a higher z-index and the lava-lamp div a lower index if you want it to appear behind.",
      icon: "hint"
    },
    FullSizeParentNote
  ]
};
export default LavaLampsData;
