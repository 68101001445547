import React from 'react';

import EffectLayout from '../../layouts/EffectLayout';

import {LoremIpsum} from '../../data';

function LoadingPage({data}) {
	const HTML = () => {
		return (
			<div className="rwx-loading" style={{width:'100%', height:'400px'}}>
				<p className="rwxu-absolute-center">{LoremIpsum}</p>
			</div>
		)
	}

	return (
		<EffectLayout data={data} HTML={HTML} />
	)
}

export default LoadingPage;