import rwxGeometry from 'roseworx/js/helpers/rwxGeometryHelpers';
import rwxCanvas from 'roseworx/js/helpers/rwxCanvasHelpers';

const GeometryHelpersSnippet = `
import rwxGeometry from 'roseworx/js/helpers/rwxGeometry';
`;

const ToRadiansSnippet = `
const angle = 30;
const Result = rwxGeometry.toRadians(angle);
// Result: 0.5235987755982988
`;

const GetQuadraticBezierLengthSnippet = `
const start = { x: 50, y: 50 };
const control = { x: 333, y: 33 };
const end = { x: 250, y: 250 };
const Result = rwxGeometry.getQuadraticBezierLength(start, control, end);
// Result: 369.3957407159895
`;

const GetQuadraticBezierLengthDemo = (ctx,w,h) => {
  ctx.beginPath();
  ctx.moveTo(50, 50);
  ctx.quadraticCurveTo(333, 33, 250, 250);  
  ctx.stroke();
  ctx.closePath();
}

const GetDistanceSnippet = `
const point1 = { x: 35, y: 35 };
const point2 = { x: 265, y: 265 };
const Result = rwxGeometry.getDistance(point1, point2);
// Result: 325.2691193458119
`;

const GetDistanceDemo = (ctx,w,h) => {
  ctx.beginPath();
  ctx.moveTo(35, 35);
  ctx.lineTo(265, 265);
  ctx.stroke();
  ctx.closePath();
  ctx.strokeStyle = "#000000";
  ctx.beginPath();
  ctx.arc(35, 35, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
  ctx.beginPath();
  ctx.arc(265, 265, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
}

const IsInsideCircleSnippet = `
const point = { x: 175, y: 175 };
const circleCenter = { x: 150, y: 150 };
const radius = 100;
const Result = rwxGeometry.isInsideCircle(point, circleCenter, radius);
// Result: true
`;

const IsInsideCircleDemo = (ctx,w,h) => {
  ctx.beginPath();
  ctx.arc(175, 175, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
  ctx.strokeStyle = "#000000";
  ctx.beginPath();
  ctx.arc(150, 150, 100, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
}

const IsInsideSectorSnippet = `
const point = { x: 200, y: 50 };
const sectorCenter = { x: 150, y: 150 };
const outerRadius = 150;
const startAngle = rwxGeometry.toRadians(270);
const endAngle = rwxGeometry.toRadians(360);
const Result = rwxGeometry.isInsideSector(point, sectorCenter, outerRadius, startAngle, endAngle);
// Result: true
`;

const IsInsideSectorDemo = (ctx,w,h) => {
  ctx.beginPath();
  ctx.arc(200, 50, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
  ctx.strokeStyle = "#000000";
  ctx.globalCompositeOperation = 'destination-over';
  rwxCanvas.drawSector(ctx, {x:150, y:150}, 150, rwxGeometry.toRadians(270), rwxGeometry.toRadians(360));
};

const IsInsideArcSnippet = `
const point = { x: 50, y: 100 };
const arcCenter = { x: 150, y: 150 };
const outerRadius = 150;
const innerRadius = 100;
const startAngle = rwxGeometry.toRadians(180);
const endAngle = rwxGeometry.toRadians(240);
const Result = rwxGeometry.isInsideArc(point, arcCenter, outerRadius, innerRadius, startAngle, endAngle);
// Result: true
`;

const IsInsideArcDemo = (ctx,w,h) => {
  ctx.beginPath();
  ctx.arc(50, 100, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
  ctx.strokeStyle = "#000000";
  ctx.globalCompositeOperation = 'destination-over';
  rwxCanvas.drawArc(ctx, {x:150, y:150}, 150, 50, rwxGeometry.toRadians(180), rwxGeometry.toRadians(240));
}

const ClosestPointOnCircumferenceSnippet = `
const point = { x: 130, y: 190 };
const circleCenter = { x: 150, y: 150 };
const radius = 100;
const Result = rwxGeometry.closestPointOnCircumference(point, circleCenter, radius);
// Result: {x: 105.2786404500042, y: 239.4427190999916}
`;

const ClosestPointOnCircumferenceDemo = (ctx,w,h) => {
  let res = rwxGeometry.closestPointOnCircumference({x:130, y:190}, {x:150, y:150}, 100);
  ctx.beginPath();
  ctx.arc(res.x, res.y, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
  ctx.strokeStyle = "#000000";
  ctx.beginPath();
  ctx.arc(130, 190, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
  ctx.beginPath();
  ctx.arc(150, 150, 100, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
}

const GetCoordinatesFromAngleSnippet = `
const point = { x: 133, y: 133 };
const angle = rwxGeometry.toRadians(120);
const distance = 80;
const Result = rwxGeometry.getCoordinatesFromAngle(point, angle, distance);
// Result: {x: 93.00000000000001, y: 202.2820323027551}
`;

const GetCoordinatesFromAngleDemo = (ctx,w,h) => {
  let res = rwxGeometry.getCoordinatesFromAngle({x:133, y:133}, rwxGeometry.toRadians(120), 80);
  ctx.beginPath();
  ctx.arc(res.x, res.y, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
  ctx.strokeStyle = "#000000";
  ctx.beginPath();
  ctx.arc(133, 133, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
  ctx.beginPath();
  ctx.moveTo(133,133);
  ctx.lineTo(res.x, res.y);
  ctx.stroke();
  ctx.closePath();
  ctx.fillStyle = "#000000";
  ctx.fillText('120 degrees', 153, 133);
  ctx.fillText('80 length', 123, 183);
}

const GetAngleSnippet = `
const center = { x: 200, y: 200 };
const point = { x: 33, y: 33 };
const Result = rwxGeometry.getAngle(center, point);
// Result: 225
`;

const GetAngleDemo = (ctx,w,h) => {
  ctx.beginPath();
  ctx.moveTo(200, 200);
  ctx.lineTo(195, 195);
  ctx.stroke();
  ctx.closePath();
  ctx.strokeStyle = "#000000";
  ctx.beginPath();
  ctx.arc(200, 200, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
  ctx.beginPath();
  ctx.arc(33, 33, 10, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
}

const GeometryHelpersData = {
  snippet: GeometryHelpersSnippet,
  methods: [
    {
      name: 'toRadians',
      returnType: 'Number',
      description: 'Converts the specified angle in to radians.',
      example: ToRadiansSnippet,
      parameters: [
        {
          name: 'Angle (in degrees)',
          type: 'Number'
        }
      ]
    },
    {
      name: 'getQuadraticBezierLength',
      returnType: 'Number',
      description: 'Returns an approximate (due to complexity) length of a quadratic bezier curve.',
      example: GetQuadraticBezierLengthSnippet,
      demo: GetQuadraticBezierLengthDemo,
      parameters: [
        {
          name: 'Start Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Control Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'End Point',
          type: 'Object {x: Number , y: Number}'
        }
      ]
    },
    {
      name: 'getDistance',
      returnType: 'Number',
      description: 'Returns a distance between 2 points',
      example: GetDistanceSnippet,
      demo: GetDistanceDemo,
      parameters: [
        {
          name: 'Point A',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Point B',
          type: 'Object {x: Number , y: Number}'
        }
      ]
    },
    {
      name: 'isInsideCircle',
      returnType: 'Boolean',
      description: 'Checks to see if a point is inside a circle of given centre point and radius.',
      example: IsInsideCircleSnippet,
      demo: IsInsideCircleDemo,
      parameters: [
        {
          name: 'Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Circle Center Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Radius',
          type: 'number'
        }
      ]
    },
    {
      name: 'isInsideSector',
      returnType: 'Boolean',
      description: 'Checks to see if a point is inside a sector of a circle.',
      example: IsInsideSectorSnippet,
      demo: IsInsideSectorDemo,
      parameters: [
        {
          name: 'Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Sector Center Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Radius',
          type: 'Number'
        },
        {
          name: 'Start Angle (In Radians)',
          type: 'Number'
        },
        {
          name: 'End Angle (In Radians)',
          type: 'Number'
        },
      ]
    },
    {
      name: 'isInsideArc',
      returnType: 'Boolean',
      description: 'Checks to see if a point is inside an arc of a circle.',
      example: IsInsideArcSnippet,
      demo: IsInsideArcDemo,
      parameters: [
        {
          name: 'Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Arc Center Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Outer Radius',
          type: 'Number'
        },
        {
          name: 'Inner Radius',
          type: 'Number'
        },
        {
          name: 'Start Angle (In Radians)',
          type: 'Number'
        },
        {
          name: 'End Angle (In Radians)',
          type: 'Number'
        },
      ]
    },
    {
      name: 'closestPointOnCircumference',
      returnType: 'Object {x: Number , y: Number}',
      description: 'Returns the closest point on a circles circumference relative to a given point.',
      example: ClosestPointOnCircumferenceSnippet,
      demo: ClosestPointOnCircumferenceDemo,
      parameters: [
        {
          name: 'Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Circle Center Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Radius',
          type: 'Number'
        }
      ]
    },
    {
      name: 'getCoordinatesFromAngle',
      returnType: 'Object {x: Number , y: Number}',
      description: 'Returns the coordinates of a specified angle at a specific distance relative to a given point.',
      example: GetCoordinatesFromAngleSnippet,
      demo: GetCoordinatesFromAngleDemo,
      parameters: [
        {
          name: 'Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Angle (In Radians)',
          type: 'Number'
        },
        {
          name: 'Distance',
          type: 'Number'
        }
      ]
    },
    {
      name: 'getAngle',
      returnType: 'Number',
      description: 'Returns the angle (in degrees) of a specific point relative to another point.',
      example: GetAngleSnippet,
      demo: GetAngleDemo,
      parameters: [
        {
          name: 'Point A',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Point B',
          type: 'Object {x: Number , y: Number}'
        },        
      ]
    }
  ]
}

export default GeometryHelpersData;