import React from 'react';

import rwxFade from 'roseworx/js/effects/rwxFade';

import EffectLayout from '../../layouts/EffectLayout';

import {LoremIpsumLong} from '../../data';

function FadePage({data}) {
	const HTML = ({id}) => {
		return (
			<div className="rwx-split center explicit">
				<div className="rwx-split-fifth-x4">
					<div id={id}>
						<p>{LoremIpsumLong}</p>
						<p>{LoremIpsumLong}</p>
					</div>
				</div>
			</div>
		)
	}

	return (
		<EffectLayout data={data} HTML={HTML} component={rwxFade}/>
	)
}

export default FadePage;