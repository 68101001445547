import React from 'react';
import Table from '../structural/Table';
import Section from '../structural/Section';
import ScssMixin from '../structural/ScssMixin';
import ScssMap from '../structural/ScssMap';
import PageBreak from '../structural/PageBreak';
import {SCSSVariableMessage} from '../data';

function Configuration({dataAttributes, classes, scssVariables, scssMaps, scssMixins, uid, rootElement, hashPrepend}) {
	const id = `configuration-table-${uid}`;
	return (
		<Section title="Personalise" hashPrepend={hashPrepend} classes="configuration-section">
			<div className="rwx-split non-fluid explicit configuration">
				<div className="rwx-split-full">
					{
						(dataAttributes && dataAttributes.length>0) && <div className="configuration-section">
							<p><strong className="impact" id={`${hashPrepend ? `${hashPrepend}-` : ''}dataattributes`}>Data Attributes</strong></p>
							<Table values={dataAttributes} id={`${id}-dataAttributes`} name="Attribute" rootElement={rootElement}/>
						</div>
					}
					{
						classes && classes.length>0 && <div className="configuration-section">
							<p><strong className="impact" id={`${hashPrepend ? `${hashPrepend}-` : ''}classes`}>CSS Classes</strong></p>
							<Table values={classes} id={`${id}-classes`} name="Class" noDefault={true} rootElement={rootElement} />
						</div>
					}
					{
						scssVariables && scssVariables.length>0 && <div className="configuration-section">
							<p><strong className="impact" id={`${hashPrepend ? `${hashPrepend}-` : ''}scssvariables`}>SCSS Variables</strong></p>
							<p className="note">{SCSSVariableMessage}</p>
							<Table values={scssVariables} id={`${id}-scssVariables`} name="SCSS Variable" />
						</div>
					}
					{
						scssMixins && scssMixins.length>0 && <div className="configuration-section">
							<p><strong className="impact" id={`${hashPrepend ? `${hashPrepend}-` : ''}scssmixins`}>SCSS Mixins</strong></p>
							{
								scssMixins.map((m,i)=>
									<React.Fragment key={`${id}-scssMixin-${i}`}>
										{
											i>0 && <PageBreak />
										}
										<ScssMixin {...m} />
									</React.Fragment>
								)
							}
						</div>
					}
					{
						scssMaps && scssMaps.length>0 && <div className="configuration-section">
							<p><strong className="impact" id={`${hashPrepend ? `${hashPrepend}-` : ''}scssmaps`}>SCSS Maps</strong></p>
							{
								scssMaps.map((m,i)=>
									<React.Fragment key={`${id}-scssMixin-${i}`}>
										{
											i>0 && <PageBreak />
										}
										<ScssMap {...m} />
									</React.Fragment>
								)
							}
						</div>						
					}
				</div>
			</div>
		</Section>
	)
}

export default Configuration;
