import React from 'react';

function TextArea({data, classes, readonly, disabled, invalidMessage}) {
	return (
		<div className={`rwx-form-item ${classes}`}>
			<textarea name="demo" id={data.key} rows="5" readOnly={readonly} disabled={disabled}></textarea>
			{data.display && <label htmlFor={data.key} title={data.description}>{data.display}</label>}
			{
				invalidMessage && <p className="invalid-message">{invalidMessage}</p>
			}
		</div>
	)
}

export default TextArea;