const colorvalues = [
  {
    color: "black",
    value: "#000000"
  },
  {
    color: "white",
    value: "#FFFFFF"
  },
  {
    color: "dark-grey",
    value: "#313131"
  },
  {
    color: "silver",
    value: "#263547"
  },
  {
    color: "grey",
    value: "#E3E3E3"
  },
  {
    color: "brown",
    value: "#9A3300"
  },
  {
    color: "orange",
    value: "#E65C00"
  },
  {
    color: "red",
    value: "#FF3333"
  },
  {
    color: "blue",
    value: "#1342A0"
  },
  {
    color: "light-blue",
    value: "#3399FF"
  },
  {
    color: "green",
    value: "#1C7503"
  },
  {
    color: "light-green",
    value: "#3EEE3E"
  },
  {
    color: "turquoise",
    value: "#39EEB2"
  },
  {
    color: "yellow",
    value: "#FCE205"
  },
  {
    color: "purple",
    value: "#731773"
  },
  {
    color: "pink",
    value: "#FF33CC"
  }
];

const colors = colorvalues.map((c)=>c.color).flat();

const scssVariables = colorvalues.map((c)=>{
    return {
      key: `$${c.color}`,
      description: `${c.color} color.`,
      value: c.value,
      options: ["Any valid CSS color"]
    }
  }
);

const borderDirections = ['l', 'r', 't', 'b'];
const borderSizes = ['md', 'lg'];

  const ColorsSnippet = `
<div class="rwxc-background-orange"></div>

<p class="rwxc-color-blue"></p>

<section class="rwxc-background-red rwxc-color-yellow"></section>

<span class="rwxc-border-silver"></span>

<span class="rwxc-border-turquoise-lg"></span>

<div class="rwxc-border-r-silver rwxc-border-l-silver rwxc-border-t-black-lg rwxc-border-b-black-md"></div>
`;

const ColorsScssMapOverride = `
$colors: (
  'dark-yellow':#CCCC00,
  'tan': #D2B48C,
  'cherry': #FF5733,
  'blue': #ADD8E6
);
`;

const ColorsData = {
  selector: "colors",
  roseworxPath: "core",
  componentType: "coreSCSS",
  manualControl: false,
  roseworxCore: false,
  renew: false,
  snippet: ColorsSnippet,
  config: {
    classes: [
      {
        key: 'rwxc-background-{color}',
        description: `Specifies the background color of the element.`,
        applyToSelector: 'Any element',
        type: "color-picker",
        value: "orange",
        display: "Background color",
        options: colors
      },
      {
        key: 'rwxc-color-{color}',
        description: `Specifies the font color of the element.`,
        applyToSelector: 'Any element',
        type: "color-picker",
        value: "blue",
        display: "Text color",
        options: colors
      },
      {
        key: 'rwxc-border-{color}',
        description: `Applies a border to the element in all directions with specified color.`,
        applyToSelector: 'Any element',
        type: "color-picker",
        value: "black",
        display: "Border color",
        options: colors
      },
      {
        key: 'rwxc-border-{direction}-{color}',
        description: `Apply the border of specified color only in the direction specified. You can apply multiple directions on one element.`,
        applyToSelector: 'Any element',
        type: "select",
        options: ['N/A', ...borderDirections],
        value: "false",
        display: "Border direction",
        useForOptions: "{direction}"
      },
      {
        key: 'rwxc-border-{direction}-{color}-{size}',
        description: `Apply the border of specified color with a specific size. Omit direction if you want the border on all sides.`,
        applyToSelector: 'Any element',
        type: "select",
        options: ['N/A', ...borderSizes],
        value: "false",
        display: "Border size",
        useForOptions: "{size}"
      },
    ]
  },
  scssVariables,
  scssMaps: [
    {
      snippet: ColorsScssMapOverride,
      title: "Colors",
      description: [
        "There is a colors variable containing an array of colors.",
        "You can specify your own SCSS map of colors using the $colors scss variable and it will get merged with the default one. If you put a key with the same name, it will override the default.",
        "This example will create 3 new colors (dark-yellow, tan, cherry) and will override the color value of blue. All other colors from the original map will still be avaiable.",
        "You can then use these colors as you would the defaults - e.g .rwxc-background-tan, .rwxc-color-dark-yellow, .rwxc-border-t-cherry-lg"
      ]
    }
  ]
}

export default ColorsData;
export {colors, colorvalues};