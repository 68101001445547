import React from 'react';

import HelperLayout from '../../layouts/HelperLayout';

function DOMHelpersPage({data}) {

  return (
    <HelperLayout data={data} />
  )
}

export default DOMHelpersPage;