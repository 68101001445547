import React from 'react';

import rwxNoticeBoxes from 'roseworx/js/components/rwxNoticeBoxes';

import StandardLayout from '../../layouts/StandardLayout';

import {parseDataAttributes, parseClasses} from '../../helpers';

import {LoremIpsum} from '../../data';

import {UseLiveInteractionToViewComponent} from '../../common';

function NoticeBoxesPage({data}) {
	const HTML = ({id, config}) => {
		return (
			<div style={{height: "300px", position: "relative"}} className="rwxc-border-black-lg rwxs-p-md">
				<div className={`rwx-notice-box ${parseClasses(config.classes)}`} id={id} {...parseDataAttributes(config.dataAttributes)}>
					<p className="small">{LoremIpsum}</p>
					<button onClick={()=>rwxNoticeBoxes.unnotify(id)}>Dismiss</button>
				</div>
				<UseLiveInteractionToViewComponent />
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxNoticeBoxes} 
			LIVEHTML={HTML}
		/> 
	)
}

export default NoticeBoxesPage;