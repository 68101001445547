import React from 'react';

import EffectLayout from '../../layouts/EffectLayout';

import rwxAnimatedBorders from 'roseworx/js/effects/rwxAnimatedBorders';

import {LoremIpsumLong} from '../../data';

function AnimatedBordersPage({data}) {
	const HTML = ({id}) => {
		return (
			<div className="rwx-split center explicit">
				<div className="rwx-split-fifth-x4">
					<p id={id}>{LoremIpsumLong}</p>
				</div>
			</div>
		)
	}

	return (
		<EffectLayout data={data} HTML={HTML} component={rwxAnimatedBorders}/>
	)
}

export default AnimatedBordersPage;