import {AccesibilityNote} from '../../data';

  const SlidersSnippet = `
<div class="rwx-slider" id="{{ UNIQUE ID }}" rwx-slider>
  <div class="rwx-slider-slide">
    {{ SLIDE CONTENT }}
  </div>
  <div class="rwx-slider-slide">
    {{ SLIDE CONTENT }}
  </div>
</div>`;

const SlidersData = {
  selector: 'rwx-slider',
  componentType: "coreJSAndSCSS",
  coreAccessName: "sliders",
  roseworxPath: "core",
  manualControl: false,
  roseworxCore: true,
  renew: false,
  snippet: SlidersSnippet,
  config: {
    dataAttributes : [
      {
        key: 'data-rwx-slider-auto-slide',
        type: 'checkbox',
        value: "false",
        options: ['true', 'false'],
        display: "Auto slide",
        description: "Whether or not the slider should automatically slide through the slides on a timeout."
      },
      {
        key: 'data-rwx-slider-auto-slide-timeout',
        type: 'number',
        value: '5',
        options: ['Number in seconds'],
        display: 'Autoslide Timeout',
        description: "If data-rwx-slider-auto-slide is set to true, use this to set the amount of seconds between each slide."
      },
      {
        key: 'data-rwx-slider-counters',
        type: 'checkbox',
        value: "true",
        options: ['true', 'false'],
        display: "Counters",
        description: "Whether or not to display clickable counters to navigate slides."     
      },
      {
        key: 'data-rwx-slider-vertical',
        type: 'checkbox',
        value: "false",
        options: ['true', 'false'],
        display: "Vertical",
        description: "Makes the slider vertical instead of horizontal." 
      },
      {
        key: 'data-rwx-slider-reeling',
        type: 'checkbox',
        value: "false",
        options: ['true', 'false'],
        display: "Reeling",
        description: "By default, when switching slides, the next slide will show immediately. If this is set to true, all other slides between the current slide and the next slide will be shown as they go past."  
      }
    ],
    classes: [
      {
        key: '--counter-circle',
        type: 'checkbox',
        value: "false",
        display: "Circle Counter",
        options: ['true', 'false'],
        description: "If data-rwx-slider-counters is set to true this will turn the counters circle instead of square."
      },
    ]   
  },
  scssVariables: [
    {
      key: "$slider-counter-active-color",
      description: "If data-rwx-slider-counters is set to true, this controls the color of the active counter.",
      value: "$dark-grey",
      options: ["Any valid CSS color"]
    },
    {
      key: "$slider-counter-inactive-color",
      description: "If data-rwx-slider-counters is set to true, this controls the color of inactive counters.",
      value: "$grey",
      options: ["Any valid CSS color"]
    },
    {
      key: "$slider-counter-size",
      description: "If data-rwx-slider-counters is set to true, this controls the size of the counters.",
      value: "20px",
      options: ["Any valid CSS length"]
    },
  ],
  methods: [
    {
      name: 'goToSlide',
      returnType: 'void',
      description: 'Call this method to programatically go to a particular slide.',
      example: ".goToSlide('{ID}', {SLIDENUMBER})",
      interactable: true,
      interactableInputs: [
        {
          key: 'getSlideNumber',
          type: 'number',
          value: "false"
        },
      ],
      parameters: [
        {
          name: 'ID',
          type: 'String'
        },
        {
          name: 'Slide Number',
          type: 'Number'
        }
      ]
    },
    {
      name: 'addEvent',
      returnType: 'void',
      description: 'Call this method to run a function when a slide has changed.',
      example: ".addEvent('{ID}', {SLIDENUMBER}, '{EVENT}', '{FUNCTION}')",
      interactable: false,
      parameters: [
        {
          name: 'ID',
          type: 'String'
        },
        {
          name: 'Slide Number',
          type: 'Number'
        },
        {
          name: 'Event',
          type: `'slideShow' || 'slideHide'`
        },
        {
          name: 'Function',
          type: 'Function'
        }
      ]
    },
  ],
  notes: [
    AccesibilityNote, 
    {
      note: "You can add a .rwxu-flex-center utility class to the .rwx-slider-slide node to make all content in the slide vertically and horizontally aligned.",
      icon: "hint"
    }
  ]
}

export default SlidersData;