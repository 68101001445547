const AnimatedBordersSnippet = `
<div rwx-animated-border></div>
<p rwx-animated-border></p>
`;

const AnimatedBordersChangeColorMixinSnippet = `
div.change-animated-border
{
  @include change-animated-border($red, $green, 5px);
}
`;

const AnimatedBordersData = {
  selector: 'rwx-animated-border',
  roseworxPath: 'effects',
  componentType: 'individualJSAndSCSS',
  snippet: AnimatedBordersSnippet,
  manualControl: false,
  roseworxCore: true,
  renew: false,
  description: [
    "A sleak upgrade on the traditional border.",
    "Add a [rwx-animated-border] attribute to any element."
  ],
  scssMixins: [
    {
      snippet: AnimatedBordersChangeColorMixinSnippet,
      title: "Change Animated Border",
      description: "Change the color and / or size of individual animated borders. This example will change the color and size of the animated-border on a <div> with class .change-animated-border using $red as the 'from' color, $green as the 'to' color and 5px as the border size. The size parameter is optional and can be omitted."
    }
  ],
  scssVariables: [
    {
      key: "$rwx-animated-border-from-color",
      description: "The start color.",
      value: "$blue",
      options: ["Any valid CSS color"]
    },
    {
      key: "$rwx-animated-border-to-color",
      description: "The end color.",
      value: "$orange",
      options: ["Any valid CSS color"]
    },
    {
      key: "$rwx-animated-border-size",
      description: "The border size.",
      value: "5px",
      options: ["Any valid CSS length"]
    },
  ]
}

export default AnimatedBordersData;