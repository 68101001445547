import React, {useState, useRef} from 'react';

import {getRandomColorForWhiteType} from '../helpers';

import {useSpring, animated} from 'react-spring';

function Note({note, icon}){
	const color = useRef(getRandomColorForWhiteType());
	const [skew, setSkew] = useState(false);

	const spring = useSpring(
		{
			transform: skew ? 'skew(0deg)' : 'skew(-12deg)', 
			config:{mass: 1, tension: 160, friction: 5}
		}
	);

	return (
		<div className="note rwxc-color-white" onMouseOver={()=>setSkew(true)} onMouseOut={()=>setSkew(false)} >
			<animated.div style={spring} className={`note-background rwxc-background-${color.current}`}></animated.div>
			<div className="note-icon"><i className={`rwxi-${icon} --white`}></i></div>
			<p className="rwxs-m-b-no h6">{note}</p>
		</div>
	)
}

export default Note;