import React from 'react';

import Select from '../../form-fields/Select';
import Checkbox from '../../form-fields/Checkbox';
import Input from '../../form-fields/Input';
import TextArea from '../../form-fields/TextArea';
import {RadioGroupNoFunctionality} from '../../form-fields/RadioGroup';

import StandardLayout from '../../layouts/StandardLayout';
import PageBreak from '../../structural/PageBreak';

import Extra from '../../sections/Extra';

import {parseClasses} from '../../helpers';

function FormsPage({data}) {
	const HTML = ({id, config}) =>{
		const readonly = config.demo.find((da)=>da.key==="readonly").value==="true";
		const disabled = config.demo.find((da)=>da.key==="disabled").value==="true";
		const parsed = parseClasses(config.classes);
		const invalidMessage = () => {
			return parsed.includes('invalid') ? "Invalid Field set by configuration options." : "Invalid Field determined by validation rule 'field is required and field is not valid'."
		}
		
		return (
			<form id={id} className="rwx-form rwxs-m-l-sm rwxs-m-r-sm" style={{minWidth:"300px"}}>
				<Input data={{type:"text", value:"", description:"text", key:"text", display: "Text"}} classes={parsed} invalidMessage={invalidMessage()} readonly={readonly} disabled={disabled}/>
				<Input data={{type:"number", value:"", description:"number", key:"number", display: "Number"}} classes={parsed} invalidMessage={invalidMessage()} readonly={readonly} disabled={disabled}/>
				<Input data={{type:"email", value:"", description:"email", key:"email", display: "Email"}} classes={parsed} invalidMessage={invalidMessage()} readonly={readonly} disabled={disabled}/>
				<TextArea data={{value:"", description:"textarea", key:"textarea", display: "Textarea"}} classes={parsed} invalidMessage={invalidMessage()} readonly={readonly} disabled={disabled}/>
				<Checkbox data={{value:"false", description:"chekbox", key:"chekbox", display: "Checkbox"}} classes={`rwx-checkbox ${parsed}`} invalidMessage={invalidMessage()} readonly={readonly} disabled={disabled}/>
				<Checkbox data={{value:"false", description:"toggle", key:"toggle", display: "Toggle"}} classes={`rwx-toggle ${parsed}`} invalidMessage={invalidMessage()} readonly={readonly} disabled={disabled}/>
				<Select data={{value:"", description:"select", key:"select", display: "Select", options:['Option 1', 'Option 2', 'Option 3']}} classes={parsed} invalidMessage={invalidMessage()} readonly={readonly} disabled={disabled}/>
				<RadioGroupNoFunctionality groupName="Radio group" data={[{value:"", description:"radio", key:"radio", display: "Option 1"},{value:"", description:"radio", key:"radio", display: "Option 2"},{value:"", description:"radio", key:"radio", display: "Option 3"}]} classes={parsed} invalidMessage={invalidMessage()} readonly={readonly} disabled={disabled} />
				<button type="submit">Submit</button>
			</form>
		)
	}

	return (
		<>
			<StandardLayout 
				data={data} 
				rwxComponent={window.rwx.forms} 
				LIVEHTML={HTML}
			/>
			<PageBreak/>
			<Extra>
				<h4>Field Validation Rules</h4>
				<p><strong className="rwxc-color-silver">You can use an .rwx-form without the Javascript functionality, however if the rwxForms Javascript is imported and hooked on to a form, the following validation rules apply;</strong></p>
				<p>If a .rwx-form-item has a .required class, validation rules are the following and are only evaluated once the field loses focus ('blur' event);</p>
				<ul className="--dotted rwxs-m-b-lg">
					<li>An input with type="email" needs a valid email in the format xx@xx.xx.xx</li>
					<li>A Checkbox / Toggle / Radio Button needs to be checked</li>
					<li>A Select box needs to have an option selected which has a value other than "". (If an option node has value="" and it gets selected, the field is considered invalid. This can be useful for placeholder options.)</li>
					<li>All other input types and textareas need a value with length > 0</li>
				</ul>
				<p>If an input does not have the .required class it is considered valid regardless of it's value.</p>
				<p>If you have put an element with class .invalid-message inside the .rwx-form-item element, this will show when the field is evaluated as invalid and hide when it is evaluated as valid.</p>
				<p>If you put a button element with type="submit" inside the [rwx-form] element, this will remain disabled until ALL fields inside the form are evaluated as valid.</p>
			</Extra>
		</>
	)
}

export default FormsPage;