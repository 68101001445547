import {AccesibilityNote, FullSizeParentNote, MouseMoveNote} from '../../data';

  const SlideshowsSnippet = `
<div class="rwx-slideshow" rwx-slideshow id="{{ UNIQUE ID }}">
  <div class="slide">
    <h1 class="slide-title">{{ SLIDE TITLE }}</h1>
    <div class="slide-content">
      {{ SLIDE CONTENT }}
    </div>
  </div>
  <div class="slide">
    <h1 class="slide-title">{{ SLIDE TITLE }}</h1>
    <div class="slide-content">
      {{ SLIDE CONTENT }}
    </div>
  </div>
</div>
  `;

const SlideshowsData = {
  selector: "rwx-slideshow",
  componentType: "individualJS",
  roseworxPath: "components",
  manualControl: false,
  roseworxCore: true,
  renew: false,
  snippet: SlideshowsSnippet,
  notes: [
    AccesibilityNote,
    {
      note: "You can use the left and right arrow keys to navigate slides back and forward whilst the slideshow is in focus.",
      icon: "star"
    },
    {
      note: "You can also use touch dragging on mobile devices to switch slides.",
      icon: "star"
    },
    FullSizeParentNote,
    MouseMoveNote
  ]
}

export default SlideshowsData;