import React,{useState, useRef} from 'react';
import {colors} from '../data';

function ColorPicker({dk, value, display}) {
	const inputRef = useRef();
	const [v, setv] = useState(value);
	const [show, setShow] = useState(false);

	const click = (c) => {
		setShow(false);
		if(v===c){setv(false)}
		else{
			setv(c);
		}
	}

	return(
		<div className="rwx-form-item valid --inline">
			<input type="hidden" ref={inputRef} value={v} key={dk} name={dk}/>
			<div className="rwx-split color-square-container">
				<button type="button" onClick={()=>setShow(!show)} className={`color-square big no-decoration ${v!=="false" ? `rwxc-background-${v}` : ''}`} title="Color picker">
					{
						(!v || v==="false") && "X"
					}
				</button>
				<div className={`color-square-popout rwxc-background-white rwx-split center ${show ? 'show' : ''}`}>
					{
						colors.map((c, i)=>
							<button onClick={()=>click(c)} title={c} type="button" key={`color-panel-${i}-${dk}`} className={`color-square no-decoration rwxs-m-sm rwxc-background-${c} ${v===c ? 'active' : ''}`}></button>
						)
					}
				</div>
			</div>
			<label>{display}</label>
		</div>
	)
}

export default ColorPicker;