import React from 'react';

import StandardLayout from '../../layouts/StandardLayout';

function SpacingsPage({data}) {

	const HTML = ({config})=>{
		const pd = config.classes.find((p)=>p.key==="rwxs-p-{direction}-{size}").value;
		const md = config.classes.find((m)=>m.key==="rwxs-m-{direction}-{size}").value;
		const ps = config.classes.find((p)=>p.key==="rwxs-p-{size}").value;
		const ms = config.classes.find((m)=>m.key==="rwxs-m-{size}").value;
		const pr = config.classes.find((p)=>p.key==="rwxs-p-{direction}-{size}-rsp").value;
		const mr = config.classes.find((m)=>m.key==="rwxs-m-{direction}-{size}-rsp").value;

		let padding = '';
		let margin = '';
		let paddingr = '';
		let marginr = '';
		if(ps!=="false")
		{
			padding += `rwxs-p-${pd!=="false" ? `${pd}-${ps}`: ps}`;
		}
		if(pr!=="false")
		{
			paddingr += `rwxs-p-${pd!=="false" ? `${pd}-${pr}`: pr}-rsp`;
		}
		if(ms!=="false")
		{
			margin += `rwxs-m-${md!=="false" ? `${md}-${ms}`: ms}`;
		}
		if(mr!=="false")
		{
			marginr += `rwxs-m-${pd!=="false" ? `${pd}-${mr}`: mr}-rsp`;
		}

		return (
			<div className="rwxu-flex-center">
				<div className="spacings-legend">
					<span className="margin">Margin</span><br />
					<span className="padding">Padding</span>
				</div>
				<div className="rwxc-background-silver">
					<div className={`${padding} ${paddingr} ${margin} ${marginr} rwxc-background-grey`}>
						<p className="rwxs-m-b-no h3 rwxc-background-white">Spacing</p>
					</div>
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={null} 
			LIVEHTML={HTML}
			useExamples={true}
		/>
	)
}

export default SpacingsPage;