import React from 'react';

import rwxBitSwarms from 'roseworx/js/bitfx/rwxBitSwarms';

import {BITFXHTML} from '../../common';

import StandardLayout from '../../layouts/StandardLayout';

function BitSwarmsPage({data}) {
	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxBitSwarms} 
			LIVEHTML={BITFXHTML}
		/>
	)
}

export default BitSwarmsPage;