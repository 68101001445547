import React, {useState, useRef} from 'react';
import ReactDOM from 'react-dom';
import {useSpring, animated} from 'react-spring';

import {OptionListHeading} from '../data';

function OptionList({string, options, useOptions}) {
  const fi = useOptions ? string.indexOf(useOptions) : string.indexOf('{');
  const li = useOptions ? string.indexOf(useOptions) + useOptions.length-1 : string.indexOf('}');
  let substr = string.substring(fi, li+1);
  let firstString = string.substring(0, fi);
  let lastString = string.substring(li+1, string.length);

  const buttonRef = useRef();
  const optionRef = useRef();
  const [show, setShow] = useState(false);
  const {transform} = useSpring(
    {
      transform: show ? 1 : 0, 
      config:{mass: 1, tension: 160, friction: 10}
    }
  );

  const determineCoordinates = () => {
    if(show)return;
    const b = buttonRef.current.getBoundingClientRect();
    optionRef.current.style.top = `${b.top + window.pageYOffset + 20}px`;
    optionRef.current.style.left = `${b.left + b.width}px`;
    setShow(true);
  }

  return (
    <>
      <div>
        {firstString}
        <div style={{position:"relative", display:"inline-block"}} onMouseOver={determineCoordinates} onMouseLeave={()=>setShow(false)}>
          <button className="rwx-link option-list-open" ref={buttonRef} onFocus={determineCoordinates} onBlur={()=>setShow(false)}>{substr}</button>
            {ReactDOM.createPortal(
              <animated.div style={{transform: transform.to(x=>`scale(${x<0 ? 0 : x})`)}} ref={optionRef} className="radius rwxs-p-sm rwxc-background-light-blue option-list">
                <p><strong className="impact rwxt-underline">{OptionListHeading}</strong></p>
                <ul className="--dotted rwxs-m-b-no">
                  {
                    options.map((o, i)=><li key={`option-${o}-${i}`}>{o}</li>)
                  }
                </ul>
              </animated.div>
          , document.getElementById("roseworx"))}
        </div>
        {lastString}
      </div>
    </>
  )
} 

export default OptionList;