import {colors} from '../../data';

const ButtonsSnippet = `
<button>{{ BUTTON TEXT }}</button>
<button class="rwx-button">{{ BUTTON TEXT }}</button>
<a class="rwx-button" href="">{{ BUTTON TEXT }}</a>
<div class="rwx-button">{{ BUTTON TEXT }}</div>
`;

const ButtonsData = {
  selector: "rwx-button",
  componentType: "coreSCSS",
  manualControl: false,
  roseworxCore: false,
  renew: false,
  snippet: ButtonsSnippet,
  config: {
    demo: [
      {
        key: 'disabled',
        type: 'checkbox',
        value: 'false',
        display: "Disabled",
        description: "Disabled Button."
      },
    ],
    classes: [
      {
        key: '--inverted',
        type: 'checkbox',
        value: "false",
        display: "Inverted",
        description: "Invert the background and text color.",
        applyToSelector: "button || .rwx-button"
      },
      {
        key: '--bullet',
        type: 'checkbox',
        value: "false",
        display: "Bullet",
        description: "Give the button a rounder bullet shape.",
        applyToSelector: "button || .rwx-button"
      },
      {
        key: 'no-decoration',
        type: 'checkbox',
        value: "false",
        display: "No decoration",
        description: "Removes button styles completely so it looks like plain text.",
        applyToSelector: "button || .rwx-button"
      },
      {
        key: '--{color}',
        type: 'color-picker',
        value: "false",
        display: "Button Color",
        description: `The color of the button.`,
        applyToSelector: "button || .rwx-button",
        options: colors
      },
    ]
  },
  scssVariables: [
    {
      key: "$button-size",
      description: "The minimum height of a button.",
      value: "50px",
      options: ["Any valid CSS length"]
    },
    {
      key: "$button-padding",
      description: "Amount of padding between the button text and the border.",
      value: "20px",
      options: ["Any valid CSS length"]
    },
    {
      key: "$button-color-base",
      description: "The default color of a button if no color modifier class is added.",
      value: "$dark-grey",
      options: ["Any valid CSS color"]
    },
    {
      key: "$button-color-white",
      description: "Because the background of a website is usually white as default, white buttons wont show up. Use this to decorate white buttons specifically.",
      value: "$black",
      options: ["Any valid CSS color"]
    },
    {
      key: "$button-font-size",
      description: "Font size of the button",
      value: "18px",
      options: ["Any valid CSS length"]
    }
  ],
  notes: [
    {
      note: "You can use the .rwx-button class on almost any element (<a>, <div>, <span>, etc..) to style it like a button.",
      icon: "star"
    },
    {
      note: "Use the .rwx-link class on a button to turn it into a link.",
      icon: "hint"
    }
  ]
}

export default ButtonsData;