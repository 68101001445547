import {FullSizeParentNote, AddFixedForFullScreen} from '../../data';

const SlideTickersSnippet = `
<div class="rwx-slide-ticker" rwx-slide-ticker id="{{ UNIQUE ID }}">
  <span class="rwx-slide-ticker-value">{{ WORD(S) }}</span>
</div>
`;

const SlideTickersData = {
  selector: "rwx-slide-ticker",
  componentType: "individualJSAndSCSS",
  roseworxPath: "components",
  manualControl: false,
  roseworxCore: true,
  renew: false,
  snippet: SlideTickersSnippet,
  methods: [
    {
      name: 'tick',
      returnType: 'Void',
      description: 'Starts the slide ticker.',
      example: ".tick('{ID}')",
      interactable: true,
      parameters: [
        {
          name: 'ID',
          type: 'String'
        }
      ]
    },
  ],
  scssVariables: [
    {
      key: "$rwx-slide-ticker-background-color",
      description: "The color of the slide ticker background. This can be overridden on a component basis by applying .rwxc-background-{color} utility class to the .rwx-slide-ticker element.",
      value: "$orange",
      options: ["Any valid CSS color"]
    }
  ],
  notes: [
    FullSizeParentNote,
    AddFixedForFullScreen('[rwx-slide-ticker]'),
    {
      note: "Click anywhere on the slide ticker component whilst it's open to dismiss it early.",
      icon: "star"
    },
    {
      note: "The maximum number of characters is 25 for this effect to work properly.",
      icon : "warning"
    }
  ]
}

export default SlideTickersData;