import React,{useEffect} from 'react';

import StandardLayout from '../../layouts/StandardLayout';
import TabbedLayout from '../../layouts/TabbedLayout';

import {parseClasses , parseDataAttributes} from '../../helpers';

function SkrollXPage({data}) {
	const tabcontent = [
		{
			title: "SkrollX",
			content: <SkrollX data={data.skrollx} hashPrepend="skrollx"/>,
			id: 'skrollx'
		},
		{
			title: "Parallax",
			content: <SkrollXPX data={data.parallax} hashPrepend="parallax"/>,
			id: 'parallax'
		}
	];

	return (
		<TabbedLayout description={data.description} tabs={tabcontent} />
	)
}

function SkrollX({data, hashPrepend}) {

	const HTML = ({id, config}) => {
		const parsed = parseClasses(config.classes);
		useEffect(()=>{
			document.getElementById(id).classList.remove('rwxsx-end');
		}, [config , id])

		return (
			<div className="rwxu-flex-center">
				<div id={id} className={`rwxc-background-purple rwxs-p-sm rwxu-flex-center rwxc-color-white ${parsed}`} style={{width:"300px", height:"300px"}} {...parseDataAttributes(config.dataAttributes)}>
					<p className="rwxs-m-b-no h3">.{parsed}</p>
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={window.rwx.skrollx} 
			LIVEHTML={HTML}
			htmlsnippet={data.snippet}
			hashPrepend={hashPrepend}
		/> 
	)
}

function SkrollXPX({data, hashPrepend}) {
	const HTML = ({id, config}) => {
		return (
			<div className="rwx-split explicit">
				<div className="rwx-split-half">
					<div className="rwxc-background-turquoise rwxu-flex-center" style={{height: "200px"}}>
						<p className="rwxs-m-b-no h3 rwxc-color-white">No parallax</p>
					</div>
				</div>
				<div className="rwx-split-half">
					<div className="rwxc-background-purple rwxu-flex-center" id={id} rwxpx="true" style={{height: "200px"}} {...parseDataAttributes(config.dataAttributes)}>
						<p className="rwxs-m-b-no h3 rwxc-color-white">parallax</p>
					</div>
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={window.rwx.skrollx} 
			LIVEHTML={HTML}
			htmlsnippet={data.snippet}
			noParallax={true}
			hashPrepend={hashPrepend}
		/> 
	)
}

export default SkrollXPage;