const UtilitiesSnippet = `
<div class="rwxu-hide rwxu-show-rsp"></div>
<div class="rwxu-flex-center rwxu-pointer"></div>
<div class="rwxu-full-size">
  <div class="rwxu-absolute-center"></div>
</div>
`;

  const UtilitiesFlexCenterMixinSnippet = `
div.center-me
{
  @include flex-center;
}
  `;

  const UtilitiesAbsoluteCenterMixinSnippet = `
div.center-me
{
  @include absolute-center;
}
  `;

  const UtilitiesFullSizeMixinSnippet = `
div.full-size
{
  @include full-size;
}
  `;

  const UtilitiesSnapDownMixinSnippet = `
div.change-background
{
  background-color: $white;
  @include snap-down-from('lg')
  {
    background-color:$red;
  }
  @include snap-down-from('md')
  {
    background-color:$green;
  }
}
  `;

  const UtilitiesSnapUpMixinSnippet = `
div.change-color
{
  color:$black;
  @include snap-up-from('sm')
  {
    color:$light-blue;
  }
  @include snap-up-from('xl')
  {
    color:$blue;
  }
}
  `;

const UtilitiesData = {
  selector: "utils",
  roseworxPath: "core",
  componentType: "coreSCSS",
  manualControl: false,
  roseworxCore: false,
  renew: false,
  snippet: UtilitiesSnippet,
  config: {
    classes: [
      {
        key: "rwxu-hide",
        applyToSelector: "Any element",
        description: "Hides an element.",
        value: "false",
        display: "Hide",
        type:"radio"
      },
      {
        key: "rwxu-show",
        applyToSelector: "Any element",
        description: "Shows an element.",
        value: "false",
        display: "Show",
        type:"radio"
      },
      {
        key: "rwxu-hide-rsp",
        applyToSelector: "Any element",
        description: "Hides an element only for below the 'md' breakpoint.",
        value: "false",
        display: "Hide Responsive",
        type:"radio2",
      },
      {
        key: "rwxu-show-rsp",
        applyToSelector: "Any element",
        description: "Shows an element only for below the 'md' breakpoint.",
        value: "false",
        display: "Show Responsive",
        type:"radio2",
      },
      {
        key: "rwxu-flex-center",
        applyToSelector: "Any element",
        description: "Uses CSS flexbox to center an elements contents vertically and horizontally.",
        value: "false",
        display: "Flex center",
        type:"checkbox"
      },
      {
        key: "rwxu-absolute-center",
        applyToSelector: "Any element",
        description: "Uses absolute positioning to center an elements contents vertically and horizontally.",
        value: "false",
        display: "Absolute center",
        type:"checkbox"
      },
      {
        key: "rwxu-full-size",
        applyToSelector: "Any element",
        description: "Makes an element the full size of its closest relatively positioned ancestor node.",
        value: "false",
        display: "Full size",
        type:"checkbox"
      },
      {
        key: "rwxu-pointer",
        applyToSelector: "Any element",
        description: "Changes the cursor of an element to be a pointer.",
        value: "false",
        display: "Pointer",
        type:"checkbox"
      },
      {
        key: "rwxu-center",
        applyToSelector: "Any element",
        description: "Centers an element vertically using auto margins.",
        value: "false",
        display: "Center",
        type:"checkbox"
      },
    ]
  },
  scssVariables: [
    {
      key: "$focus-ring-color",
      description: "The color of the focus ring when a focussable element (form field, button, link, etc) is focussed.",
      value: "$turquoise",
      options: ["Any valid CSS color"]
    },
    {
      key: "$focus-ring-shadow",
      description: "The focus ring shadow when a focussable element receives focus.",
      value: "0 0 0.3rem 0.1rem $focus-ring-color",
      options: ["Any valid CSS box shadow value"]
    }
  ],
  scssMixins: [
    {
      title: "Flex center",
      snippet: UtilitiesFlexCenterMixinSnippet,
      description: "This example will align the content of a div with class .center-me to the center both vertically and horizontally."
    },
    {
      title: "Absolute center",
      snippet: UtilitiesAbsoluteCenterMixinSnippet,
      description: "This example will align the content of a div with class .center-me to the center both vertically and horizontally with absolut positioning."
    },
    {
      title: "Full size",
      snippet: UtilitiesFullSizeMixinSnippet,
      description: "This example will make a div with class .full-size the full size of its closest relatively positioned ancestor using absolute positioning."
    },
    {
      title: "Snap down from",
      snippet: UtilitiesSnapDownMixinSnippet,
      description: "The snap-down mixin applies certain styles when the viewport goes below a specified breakpoint. This example will make a div with class .change-background have a white background when the viewport is above the 'lg' breakpoint, a red background when the viewport is between the 'lg' and 'md' breakpoints and a green background when the viewport is below the 'md' breakpoint."
    },
    {
      title: "Snap up from",
      snippet: UtilitiesSnapUpMixinSnippet,
      description: "The snap-up mixin applies certain styles when the viewport goes above a specified breakpoint. This example will make a div with class .change-color have a black color when the viewport is below the 'sm' breakpoint, a light blue color when the viewport is between the 'sm' and 'xl' breakpoints and a blue color when the viewport is above the 'xl' breakpoint."
    }
  ]
}

export default UtilitiesData;