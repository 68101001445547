const PreloadSnippet = `
<body class="rwx-preload">
  {{ PAGE CONTENT }}
</body>
`;

const PreloadData = {
  resourceName: "rwxPreload",
  roseworxPath: "modules",
  componentType: "individualJS",
  manualControl: false,
  roseworxCore: false,
  renew: false,
  description: [
    `The Roseworx Preload Module solves the issue of CSS transitions running when a page is loaded. If an element has a css transition defined, when the page is first loaded, you will see the element transition to its initial state unintentionally. Preload eliminates this issue, providing a much nicer user experience.`,
  ],
  steps: [
    {
      heading: "...Add class",
      extra: ["Add rwx-preload class to the body element."],
      snippet: PreloadSnippet  
    }
  ]
};

export default PreloadData;