import React from 'react';

import {useLocation} from 'react-router';
import {NavLink} from 'react-router-dom';

function Back() {
  const location = useLocation();
  const paths = location.pathname.split('/'); 
  const prev = paths[paths.length-2];
  return (
    <NavLink to={`/${prev}`} title="Back" aria-label="Back" className="rwxs-m-t-sm no-decoration rwxs-p-xl rwxc-background-light-blue back-to-parent">
      <div className="rwxu-absolute-center">
        <i className="rwxi-lchev --white"></i>
      </div>
    </NavLink>
  )
}

export default Back;