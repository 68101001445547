import React from 'react';
import Checkbox from './Checkbox';
import ColorPicker from './ColorPicker';
import Input from './Input';
import Select from './Select';
import RadioGroup from './RadioGroup';

function InputDistributor({data, classes}) {
	if(data.type === "text" || data.type === "number"){
		return (
			<Input data={data} classes={classes}/>	
		)
	}
	else if (data.type === "select") {
		return (
			<Select data={data} classes={classes}/>
		)
	}
	else if (data.type === "checkbox") {
		return (
			<Checkbox data={data} classes={classes}/>
		)
	}
	else
	{
		return null;
	}
}

function FieldDistributor({data, id, classes}) {
	const radio = data.filter((d)=>d.type?.includes("radio"));
	const color = data.filter((d)=>d.type==="color-picker");
	const inputs = data.filter((d)=>d.type!=="color-picker" && !d.type?.includes("radio"));
	let radiogroups = [];

	if(radio.length>0)
	{
		let types = [...new Set(radio.map((r)=>r.type))];
		types.map((t)=>radiogroups.push({name:t, data:[]}));
		radio.map((r)=>{
			let found = radiogroups.find((a)=>a.name===r.type);
			found.data.push(r);
			return false;
		});
	}

	return (
		<>
			{
				color.length > 0 && color.map((c)=><ColorPicker {...c} dk={c.key} key={`color-field-${id}-${c.key}`}/>)
			}
			{
				inputs.length > 0 && inputs.map((i)=><InputDistributor data={i} key={`input-field-${id}-${i.key}`} classes={classes}/>)
			}
			{
				radiogroups.length > 0 && radiogroups.map((rg)=><RadioGroup key={`radio-group-${id}-${rg.name}`} data={rg.data} name={rg.name} />)
			}
		</>
	)
}

export default FieldDistributor;