import EffectsData from './Effects';
import HomeData from './Home';
import ToolboxData from './Toolbox';
import AnimatedBordersData from './_effects/AnimatedBorders';
import FadeData from './_effects/Fade';
import LavaLampsData from './_effects/LavaLamps';
import LoadingData from './_effects/Loading';
import MistsData from './_effects/Mists';
import SlideData from './_effects/Slide';
import CanvasHelpersData from './_toolbox/CanvasHelpers';
import CssData from './_toolbox/Css';
import DOMHelpersData from './_toolbox/DOMHelpers';
import GeometryHelpersData from './_toolbox/GeometryHelpers';
import MathHelpersData from './_toolbox/MathHelpers';
import MiscHelpersData from './_toolbox/MiscHelpers';
import ScssData from './_toolbox/Scss';
import ThemesData from './_toolbox/Themes';
import BitBlackHolesData from './bitfx/BitBlackHoles';
import BitExplosionsData from './bitfx/BitExplosions';
import BitSwarmsData from './bitfx/BitSwarms';
import BitSystemsData from './bitfx/BitSystems';
import bitfxData from './bitfx/bitfx';
import CountOMetersData from './components/CountOMeters';
import GridGalleriesData from './components/GridGalleries';
import LuckyDipsData from './components/LuckyDips';
import NoticeBoxesData from './components/NoticeBoxes';
import NumberSwitchersData from './components/NumberSwitchers';
import OptionSelectorsData from './components/OptionSelectors';
import PhotoTilesData from './components/PhotoTiles';
import SlideTickersData from './components/SlideTickers';
import SlideshowsData from './components/Slideshows';
import TimelinesData from './components/Timelines';
import ButtonsData from './core/Buttons';
import ColorsData from './core/Colors';
import FormsData from './core/Forms';
import GridData from './core/Grid';
import SlidersData from './core/Sliders';
import SpacingsData from './core/Spacings';
import TablesData from './core/Tables';
import TabsData from './core/Tabs';
import TypeData from './core/Type';
import UtilitiesData from './core/Utilities';
import AnimationData from './modules/Animation';
import PreloadData from './modules/Preload';
import SkrollHighjackData from './modules/SkrollHighjack';
import SkrollXData from './modules/SkrollX';

const mappings = {"EffectsData":"/effects","HomeData":"/home","ToolboxData":"/toolbox","AnimatedBordersData":"/effects/animatedborders","FadeData":"/effects/fade","LavaLampsData":"/effects/lavalamps","LoadingData":"/effects/loading","MistsData":"/effects/mists","SlideData":"/effects/slide","CanvasHelpersData":"/toolbox/canvashelpers","CssData":"/toolbox/css","DOMHelpersData":"/toolbox/domhelpers","GeometryHelpersData":"/toolbox/geometryhelpers","MathHelpersData":"/toolbox/mathhelpers","MiscHelpersData":"/toolbox/mischelpers","ScssData":"/toolbox/scss","ThemesData":"/toolbox/themes","BitBlackHolesData":"/bitfx/bitblackholes","BitExplosionsData":"/bitfx/bitexplosions","BitSwarmsData":"/bitfx/bitswarms","BitSystemsData":"/bitfx/bitsystems","bitfxData":"/bitfx/bitfx","CountOMetersData":"/components/countometers","GridGalleriesData":"/components/gridgalleries","LuckyDipsData":"/components/luckydips","NoticeBoxesData":"/components/noticeboxes","NumberSwitchersData":"/components/numberswitchers","OptionSelectorsData":"/components/optionselectors","PhotoTilesData":"/components/phototiles","SlideTickersData":"/components/slidetickers","SlideshowsData":"/components/slideshows","TimelinesData":"/components/timelines","ButtonsData":"/core/buttons","ColorsData":"/core/colors","FormsData":"/core/forms","GridData":"/core/grid","SlidersData":"/core/sliders","SpacingsData":"/core/spacings","TablesData":"/core/tables","TabsData":"/core/tabs","TypeData":"/core/type","UtilitiesData":"/core/utilities","AnimationData":"/modules/animation","PreloadData":"/modules/preload","SkrollHighjackData":"/modules/skrollhighjack","SkrollXData":"/modules/skrollx"}
export { EffectsData, HomeData, ToolboxData, AnimatedBordersData, FadeData, LavaLampsData, LoadingData, MistsData, SlideData, CanvasHelpersData, CssData, DOMHelpersData, GeometryHelpersData, MathHelpersData, MiscHelpersData, ScssData, ThemesData, BitBlackHolesData, BitExplosionsData, BitSwarmsData, BitSystemsData, bitfxData, CountOMetersData, GridGalleriesData, LuckyDipsData, NoticeBoxesData, NumberSwitchersData, OptionSelectorsData, PhotoTilesData, SlideTickersData, SlideshowsData, TimelinesData, ButtonsData, ColorsData, FormsData, GridData, SlidersData, SpacingsData, TablesData, TabsData, TypeData, UtilitiesData, AnimationData, PreloadData, SkrollHighjackData, SkrollXData };
export default mappings;