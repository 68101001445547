import {transitions} from '../../data';

const orientations = [
  "left",
  "right",
  "bottom",
  "top",
  "initial",
  "top-left",
  "top-right",
  "bottom-left",
  "bottom-right"
];

const directions = ["down", "left", "right", "up"];

const SkrollXSnippet = `
<div rwxsx class="rwxsx-start-{{ ORIENTATION }}">{{ HTML }}</div>
`;

const SkrollXPXSnippet = `
<div rwxpx>{{ HTML }}</div>
`;

const SkrollXData = {
  flatten: ['skrollx', 'parallax'],
  description: [
    `SkrollX gives you an easy way to transition elements in when they are scrolled in to view.`,
    `SkrollX also gives you parallax capabilities by transitioning elements at different speeds to the page scroll.`
  ],
  skrollx: {
    selector: "rwxsx",
    resourceName: "rwxSkrollX",
    roseworxPath: "modules",
    componentType: "individualJS",
    snippet: SkrollXSnippet,
    manualControl: false,
    roseworxCore: true,
    renew: true,
    config: {
      dataAttributes: [
        {
          key: 'data-rwxsx-trigger',
          value: "200",
          type: "number",
          display: "Trigger",
          options: ["Any number"],
          description: "The amount of pixels from the bottom of the visible viewport that the element has to scroll past before transitioning in."
        },
        {
          key: "data-rwxsx-delay",
          value: "0",
          type: "number",
          options: ["Any number"],
          display: "Delay (in seconds)",
          description: "The delay in seconds before the element transitions in."
        },
      ],
      classes: [
        {
          key: 'rwxsx-start-{orientation}',
          type: 'select',
          value: "left",
          display: "Orientation",
          options: orientations,
          description: `Start orientation for the element.`,
          applyToSelector: "[rwxsx]"
        },
        {
          key: '--{easing}',
          type: 'select',
          value: 'slow-in',
          display: 'Easing',
          options: [...transitions, 'elastic'],
          description: `The type of easing to use during the transition.`,
          applyToSelector: "[rwxsx]"
        }
      ]
    },
    notes: [
      {
        note: "The default start translation is 33%. You can customise & override this by specyfing your own transform: translate(X,Y); on the SkrollX node.",
        icon: "star"
      }
    ]
  },
  parallax: {
    selector: "rwxpx",
    resourceName: "rwxSkrollX",
    roseworxPath: "modules",
    componentType: "individualJS",
    snippet: SkrollXPXSnippet,
    manualControl: false,
    roseworxCore: true,
    renew: false,
    config: {
      dataAttributes: [
        {
          key: 'data-rwxpx-direction',
          value: "up",
          type: "select",
          display: "Direction",
          options: directions,
          description: "The direction to transform the element in."
        },
        {
          key: "data-rwxpx-speed",
          value: "10",
          type: "number",
          options: ["Any number"],
          display: "Speed",
          description: "The speed of the parallax effect. The lower the number, the faster the transition speed will be."
        },      
      ]
    }
  }
}

export default SkrollXData;