const EffectsData = {
  links: [
    {
      name: "Loading",
      link: "/effects/loading",
      description: "Loading effect."
    },
    {
      name: "Animated Borders",
      link: "/effects/animatedborders",
      description: "A sleak upgrade on traditional borders."
    },
    {
      name: "Fade",
      link: "/effects/fade",
      description: "Fade out/in effect."
    },
    {
      name: "Mists",
      link: "/effects/mists",
      description: "Mist effect for scrollable content."
    },
    {
      name: "Slide",
      link: "/effects/slide",
      description: "Sliding effect for content."
    },
    {
      name: "Lava Lamps",
      link: "/effects/lavalamps",
      description: "Background blob effect."
    },
  ]
}

export default EffectsData;