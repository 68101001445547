import React from 'react';

import EffectLayout from '../../layouts/EffectLayout';

import {LoremIpsumLong} from '../../data';

function MistsPage({data}) {
	
	const HTML = ({id}) => {
		return (
			<div className="rwxu-flex-center">
				<div style={{width:'300px', height:'400px'}} id={id}>
					<p style={{width: '600px', height:'800px'}}>{LoremIpsumLong} {LoremIpsumLong} {LoremIpsumLong} {LoremIpsumLong} {LoremIpsumLong} {LoremIpsumLong}</p>
				</div>
			</div>
		)
	}

	return (
		<EffectLayout data={data} HTML={HTML} component={window.rwx.mists} />
	)
}

export default MistsPage;