import React, {useRef, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route, Redirect, useLocation} from 'react-router-dom';
import {useTransition, animated} from 'react-spring';

import * as DataBundle from './data-servlets';
import {routes} from './siteSchema';
import ParseDataBundle from './parseDataBundle';

import './scss/index.scss';

import Header from './structural/Header';
import Navigation from './components/Navigation';

import rwxDOM from 'roseworx/js/helpers/rwxDOMHelpers';
import rwxSkrollX from 'roseworx/js/modules/rwxSkrollX';
import rwxLavaLamps from 'roseworx/js/effects/rwxLavaLamps';
require('roseworx/js/modules/rwxPreload');
require('roseworx');
window.rwx.skrollx = rwxSkrollX;
window.rwx.lavaLamps = rwxLavaLamps;

const DataContext = React.createContext()

function PageWrapper({children}){
	const [SiteData, setSiteData] = useState({});

	useEffect(()=>{
		ParseDataBundle(DataBundle).then((res)=>{
			setSiteData(res);
		});
	}, []);

	return (
		<DataContext.Provider value={SiteData}>
			<div className="page-wrapper rwxc-background-orange">
				<Navigation />
				<div style={{position:'relative', width:'100%'}} className="rwxc-background-white">
					{children}
				</div>
			</div>
		</DataContext.Provider>
	)
}

function PageAnimationWrapper({children}) {
	const location = useLocation();
	const pawref = useRef();

  const transitions = useTransition(location, {
    from: { opacity: 0, transform: 'translate3d(100vw, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-100vw, 0, 0)' },
    onRest: ()=>{
    	pawref.current.style.position = "static";
    }
  });

  useEffect(()=>{
  	window.scrollTo(0, 0);

  	const determinePulsate = (el) => {
  		let tu = el;
  		if(el.nodeName!=="SECTION")
  		{
				let cs = rwxDOM.hasAncestor(el, '.configuration-section');
				if(cs)
				{
					tu = cs;
				}
  		}
  		return tu;
  	}

  	if(location.hash)
  	{
  		setTimeout(()=>{
  			window.scrollTo(0, 0);
  			const hash = location.hash.replace('#', '');
	  		setTimeout(()=>{
		  		let el = document.getElementById(hash);
		  		if(!el)return
		  		determinePulsate(el).classList.remove('highlight-pulsate');
		  		let top = el.getBoundingClientRect().top;
	  			window.scrollTo({
	  				top: (window.pageYOffset + top)-40,
	  				left: 0,
	  				behavior: 'smooth'
	  			});
	  			setTimeout(()=>{
	  				determinePulsate((document.getElementById(hash))).classList.add('highlight-pulsate');
	  			}, 500);
	  		}, 500);
  		}, 10);
  	}
  }, [location]);

	return transitions((style, item ) => {
	  return <animated.div style={style} className="page-animation-wrapper" ref={pawref}>
	    <Switch location={item}>
	    	{children}
	    </Switch>
	  </animated.div>
	})
}

function Page({children, title}) {
	return (
		<div className={`page ${title}-page`}>
			<Header title={title} />
			<PageContent>{children}</PageContent>
		</div>
	)
}

function PageContent({children}) {
	return (
		<div className="page-content">
			{children}
		</div>
	)
}

function RenderPage({c, d, dataName}) {
	const C = c;
	if(process.env.NODE_ENV === "development" && d && Object.keys(d).length===0)
	{
		return <>
			<div className="rwx-split explicit">
				<div className="rwx-split-full">
					<p className="warning">Data object is empty - {dataName}</p>
				</div>
			</div>
			<C data={d}/>
		</>
	}
	return <C data={d}/>;
}

ReactDOM.render(
  <React.StrictMode>
  	<BrowserRouter>
			<PageWrapper>
	    	<PageAnimationWrapper>
	        {
	          routes.map((r)=>{
	            return <Route path={r.link.includes("_") ? r.link.replace("_", "") : r.link} key={r.text} exact>
	              {
	                r.text === "Home" ? <RenderPage c={r.component} d={DataBundle[r.dataName] || null} dataName={r.dataName} /> :
	                <Page title={r.text}><RenderPage c={r.component} d={DataBundle[r.dataName] || null} dataName={r.dataName} /></Page>
	              }
	              </Route>
	            }
	          )
	        }
	    		<Redirect to="/home" />
	    	</PageAnimationWrapper>
	    </PageWrapper>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('roseworx')
);

export { DataContext };