import React, {useEffect, useRef, useState} from 'react';
import rwxMisc from 'roseworx/js/helpers/rwxMiscHelpers';
// import rwxMath from 'roseworx/js/helpers/rwxMathHelpers';
import {getRandomColor} from '../helpers';

function Step({index, heading, extra, content, noarrow, extraHTML, icon}) {
	const isLeft = index%2===0;
	const color = getRandomColor();
	const i = useRef(index);
	const l = useRef(noarrow);
	const id = useRef(rwxMisc.uniqueId());

	useEffect(()=>{
		const index = i.current;
		const uid = id.current; 
		const lastOne = l.current;
		window.rwx.skrollx.hook(`step${index}-half1-${uid}`);
		window.rwx.skrollx.hook(`step${index}-half2-${uid}`);
		!lastOne && window.rwx.skrollx.hook(`step${index}-arrow-${uid}`);
		return()=>{
			window.rwx.skrollx.unhook(`step${index}-half1-${uid}`);
			window.rwx.skrollx.unhook(`step${index}-half2-${uid}`);
			!lastOne && window.rwx.skrollx.unhook(`step${index}-arrow-${uid}`);
		}
	},[]);

	return (
		<>
			<div className={`rwx-split explicit align step ${index > 0 ? 'rwxs-m-t-no' : ''} ${isLeft ? 'left' : 'right'}`}>
				<div className={`rwx-split-half rwxc-background-grey rwxsx-start-${isLeft ? 'left' : 'right'}`} id={`step${index}-half1-${id.current}`} rwxsx="true">
					<div className="step-content rwx-split vertical center-vertically rwxs-p-lg rwxt-text-center">
						<div className={`step-icon rwxc-background-${color} rwxs-p-b-lg rwxs-p-t-sm ${isLeft ? 'rwxs-p-l-sm rwxs-p-r-lg' : 'rwxs-p-r-sm rwxs-p-l-lg'}`}><i className={`rwxi-${icon ? icon : 'code'} --white --md`}></i></div>
						{heading && <h4 className={`rwxc-color-silver ${(extra || extraHTML) ? 'rwxs-m-b-sm' : 'rwxs-m-b-no'}`}>{heading}</h4>}
						{extra && extra.map((e,i)=><p key={`step-extra-${i}-${id}`} className={`small ${i===extra.length-1 ? 'rwxs-m-b-no' : ''}`}>{e}</p>)}
						{extraHTML && extraHTML.map((eh,i)=><p key={`step-extra-${i}-${id}`} className={`small ${i===extraHTML.length-1 ? 'rwxs-m-b-no' : ''}`} dangerouslySetInnerHTML={{__html:eh}}></p>)}
					</div>
				</div>
				<div className={`rwx-split-half rwxc-background-${color} rwxsx-start-${isLeft ? 'right' : 'left'}`} id={`step${index}-half2-${id.current}`} rwxsx="true">
					<div className="step-content rwxs-p-lg rwx-split vertical center-vertically">
						<div style={{width:"100%"}}>
						{content}
						</div>
					</div>
				</div>
			</div>
			{
				!noarrow &&
				<div className="step-arrow rwxsx-start-initial" rwxsx="true" id={`step${index}-arrow-${id.current}`}>
					<Arrow left={isLeft} />
				</div>
			}
		</>
	)
}

function Arrow({left}) {
	const isLeftRef = useRef(left);
	const svgRef = useRef();
	const pathRef = useRef();
	const [vb, setvb] = useState({width:"100", height:"100"});
	const [coords, setCoords] = useState(false);
	const [length, setLength] = useState(0);

	useEffect(()=>{
		const isLeft = isLeftRef.current;
		const ref = svgRef.current;
		const width = window.innerWidth;
		const height = ref.parentNode.offsetHeight;
		setvb({width, height});
		const x1 = isLeft ? (window.innerWidth/4) : (window.innerWidth/4)*3;
		const x2 = isLeft ? (window.innerWidth/4)*3 : (window.innerWidth/4);
		const start = {x: x1, y: 0};
		const end = {x: x2, y: height};
		const c1 = {x:isLeft ? x1-60 : x1+60, y:height*2};
		const c2 = {x:isLeft ? x2+60 : x2-60, y:-height};
		setCoords({start, c1, c2, end});
	},[]);

	useEffect(()=>{
		if(!pathRef.current){return;}
		const length = pathRef.current.getTotalLength()

		//build branches

		// let numberOfBranches = 10;
		// let lengthCounter = length/numberOfBranches;
		// let arr = [];
		// for(let i=0; i<numberOfBranches; i++)
		// {
		// 	const start = pathRef.current.getPointAtLength(i*lengthCounter + (lengthCounter));
		// 	const end = {
		// 		x: start.x + (i===0 ? !left ? 30 : -30 : left ? -30 : 30),
		// 		y: start.y + (i%2===0 ? 30 : -30)
		// 	};
			
		// 	arr.push({
		// 		start,
		// 		end,
		// 		deets: {
		// 			xr: rwxMath.randomInt(30,40),
		// 			yr: rwxMath.randomInt(30,40),
		// 			r: rwxMath.randomInt(-45, 45),
		// 			sf: i%2===0 ? 1 : 0
		// 		}
		// 	})
		// }

		// setBranches(arr);
		setLength(length);
	},[coords, left]);

	return (
		<svg viewBox={`0 0 ${vb.width} ${vb.height}`} xmlns="http://www.w3.org/2000/svg" ref={svgRef}>
			{ coords &&
				<path className="main-branch" ref={pathRef} strokeDasharray={length} strokeDashoffset={length} d={`M ${coords.start.x} ${coords.start.y} C ${coords.c1.x} ${coords.c1.y} ${coords.c2.x} ${coords.c2.y} ${coords.end.x} ${coords.end.y}`}/>
			}
		</svg>
	)
}

function Branch({coords}) {
	const [stroke, setStroke] = useState(0);
	const branchRef = useRef();

	useEffect(()=>{
		if(!branchRef.current)return;
		setStroke(branchRef.current.getTotalLength());
	}, []);

	return (
		<path className="branch" ref={branchRef} strokeDasharray={stroke} strokeDashoffset={stroke} d={`M ${coords.start.x} ${coords.start.y} A ${coords.deets.xr} ${coords.deets.yr} ${coords.deets.r} 0 ${coords.deets.sf} ${coords.end.x} ${coords.end.y}`} />
	)
}

export default Step;