const MathHelpersSnippet = `
import rwxMath from 'roseworx/js/helpers/rwxMathHelpers';
`;

const RandomIntSnippet = `
const Result = rwxMath.randomInt(0, 10);
// Result: 2
`;

const RandomFloatSnippet = `
const Result = rwxMath.randomFloat(0, 1);
// Result: 0.8075234674145297
`;

const MathHelpersData = {
  snippet: MathHelpersSnippet,
  methods: [
    {
      name: 'randomInt',
      returnType: 'Number',
      description: 'Generates a random number between 2 specified numbers (including both numbers).',
      example: RandomIntSnippet,
      parameters: [
        {
          name: 'Number 1',
          type: 'Number'
        },
        {
          name: 'Number 2',
          type: 'Number'
        }
      ]
    },
    {
      name: 'randomFloat',
      returnType: 'Number',
      description: 'Generates a random float between 2 specified numbers (including both numbers).',
      example: RandomFloatSnippet,
      parameters: [
        {
          name: 'Number 1',
          type: 'Number'
        },
        {
          name: 'Number 2',
          type: 'Number'
        }
      ]
    },
  ]
}

export default MathHelpersData;