import React from 'react';

import rwxBitBlackHoles from 'roseworx/js/bitfx/rwxBitBlackHoles';

import {BITFXHTML} from '../../common';

import StandardLayout from '../../layouts/StandardLayout';

function BitBlackHolesPage({data}) {
	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxBitBlackHoles} 
			LIVEHTML={BITFXHTML}
		/>
	)
}

export default BitBlackHolesPage;