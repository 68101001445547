import React from 'react';

import rwxBitSystems from 'roseworx/js/bitfx/rwxBitSystems';

import {BITFXHTML} from '../../common';

import StandardLayout from '../../layouts/StandardLayout';

function BitSystemsPage({data}) {
	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxBitSystems} 
			LIVEHTML={BITFXHTML}
		/>
	)
}

export default BitSystemsPage;