import rwxMisc from 'roseworx/js/helpers/rwxMiscHelpers';

const bits = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '!', '?', ','];
const shapes = ['square', 'circle', 'triangle', 'pentagon', 'diamond', 'cross', 'mixed'];
const orientations = ['horizontal', 'vertical', 'slanted', 'wrap'];


const bitfxConfig = {
  dataAttributes: [
    {
      key: 'value',
      type: 'text',
      display: "Bit Value",
      value: "RWX",
      options: bits,
      description: "Sets the character/word/sentence made up of any combination from the possible values."
    },
    {
      key: 'shape',
      options: shapes,
      type: 'select',
      display: "Bit shape",
      value: 'circle',
      description: 'Sets the shape of the letter particles.'
    },
    {
      key: 'orientation',
      options: orientations,
      type: 'select',
      display: "Orientation",
      value: 'horizontal',
      description: "Sets the orientation of the specified value. If 'wrap' is specified, '*' in the value denotes a carriage return."
    },
    {
      key: 'color',
      type: 'text',
      options: ['Any valid HEX color'],
      display: "Bit Color",
      value: "#FFFFFF",
      description: "Sets the color of the letter particles."
    },
    {
      key: 'background-color',
      type: 'text',
      options: ['Any valid HEX color', 'transparent'],
      display: "Background Color",
      value: "#000000",
      description: 'Sets the background color.'
    },
    {
      key: 'nofill',
      type: 'checkbox',
      options: ['true', 'false'],
      display: "No fill",
      value: "false",
      description: "Fill the bits with color"
    }
  ]
}

const getBitFxConfig = (c, add, exclude) => {
  let r = rwxMisc.safeCloneArrayOfObjects(bitfxConfig.dataAttributes);
  if(exclude)
  {
    r = r.filter((e)=>!exclude.includes(e.key));
  }
  r.map((re)=>re.key = `${c}-${re.key}`);
  add && r.push(...add);
  return {dataAttributes: r};
}

const BitFxSnippet = (selector, attrs) => {
  let str = `<div id="{{ UNIQUE ID }}"`
  attrs.map((a)=>{
    str += ` ${a.key}="{{ ${a.key.replace(`data-${selector}-`, "").replace("-", " ").toUpperCase()} }}"`;
    return false;
  });
  return str+=`></div>`;
}

export default getBitFxConfig;
export {BitFxSnippet}