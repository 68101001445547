import React from 'react';

import HubLayout from '../layouts/HubLayout';

function ToolboxPage({data}){
	return (
		<HubLayout data={data} />
	)
}

export default ToolboxPage;