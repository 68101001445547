import React, {useState} from 'react';
import {useSpring, animated} from 'react-spring';

import {CopySectionLinkText} from '../data';

import {useCopy} from '../hooks';

import {SectionTitle} from './Title';

function Section({children, title, hashPrepend, classes}) {
	const [open, setOpen] = useState(false);
	const identifier = `${hashPrepend ? `${hashPrepend}-` : ''}${title.toLowerCase()}`
	const copy = useCopy();

	const CopySpring = useSpring(
		{
			width: open ? 60 : 12, 
			config:{mass: 1, tension: 160, friction: 10}
		}
	);

	const ScaleSpring = useSpring(
		{
			transform: open ? 'scale(1)' : 'scale(0)', 
			config:{mass: 1, tension: 160, friction: 10}
		}
	);

	const Copied = () => {
		copy(`${window.location.href.replace(window.location.hash, '')}#${identifier}`);
		setOpen(false);
	}

	return (
		<section className={classes} id={identifier}>
			{title && <SectionTitle title={title} />}
			{children}
			<animated.div className="copy-link rwx-split vertical space-around" onClick={Copied} title={CopySectionLinkText} style={CopySpring} onMouseEnter={()=>setOpen(true)} onMouseLeave={()=>setOpen(false)}>
				<animated.i style={ScaleSpring} className="rwxi-copy --md --white rwxs-m-l-xs"></animated.i>
				<animated.i style={ScaleSpring} className="rwxi-copy --md --white rwxs-m-l-xs"></animated.i>
				<animated.i style={ScaleSpring} className="rwxi-copy --md --white rwxs-m-l-xs"></animated.i>
			</animated.div>
		</section>
	)
}

export default Section;