import React, {useState} from 'react';
import useRoseworx from 'roseworx/js/useRoseworx';
import CodeSnippet from './structural/CodeSnippet';
import InteractiveDemo from './components/InteractiveDemo';
import {parseDataAttributes, cloneConfig} from './helpers';
import { 
	ImportStepHeading, 
	MarkupStepHeading,
	MarkupStepExtra,
	ConfigureStepHeading, 
	ConfigureStepText,
	IndividualSCSSImportText,
	IndividualJSImportText,
	CoreJSImportText,
	CoreSCSSImportText,
	ExamplesStepHeading,
	ExamplesStepExtra,
	ComponentThatDoesntShowOnInitMessage,
	RenewMethod
} from './data';

const DependancyStep = (type, dependancy, link, roseworxPath, inCore=false) => {
	if(type === "scss")
	{
		return {
			heading: "SCSS Dependancy",
			icon: "dependancy",
			extraHTML:[`This component has a dependancy - <a href="${link}">${dependancy}</a>. Please additionally import this SCSS file before the resource SCSS file if you haven't already. ${inCore ? 'This dependancy is part of the Roseworx core, so if you have imported that you dont need to do anything.' : ''}`],
			content: <CodeSnippet copyLeft={true} language="css" data={`@import "~roseworx/scss/${roseworxPath}/${dependancy}";`}/>
		}
	}
}

const ImportStep = (name, path, componentType, named=false)=>{
	const Heading = ({h, margin}) => {
		return (
			<p className={`rwxt-text-center ${margin ? 'rwxs-m-t-lg' : ''}`}><strong className="rwxc-color-white impact">{h}</strong></p>
		)
	}

	const IndividualJSImport = ({margin}) => {
		return ( <>
				<Heading h="Individual component JS" margin={margin}/>
				<CodeSnippet language="javascript" copyLeft={true} data={`import ${named ? '{' : ''}${name}${named ? '}' : ''} from 'roseworx/js/${path}/${name}';`}/>
			</>
		)
	}

	const IndividualSCSSImport = ({margin})=> {
		return ( <>
				<Heading h="Individual component SCSS" margin={margin}/>
				<CodeSnippet copyLeft={true} language="css" data={`@import "~roseworx/scss/${path}/${name}";`}/>
			</>
		)
	}

	const CoreJSImport = ({margin}) => {
		return ( <>
				<Heading h="Core JS" margin={margin}/>
				<CodeSnippet language="javascript" copyLeft={true} data="import rwx from 'roseworx';"/>
			</>
		)
	}

	const CoreSCSSImport = ({margin}) => {
		return ( <>
				<Heading h="Core SCSS" margin={margin}/>
				<CodeSnippet copyLeft={true} language="css" data={`@import "~roseworx/scss/roseworx";`}/>
			</>
		)
	}

	if(componentType === "individualJS")
	{
		return {
			heading: ImportStepHeading, 
			extra: [IndividualJSImportText], 
			content: <IndividualJSImport />,
			icon: 'download'
		}
	}
	else if(componentType === "individualSCSS")
	{
		return {
			heading: ImportStepHeading, 
			extra: [IndividualSCSSImportText], 
			content: <IndividualSCSSImport />,
			icon: 'download'
		}
	}
	else if(componentType === "individualJSAndSCSS")
	{
		return {
			heading: ImportStepHeading, 
			extra: [ 
				IndividualSCSSImportText,
				IndividualJSImportText
			], 
			content: <>
				<IndividualSCSSImport />
				<IndividualJSImport margin={true}/>
			</>,
			icon: 'download'
		}
	}
	else if(componentType === "coreJSAndSCSS")
	{
		return {
			heading: ImportStepHeading, 
			extra: [ 
				CoreSCSSImportText,
				CoreJSImportText
			], 
			content: <>
				<CoreSCSSImport />
				<CoreJSImport margin={true} />
				<IndividualJSImport margin={true} />
			</>,
			icon: 'download'
		}
	}
	else if(componentType==="coreSCSS")
	{
		return {
			heading: ImportStepHeading, 
			extra: [CoreSCSSImportText], 
			content: <CoreSCSSImport />
		}
	}
	else
	{
		return null;
	}
}

const ExamplesStep = (snippet) => {
	const determineLanguage = (l) => {
		return l.includes('const') ? 'javascript' : 'html'; 
	}

	return {heading: ExamplesStepHeading, extra: [ExamplesStepExtra], content: <CodeSnippet language={determineLanguage(snippet)} data={snippet} copyLeft={true}/>}
}

const MarkupStep = (html) => {
	const Snippet = () => {
		return Array.isArray(html) ?
				<>{html.map((h,i)=>
					<div key={`html-snippet-${i}`} className={`${i>0 ? 'rwxs-m-t-lg' : ''}`}>
						{h.descriptor && <p className="rwxt-text-center"><strong className="rwxc-color-white impact">{h.descriptor}</strong></p>}
						<CodeSnippet language="html" data={h.html} copyLeft={true} />
					</div>
					)}
				</>
			:
				<CodeSnippet language="html" data={html} copyLeft={true} />
	}
	return {heading:MarkupStepHeading, content: <Snippet />, extra:[MarkupStepExtra]}
}

const ConfigureStep = () => {
	const Text = () => {
		return (
			<div className="radius rwxs-p-sm step-shadow"><p className="rwxs-m-b-no"><strong className="rwxc-color-white">{ConfigureStepText}</strong></p></div>
		)
	}
	return {heading:ConfigureStepHeading, content: <Text />, icon: 'personalise'}
}

const BITFXHTML = ({id, config}) =>{
	return (
		<div className="rwxu-full-size"><div id={id} {...parseDataAttributes(config.dataAttributes)}></div></div>
	)
}

const DemoWrapper = ({id, component, conf, methods, LIVEHTML, renew, small, noParallax}) => {
  useRoseworx(component, id);
  const cloned = conf ? cloneConfig(conf) : false;
  const [config, setConfig] = useState(cloned);

  const refresh = (c) => {
    setConfig(c);
    if(!component)return;
    component.renew(id);
  }

  const ms = renew ? methods ? [...methods, RenewMethod] : [RenewMethod] : methods;

  return (
    <InteractiveDemo config={config} refreshFn={refresh} id={id} renew={renew} methods={{methods:ms, component:component}} small={small} noParallax={noParallax}>
      <LIVEHTML id={id} config={config}/>
    </InteractiveDemo>
  )
}

const UseLiveInteractionToViewComponent = () => {
	return (
		<p className="rwxt-text-center"><strong className="impact">{ComponentThatDoesntShowOnInitMessage}</strong></p>
	)
}

export {
	ImportStep,
	MarkupStep,
	ConfigureStep,
	ExamplesStep,
	DependancyStep,
	BITFXHTML,
	DemoWrapper,
	UseLiveInteractionToViewComponent
}