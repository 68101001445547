const NumberSwitchersSnippet = [
  {
    descriptor: "Self",
    html: `
<div class="rwx-number-switcher" rwx-number-switcher id="{{ UNIQUE ID }}"></div>
`
  },
  {
    descriptor: "With text",
    html: `
<div class="rwx-number-switcher" rwx-number-switcher id="{{ UNIQUE ID }}">
  <span>{{ TEXT }}</span>
</div>
`
  },
  {
    descriptor: "With icon",
    html: `
<div class="rwx-number-switcher" rwx-number-switcher id="{{ UNIQUE ID }}">
  <i class="rwxi-{{ ICON }}"></i>
</div>
`
  },
  {
    descriptor: "With image",
    html: `
<div class="rwx-number-switcher" rwx-number-switcher id="{{ UNIQUE ID }}">
  <img src="{{ IMG SRC}}" />
</div>
`
  },
];

const NumberSwitchersData = {
  selector: "rwx-number-switcher",
  componentType: "individualJS",
  roseworxPath: "components",
  manualControl: false,
  roseworxCore: true,
  renew: false,
  snippet: NumberSwitchersSnippet,
  config: {
      dataAttributes: [
      {
        key: 'data-rwx-number-switcher-initial-value',
        type: 'number',
        options: ['Any valid number'],
        display: "Initial number",
        value: "0",
        description: "Sets the initial number."
      },
      {
        key: 'data-rwx-number-switcher-stop-at-zero',
        type: 'checkbox',
        options: ['true', 'false'],
        display: "Stop at zero",
        value: "false",
        description: "If you set the Number Switcher to switch to a number below 0, you can set this attribute to tell the component to not go into negative numbers and to stop at 0."
      },
    ],
    classes: [
      {
        key: '--right',
        type: 'checkbox',
        value: "false",
        display: "Align right",
        description: "You can add a <span>, <i> (icon) or <img> inside the .rwx-number-switcher and use this flag to display the number to the right of it."
      },
    ]
  },
  methods: [
    {
      name: 'switch',
      returnType: 'void',
      description: 'Call this method to change the number on the number switcher.',
      example: ".switch('{ID}', {NUMBER})",
      interactable: true,
      interactableInputs: [
        {
          key: 'getSwitchNumber',
          type: 'number',
          value: "false"
        },
      ],
      parameters: [
        {
          name: 'ID',
          type: 'String'
        },
        {
          name: 'Number',
          type: 'Number'
        }
      ]
    },
  ]
}

export default NumberSwitchersData;