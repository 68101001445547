import React from 'react';

import {MainTitle} from './Title';

function Header({title}) {
  return (
    <header className="rwxs-p-t-md rwxs-p-b-md rwxs-m-b-xl rwxc-background-blue rwxc-color-white">
      {title && <MainTitle title={title} />}
    </header>   
  )
}

export default Header;