const FadeSnippet = `
const element = document.getElementById('fade');
rwxFade.fadeIn(element);
element.addEventListener('click', ()=>{
  rwxFade.fadeOut(element);
});
`;

const FadeData = {
  description: ["Fade in & / or out anything using these fade methods."],
  selector: 'rwx-fade',
  roseworxPath: 'effects',
  componentType: 'individualJS',
  manualControl: false,
  roseworxCore: false,
  renew: false,
  snippet: FadeSnippet,
  methods: [
    {
      name: 'fadeIn',
      returnType: 'void',
      description: 'Fade an element in. If fading an element in without fading it out first it must start with a z-index:-1; opacity: 0;',
      example: ".fadeIn(HTMLElement);",
      interactable: true,
      useElement:true,
      parameters: [
        {
          name: 'element',
          type: 'HTMLElement'
        },
      ]
    },
    {
      name: 'fadeOut',
      returnType: 'void',
      description: 'Fade an element out.',
      example: ".fadeOut(HTMLElement);",
      interactable: true,
      useElement:true,
      parameters: [
        {
          name: 'element',
          type: 'HTMLElement'
        },
      ]
    }
  ]
}

export default FadeData;