import React from 'react';

import rwxLuckyDips from 'roseworx/js/components/rwxLuckyDips';

import StandardLayout from '../../layouts/StandardLayout';

import {parseDataAttributes} from '../../helpers';

function LuckyDipsPage({data}) {

	const items = Array.from(Array(6).keys());

	const HTML = ({id, config}) =>{
		setTimeout(()=>{
			rwxLuckyDips.onSelected(id, (v)=>alert(`You got - ${v}`));
		}, 1000);

		return (
			<div className="rwxu-full-size">
				<div className="rwx-lucky-dip" id={id} {...parseDataAttributes(config.dataAttributes)}>
					{
						items.map((i)=><div key={`lucky-dip-demo-item-${i}`} className="rwx-lucky-dip-item" data-rwx-lucky-dip-value={`Option ${i+1}`}><span>Option {i+1}</span></div>)
					}
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxLuckyDips} 
			LIVEHTML={HTML}
		/>
	)
}

export default LuckyDipsPage;