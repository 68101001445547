import React, {useEffect, useState, useRef} from 'react';

import rwxMisc from 'roseworx/js/helpers/rwxMiscHelpers';
import rwxScrollTrack from 'roseworx/js/common/rwxScrollTracking';

import {useSpring, animated} from 'react-spring';

function MainTitle({title}) {
	const style = useSpring({
		from: {transform: 'scale(0)'},
		to: {transform: 'scale(1)'},
		config:  {mass: 3, tension: 200, friction: 13 },
		delay: 500
	})

	return (
		<animated.h1 style={style} className="rwxt-text-center page-title rwxs-m-b-no rwxs-p-l-sm rwxs-p-r-sm">{title}</animated.h1>
	)
}

function SectionTitle({title}) {
	const titleRef = useRef();
	const [render, setRender] = useState(false);
	useEffect(()=>{
		if(!titleRef.current)return;
		const id = rwxMisc.uniqueId();
		const fn = ()=>{
			if(!titleRef.current)return;
			if(titleRef.current.getBoundingClientRect().top<(window.innerHeight-100))
			{
				setRender(true);
				rwxScrollTrack.remove(id);
			}
		}
		rwxScrollTrack.add(fn, id);
		fn();

		return ()=>{
			rwxScrollTrack.remove(id);		
		}
	},[])

	const letters = [...title];

	return (
		<h3 ref={titleRef} className="rwx-split center rwxs-p-l-md rwxs-p-r-md rwxt-text-center">{letters.map((l,i)=><SectionTitleLetter go={render} letter={l} index={i} key={`sectiontitle${l}${i}`}/>)}</h3>
	)
}

function SectionTitleLetter({letter, index, go}){
	const style = useSpring({
		from: {transform: go ? `translateY(-66px)` : 'translateY(0)', opacity: go ? 0 : 1},
		to: {transform: 'translateY(0)', opacity:1},
		config:  {mass: 3, tension: 200, friction: 13 },
		delay: index*100
	})
	return (
		go ?
			<animated.span style={{whiteSpace:"pre", ...style}}>{letter}</animated.span>
		:
		 null
	)
}

export {MainTitle, SectionTitle}