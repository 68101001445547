import React from 'react';
import Step from '../structural/Step';
import Section from '../structural/Section';

function Instructions({steps, hashPrepend}) {
	return (
		<Section title="Instructions" hashPrepend={hashPrepend} classes="instructions-section">
			<div className="rwxs-p-t-md">
				{
					steps.map((s,i)=><Step key={`InstructionsStep${i}`} index={i} noarrow={i===steps.length-1} {...s} />)
				}
			</div>
		</Section>
	)
}

export default Instructions;