import {AccesibilityNote} from '../../data';

const effects = ['bubble', 'spin', 'pixelated', 'slideRandom', 'slideLeft', 'slideRight', 'slideUp', 'slideDown', 'random', 'puzzle'];

  const PhotoTilesSnippet = `
<div class="rwx-phototile" id="{{ UNIQUE ID }}" rwx-phototile>
  <img src="{{ IMAGE SRC }}" alt="{{ ALT TEXT }}" />
  <img src="{{ IMAGE SRC }}" alt="{{ ALT TEXT }}" />
  <div class="rwxc-background-{{ COLOR }}"></div>
</div>
  `;

const PhotoTilesData = {
  selector: "rwx-phototile",
  roseworxPath: "components",
  componentType: "individualJSAndSCSS",
  manualControl: false,
  roseworxCore: true,
  renew: false,
  snippet: PhotoTilesSnippet,
  dependancy: {
    type: "scss",
    dependancy: "rwxLoading",
    link: "/effects/loading",
    roseworxPath: "effects",
    inCore: true
  },
  config: {
    dataAttributes: [
      {
        key: 'data-rwx-phototile-effect',
        type: 'select',
        display: "Animation style",
        value: "random",
        options: effects,
        description: `Sets the transition between tiles to the specified animation style.`
      },
      {
        key: 'data-rwx-phototile-no-thumbnails',
        type: 'checkbox',
        display: "No thumbnails",
        value: "false",
        options: ["true", "false"],
        description: "Whether or not to display clickable thumbnails of the tiles."
      },
      {
        key: 'data-rwx-phototile-auto',
        type: 'checkbox',
        display: "Autoloop",
        value: "false",
        options: ["true", "false"],
        description: "Put the tiles on an autoloop which switches the tiles every N seconds specified by data-rwx-phototile-auto-timeout."
      },
      {
        key: 'data-rwx-phototile-auto-timeout',
        type: 'number',
        display: "Timeout",
        value: "5",
        options: ["Any valid number in seconds"],
        description: "The time in seconds it takes to transition to the next tile, providing data-rwx-phototile-auto is set to true."
      },
    ]
  },
  methods: [
    {
      name: 'goToTile',
      returnType: 'void',
      description: 'Call this method to programatically transition to a particular tile.',
      example: ".goToTile('{ID}', {TILENUMBER}, '{EFFECT}')",
      interactable: true,
      interactableInputs: [
        {
          key: 'getTileNumber',
          type: 'number',
          value: "false"
        },
        {
          key: 'getEffect',
          type: 'select',
          value: "false",
          options: effects
        }
      ],
      parameters: [
        {
          name: 'ID',
          type: 'String'
        },
        {
          name: 'Tile Number',
          type: 'number'
        },
        {
          name: 'Effect',
          type: `String`,
          options: effects    
        }
      ]
    },
  ],
  notes: [
    AccesibilityNote,
    {
      note: "A phototile doesn't have to be an <img>, it can be an empty div with a solid background color on it.",
      icon: "star"
    },
    {
      note: "Phototiles don't all have to be the same size, different sizes work to!",
      icon: "star"
    }
  ]
}

export default PhotoTilesData;