const types = ['m', 'p'];
const directions = ['l', 'r', 'b', 't'];
const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'no'];

  const SpacingsSnippet = `
<div class="rwxs-m-lg"></div>

<p class="rwxs-p-t-md"></p>

<section class="rwxs-m-t-lg rwxs-m-b-lg rwxs-m-t-sm-rsp rwxs-m-b-sm-rsp"></section>

<span class="rwxs-p-sm"></span>

<h1 class="rwxs-m-b-no"></h1>

<div class="rwxs-m-l-md rwxs-m-r-no rwxs-p-t-lg rwxs-p-b-sm rwxs-p-no-rsp rwxs-m-l-lg-rsp rwxs-m-r-lg-rsp"></div>
`;

const SpacingsScssMapOverride = `
$spacings: (
  'xxs': 2px,
  'sme': 20px,
  'xxl': 48px,
  'md': 26px
);
`;

const SpacingsData = {
  selector: "spacings",
  roseworxPath: "core",
  componentType: "coreSCSS",
  manualControl: false,
  roseworxCore: false,
  renew: false,
  snippet: SpacingsSnippet,
  config: {
    classes: [
      {
        key: 'rwxs-p-{size}',
        description: `Apply padding at the specified size in all directions. You can apply both ${types.join(' & ')} on one element.`,
        applyToSelector: 'Any element',
        type: "select",
        options: sizes,
        value: "md",
        display: "Padding size"
      },
      {
        key: 'rwxs-p-{direction}-{size}',
        description: `Apply padding only in a specific direction with a specific size. You can apply multiple directions on one element.`,
        applyToSelector: 'Any element',
        type: "select",
        value: "false",
        display: "Padding direction",
        options: ['N/A', ...directions]
      },
      {
        key: 'rwxs-p-{direction}-{size}-rsp',
        description: `Apply padding (overrides matching spacing modifiers) only when the viewport goes below the 'md' breakpoint. Omit {direction} for all directions.`,
        applyToSelector: 'Any element',
        type: "select",
        options: ['N/A', ...sizes],
        value: "false",
        display: "Responsive padding size",
        useForOptions: "{}"
      },
      {
        key: 'rwxs-m-{size}',
        description: `Apply margin at the specified size in all directions. You can apply both ${types.join(' & ')} on one element.`,
        applyToSelector: 'Any element',
        type: "select",
        options: sizes,
        value: "md",
        display: "Margin size"
      },
      {
        key: 'rwxs-m-{direction}-{size}',
        description: `Apply margin only in a specific direction with a specific size. You can apply multiple directions on one element.`,
        applyToSelector: 'Any element',
        type: "select",
        value: "false",
        display: "Margin direction",
        options: ['N/A', ...directions]
      },
      {
        key: 'rwxs-m-{direction}-{size}-rsp',
        description: `Apply margin (overrides matching spacing modifiers) only when the viewport goes below the 'md' breakpoint. Omit {direction} for all directions.`,
        applyToSelector: 'Any element',
        type: "select",
        options: ['N/A', ...sizes],
        value: "false",
        display: "Responsive margin size",
        useForOptions: "{}"
      }
    ]
  },
  scssVariables: [
    {
      key: "$extra-small-spacing",
      description: `Extra small spacing (xs)`,
      value: "8px",
      options: ["Any valid CSS length"]
    },
    {
      key: "$small-spacing",
      description: `Small spacing (sm)`,
      value: "16px",
      options: ["Any valid CSS length"]
    },
    {
      key: "$medium-spacing",
      description: `Medium spacing (md)`,
      value: "24px",
      options: ["Any valid CSS length"]
    },
    {
      key: "$large-spacing",
      description: `Large spacing (lg)`,
      value: "32px",
      options: ["Any valid CSS length"]
    },
    {
      key: "$extra-large-spacing",
      description: `Extra large spacing (xl)`,
      value: "40px",
      options: ["Any valid CSS length"]
    },
  ],
  scssMaps: [
    {
      snippet: SpacingsScssMapOverride,
      title: "Spacings",
      description: [
        "There is a spacings variable containing an array of spacings.",
        "You can specify your own SCSS map of spacings using the $spacings scss variable and it will get merged with the default one. If you put a key with the same name, it will override the default.",
        "This example will create 3 new spacings (xxs, sme, xxl) and will override the spacings value of md. All other spacings from the original map will still be avaiable.",
        "You can then use these spacings as you would the defaults - e.g .rwxs-m-xxs, .rwxs-p-xxl, .rwxs-m-t-sme"
      ]
    }
  ],
  notes: [
    {
      note: "-rsp classes will apply below the 'md' endpoint. For more granularity, add -rsp-sm or -rsp-lg to apply spacings below the small and large breakpoints.",
      icon: "star"
    }
  ]
};

export default SpacingsData;