import React, {useState, useRef, useEffect} from 'react';
import {LiveConfigurationTitle, LiveFunctionTitle} from '../data';
import DemoBox from '../structural/DemoBox';
import FieldDistributor from '../form-fields/FieldDistributor';
import {cloneConfig} from '../helpers';
import useRoseworx from 'roseworx/js/useRoseworx';
import {useSpring, animated} from 'react-spring';


function ConfigurationForm({config, refresh, id}) {
	const identifier = useRef(`config-form-${id}`);
	const rf = useRef(refresh);
	useRoseworx(window.rwx.forms, identifier.current);

	useEffect(()=>{
		const id = identifier.current;
		const refr = rf.current;
		window.rwx.forms.customSubmitFn(id, (values) => {
			for(let v of Object.keys(values))
			{
				if(v.includes('radio-group'))
				{
					const val = values[v];
					values[val] = "true";
				}
			}
		  refr(values);
		});
	}, []);

	return (
		<form className="rwx-form rwxs-m-b-no" id={identifier.current}>
			<div className="restrict-height">
				{
					config.dataAttributes && <FieldDistributor data={config.dataAttributes} id={id} />
				}
				{
					config.classes && <FieldDistributor data={config.classes} id={id} />
				}
				{
					config.demo && <FieldDistributor data={config.demo} id={id} />
				}
			</div>
			<div className="rwx-split center">
				<button type="submit" className="--white --bullet --inverted">Render</button>
			</div>
		</form>
	)
}


function LiveInteraction({config, refresh, id}) {
	const [show, setShow] = useState(false);
	const dontClose = useRef();

	const {transform} = useSpring(
		{
			transform: show ? 1 : 0, 
			config:{mass: 1, tension: 160, friction: 10}
		}
	);

	const closeOnRefresh = (p) =>{
		setShow(false);
		refresh(p);
	}

	useEffect(()=>{
		const ev = (e)=>{
			if(e.target === dontClose.current || dontClose.current.contains(e.target))return
			setShow(false);
		};
		document.body.addEventListener('click', ev);
		return ()=>document.body.removeEventListener('click',ev);
	},[]);

	return (
		<div ref={dontClose}>
			<button title={LiveConfigurationTitle} className="live-interaction no-decoration rwxc-color-white" onClick={()=>setShow(!show)}>
				<i className="rwxi-spanner --white --md" />
			</button>
			<animated.div style={{visibility: show ? 'visible' : 'hidden', transform: transform.to(x=>`scale(${x<0 ? 0 : x})`)}} aria-hidden={!show} className="configuration-options radius rwxs-p-sm rwxc-color-white">
				<h5 className="underline rwxt-text-center">Configuration options</h5>
				<ConfigurationForm refresh={closeOnRefresh} config={config} id={id}/>
			</animated.div>
		</div>
	)
}

function LiveFunction({id, methods}) {
	const [show, setShow] = useState(false);
	const dontClose = useRef();

	const open = useSpring(
		{
			transform: show ? 'scale(1)' : 'scale(0)', 
			config:{mass: 1, tension: 160, friction: 10}
		}
	);

	const runFunction = (i) => {
		if(methods.methods[i].interactableInputs)
		{
			const vals = [];
			for(let input of methods.methods[i].interactableInputs)
			{
				let val = document.getElementById(input.key).value;
				if(input.type==="number")val=parseInt(val);
				if(input.type==="checkbox")val=document.getElementById(input.key).checked;
				vals.push(val);
			}
			methods.component[methods.methods[i].name](methods.methods[i].useElement ? document.getElementById(id) : id, ...vals);
		}
		else
		{
			methods.component[methods.methods[i].name](methods.methods[i].useElement ? document.getElementById(id) : id);
		}
	}

	useEffect(()=>{
		const ev = (e)=>{
			if(e.target === dontClose.current || dontClose.current.contains(e.target))return
			setShow(false);
		};
		document.body.addEventListener('click', ev);
		return ()=>document.body.removeEventListener('click',ev);
	},[]);

	return (
		<div ref={dontClose}>
			<button title={LiveFunctionTitle} className="live-function no-decoration rwxc-color-white" onClick={()=>setShow(!show)}>
				<i className="rwxi-cogs-fill --white --md" />
			</button>
			<animated.div style={{visibility: show ? 'visible' : 'hidden', ...open}} aria-hidden={!show} className="methods radius rwxs-p-sm rwxc-color-white">
				<div className="overflower">
					{
						methods && methods.methods && methods.methods.map((m, i)=> m.interactable && 
							<div key={`live-method-${id}-${i}`} className="interactable-method rwxs-m-l-sm">
								<button title={m.description} onClick={()=>runFunction(i)} type="submit" className="--white --bullet --inverted">{m.name}</button>
								<div>
								{
									m.interactableInputs && <FieldDistributor classes="rwxs-m-b-no rwxs-m-t-sm" data={m.interactableInputs} id={`interactable-method-${i}`}/>
								}
								</div>
							</div>
						)
					}
				</div>
			</animated.div>
		</div>
	)
}

function InteractiveDemo({config, children, refreshFn, renew, id, methods, small, noParallax, noLiveInteraction}) {
	const refresh = (newValues)=>
	{
		const cc = cloneConfig(config);
		Object.keys(newValues).map((nv)=>{
			if(cc.dataAttributes)
			{
				let match = cc.dataAttributes.find((da)=>nv===da.key);
				if(match)match.value=newValues[nv].toString();
			}
			if(cc.classes)
			{
				let match2 = cc.classes.find((da)=>nv===da.key);
				if(match2)match2.value=newValues[nv].toString();
			}
			if(cc.demo)
			{
				let match3 = cc.demo.find((da)=>nv===da.key);
				if(match3)match3.value=newValues[nv].toString();				
			}
			return false;
		});
		refreshFn(cc);
	}

	const hasLiveFunction = methods && methods.methods && methods.methods.filter((m)=>m.interactable).length > 0;

	return (
		<>
			<DemoBox small={small} noParallax={noParallax}>
				<div className="interactive-demo rwx-split center">
					<div style={{margin:"auto", width: "100%"}}>
						{children}
					</div>
				</div>
				{
					config && !noLiveInteraction && <LiveInteraction config={config} refresh={refresh} id={id}/>
				}
				{
					hasLiveFunction && <LiveFunction id={id} methods={methods} />
				}
			</DemoBox>
		</>
	)
}
export default InteractiveDemo;