const ParseDataBundle = (DataBundle) => {
  return new Promise((resolve, reject)=>{

    const {parsedDataBundle, URLMappings} = flattenDataStructure(DataBundle);

    for(let key of Object.keys(parsedDataBundle))
    {
      let access = parsedDataBundle[key];
      let display = `${key.charAt(0).toUpperCase()}${key.slice(1).replace('Data', '')}`;

      determineCoreResources(access, display);
    
      determineRoseworxCore(access, display);

      indexDataForSearch(access, display, URLMappings[key]);

      calculateConglomoratedCSS(access, display);
    }

    siteIndex.sort((a,b)=>{
      if (a.sortOrder < b.sortOrder) return -1;
      if (a.sortOrder > b.sortOrder) return 1;
      return 0;
    });
    resolve({coreResources, siteIndex, allClasses, allSCSS, roseworxCore});
  })
}


// Calculates all available utility an modifier classes in the core import of roseworx
// Calculates all the available SCSS customisations in the core import of roseworx
const allClasses = {};
const allSCSS = {};
const calculateConglomoratedCSS = (a, d) =>
{
  if(!a.componentType)return;
  if(!a.componentType.includes('core'))return;
  let obj = {utilityClasses:[], modifierClasses:[]};
  let obj2 = {maps: [], mixins: [], variables:[]};
  if(a.config && a.config.classes)
  {
    a.config.classes.map((c)=>obj[c.key.includes('--') ? 'modifierClasses' : 'utilityClasses'].push(c));
    obj.rootElement = a.selector;
    allClasses[d] = obj;
  }
  a.scssVariables && obj2.variables.push(...a.scssVariables);
  a.scssMixins && obj2.mixins.push(...a.scssMixins);
  a.scssMaps && obj2.maps.push(...a.scssMaps);
  allSCSS[d] = obj2;
}

// Flattens data which contains flatten array into its own indexable object & creates new url mapping for it using #
// And then merges it with the mappings from the data bundle.
const flattenMappings = {};
const flattenDataStructure = (db) => {
  let parsedDataBundle = {};
  for(let key of Object.keys(db))
  {
    if(key === "default") continue;
    parsedDataBundle[key] = db[key];
    if(typeof db[key] === "object")
    {
      db[key].flatten && db[key].flatten.map((f)=>{
        parsedDataBundle[f] = db[key][f];
        parsedDataBundle[f].parent = key;
        flattenMappings[f] = `${db.default[key]}#${f}`;
        return false;
      })
    }
  }
  return {parsedDataBundle, URLMappings:{...flattenMappings, ...db.default}};
}

// Responsible for building an array of all the resources included in the core import of roseworx.
const coreResources = [];
const determineCoreResources = (a, d) => {
  if(a.componentType && a.componentType.includes('core'))
  {
    coreResources.push(d);
  }
}

// Responsible for determining all resources that extend the RoseworxCore class constructor
const roseworxCore = [];
const determineRoseworxCore = (a, d) => {
  if(a.roseworxCore)
  {
    roseworxCore.push(d);
  }
}


// Responsible for building site index for the search
const siteIndex = [];
const indexIgnore = [
  'home',
  'bitfx'
];

const indexDataForSearch = (a, d, u) => {
  
  // Index actual resource Name
  const isHub = u.lastIndexOf('/') === 0;  
  if(!indexIgnore.includes(d.toLowerCase()) && !siteIndex.find((s)=>d===s.result))
  {
    siteIndex.push({
      link: u,
      result: d,
      resultType: isHub ? 'page' : 'resource',
      searchTerm: d.toLowerCase(),
      sortOrder: 1,
      type: 'resource'
    })
  }

  // Index methods
  if(a.methods) {
    a.methods.map((m)=>{
      siteIndex.push({
        link: u.indexOf('#') !== -1 ? `${u}-${m.name.replace('.', '')}` : `${u}#${m.name.replace('.', '')}`,
        result: m.name,
        resultType: 'Method',
        resultDescriptor: `Resource - ${d}`,
        searchTerm: m.name.toLowerCase(),
        sortOrder: 2,
        type: 'method'
      })
      return false;
    })
  }

  // Index classes
  if(a.config?.classes) {
    a.config.classes.map((c)=>{
      siteIndex.push({
        link: u.indexOf('#') !== -1 ? `${u}-classes` : `${u}#classes`,
        result: c.key,
        resultType: c.key.indexOf('--') !== -1 ? 'CSS modifier class' : 'CSS utility class',
        resultDescriptor: `Resource - ${d}`,
        searchTerm: c.key.toLowerCase(),
        sortOrder: 3,
        type: 'configuration'
      })
      return false;
    })
  }

  //Index data attributes
  if(a.config?.dataAttributes) {
    a.config.dataAttributes.map((da)=>{
      siteIndex.push({
        link: u.indexOf('#') !== -1 ? `${u}-dataattributes` : `${u}#dataattributes`,
        result: da.key,
        resultType: 'Data attribute',
        resultDescriptor: `Resource - ${d}`,
        searchTerm: da.key.toLowerCase(),
        sortOrder: 4,
        type: 'configuration'
      })
      return false;
    })
  }

  // Index scss variables
  if(a.scssVariables) {
    a.scssVariables.map((s)=>{
      siteIndex.push({
        link: u.indexOf('#') !== -1 ? `${u}-scssvariables` : `${u}#scssvariables`,
        result: s.key,
        resultType: 'SCSS Variable',
        resultDescriptor: `Resource - ${d}`,
        searchTerm: s.key.toLowerCase(),
        sortOrder: 5,
        type: 'configuration'
      })
      return false;
    })
  }

  if(a.scssMixins) {
    a.scssMixins.map((s)=>{
      siteIndex.push({
        link: u.indexOf('#') !== -1 ? `${u}-scssmixins` : `${u}#scssmixins`,
        result: s.title,
        resultType: 'SCSS Mixin',
        resultDescriptor: `Resource - ${d}`,
        searchTerm: s.title.toLowerCase().replace(' ', ''),
        sortOrder: 6,
        type: 'configuration'
      })
      return false;
    })
  }

  // Index scss maps
  if(a.scssMaps) {
    a.scssMaps.map((s)=>{
      siteIndex.push({
        link: u.indexOf('#') !== -1 ? `${u}-scssmaps` : `${u}#scssmaps`,
        result: s.title,
        resultType: 'SCSS Map',
        resultDescriptor: `Resource - ${d}`,
        searchTerm: s.title.toLowerCase().replace(' ', ''),
        sortOrder: 7,
        type: 'configuration'
      })
      return false;
    })
  }
}

export default ParseDataBundle;