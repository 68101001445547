const valignments = ['center-vertically', 'end-vertically'];
const halignments = ['center', 'end', 'space-between', 'space-around', 'space-even'];

const sizes = [
  {
    name: 'full',
    numberFullRow: 1
  },
  {
    name: 'half',
    numberFullRow: 2
  },
  {
    name: 'third',
    numberFullRow: 3,
    breakdown: [['x2', false]]
  },
  {
    name: 'quarter',
    numberFullRow: 4,
    breakdown: [['x3',false]]
  },  
  {
    name: 'fifth',
    numberFullRow: 5,
    breakdown: [['x2','x3'], ['x3','x2'], ['x4',false]]
  },  
];

const griditems = [...sizes.map((s)=>s.name), ...sizes.filter((s)=>s.breakdown).map((s)=>s.breakdown.map((b)=>`${s.name}-${b[0]}`)).flat()];

const GridData = {
  selector: "rwx-split",
  componentType: "coreSCSS",
  manualControl: false,
  roseworxCore: false,
  renew: false,
  config: {
    classes: [
      {
        key: "{vertical alignment}",
        description: `Align the grid items vertically.`,
        options: valignments
      },
      {
        key: "{horizontal alignment}",
        description: `Align the grid items horizontallly.`, 
        options: halignments    
      },
      {
        key: "explicit",
        description: "Use this if you plan to use sized grid items (.rwx-split-{size}) inside the grid.",
      },
      {
        key: "non-fluid",
        description: "By default the grid system is fluid which means it's width is 100% of the viewport. Specify non-fluid and the grids' maximum width will be restricted to the size of the current matched breakpoint for the viewport.."
      },
      {
        key: "align",
        description: "Apply this only to explicit nested grids when you want the contents to align with the parent grid."
      },
      {
        key: "no-wrap",
        description: "Stops the grid items wrapping below the ('md') breakpoint."
      },
      {
        key: "rwx-split-{size}",
        description: `Restrict the width of grid item to a fractional size.`,
        applyToSelector: ".rwx-split >",
        options: griditems
      }
    ]
  },
  scssVariables: [
    {
      key: "$grid-item-gutter",
      description: `The padding between sized grid items.`,
      value: "30px",
      options: ["Any valid CSS length"]
    },
    {
      key: "$grid-item-gutter-vertical",
      description: `The vertical padding between sized grid items below the 'md' breakpoint when they are stacked.`,
      value: "30px",
      options: ["Any valid CSS length"]
    },
    {
      key: '$breakpoint-mini',
      value: '480px',
      description: "Mini breakpoint.",
      options: ["Any valid CSS length"]
    },
    {
      key: '$breakpoint-sm',
      value: '576px',
      description: "Small breakpoint.",
      options: ["Any valid CSS length"]
    },
    {
      key: '$breakpoint-md',
      value: '768px',
      description: "Medium breakpoint.",
      options: ["Any valid CSS length"]
    },
    {
      key: '$breakpoint-lg',
      value: '992px',
      description: "Large breakpoint.",
      options: ["Any valid CSS length"]
    },
    {
      key: '$breakpoint-xl',
      value: '1200px',
      description: "Extra large breakpoint.",
      options: ["Any valid CSS length"]
    }
  ],
  notes: [
    {
      note: "By default, when the viewport goes below the 'md' breakpoint, everything will turn full width and stack.",
      icon: "note"
    },
    {
      note: "Try shrinking and growing your browser to see how the grid system behaves in different viewports.",
      icon: "hint"
    }
  ]
}

export default GridData;
export {sizes, halignments, valignments};