import React from 'react';

function Checkbox({data, classes, readonly, disabled, invalidMessage}) {
	return (
		<div className={`rwx-form-item ${classes===undefined ? 'rwx-checkbox --inline valid' : `${!classes.includes('rwx-toggle') ? 'rwx-checkbox' : ''} ${classes}`}`} readOnly={readonly} disabled={disabled}>
			<input type="checkbox" name={data.key} id={data.key} key={data.value} defaultChecked={data.value!=="false"} readOnly={readonly} disabled={disabled}/>
			{data.display && <label htmlFor={data.key} title={data.description}>{data.display}</label>}
			{
				invalidMessage && <p className="invalid-message">{invalidMessage}</p>
			}
		</div>
	)
}

export default Checkbox;