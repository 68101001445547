import React from 'react';

import {DemoWrapper, ImportStep, MarkupStep, ConfigureStep, ExamplesStep, DependancyStep} from '../common';

import PageBreak from '../structural/PageBreak';

import Instructions from '../sections/Instructions';
import Configuration from '../sections/Configuration';
import Methods from '../sections/Methods';
import Notes from '../sections/Notes';

import {InteractiveResponsiveNote} from '../data';

/*
  Layout:
    InteractiveDemo -> Responsive Note -> Configuration -> Methods -> Notes

  Props:
    data: REQUIRED - (FOLLOW STANDARDDATASCHEMA IN README)
    rwxComponent: OPTIONAL - reference to the actual Roseworx resource 
    LIVEHTML: OPTIONAL - React functional component ({id, config}), will get re-rendered with id and config selected from interactiveConfiguration
    small: OPTIONAL - use a small demo box (40vh) instead of big (80vh)
    useExamples: OPTIONAL - uses example step to display anything rather than markupstep for specific html schema
    noParallax: OPTIONAL - no rwxpx parallax effect
    hashPrepend: Prepends a particular string to the ID of sections and components - useful if you will have component/sections with a duplicate id

  Used data keys:
    config
    scssVariables
    scssMaps
    scssMixins
    roseworxPath
    componentType
    dependancies
    snippet
    selector
    methods
    renew
    coreAccessName
    manualControl
    notes
*/

function StandardLayout({data, rwxComponent, LIVEHTML, small, useExamples, noParallax, hashPrepend}) {
  const showConfiguration = () =>{
    let show = false;
    if(data.config)
    {
      if(data.config.dataAttributes)show=true;
      if(data.config.classes)show=true;
    }
    if(data.scssVariables)show=true;
    if(data.scssMaps)show=true;
    if(data.scssMixins)show=true;
    return show;
  }

  const steps = useExamples ?
    [
      ImportStep(rwxComponent ? rwxComponent.resourceName : null, data.roseworxPath, data.componentType, data.dependancies),
      ExamplesStep(data.snippet)
    ]
  :
    [
      ImportStep(rwxComponent ? rwxComponent.resourceName : null, data.roseworxPath, data.componentType, data.dependancies),
      MarkupStep(data.snippet), 
      ConfigureStep()
    ];

  if(data.dependancy)
  {
    steps.splice(1, 0, DependancyStep(data.dependancy.type, data.dependancy.dependancy, data.dependancy.link, data.dependancy.roseworxPath, data.dependancy.inCore));
  }

  const hasResponsiveClasses = data.config && data.config.classes && data.config.classes.filter((c)=>c.key.includes('-rsp')).length>0;

  return (
    <>
      { 
        LIVEHTML && 
        <DemoWrapper 
          id={data.selector} 
          component={rwxComponent} 
          conf={data.config} 
          methods={data.methods} 
          LIVEHTML={LIVEHTML} 
          renew={data.renew}
          small={small}
          noParallax={noParallax}
        />
      }
      {
        hasResponsiveClasses && 
        <div className="rwx-split non-fluid explicit">
          <div className="rwx-split-full">
            <p className={InteractiveResponsiveNote.icon}>{InteractiveResponsiveNote.note}</p>
          </div>
        </div>
      }
      <Instructions steps={steps} hashPrepend={hashPrepend}/>
      {
        showConfiguration() && 
        <>
          <PageBreak/>
          <Configuration 
            rootElement={data.selector} 
            dataAttributes={data.config?.dataAttributes || []} 
            classes={data.config?.classes || []} 
            scssVariables={data.scssVariables || []} 
            uid={data.selector} 
            scssMixins={data.scssMixins || []}
            scssMaps={data.scssMaps || []}
            hashPrepend={hashPrepend}
          />
        </>
      }
      {
        rwxComponent &&
        <>
          <PageBreak/>
          <Methods 
            component={rwxComponent.resourceName} 
            methods={data.methods} 
            coreAccess={data.coreAccessName}
            manualControl={data.manualControl}
            roseworxCore={data.roseworxCore}
            renew={data.renew}
            hashPrepend={hashPrepend}
          />
        </>
      }
      {
        data.notes &&
        <>
          <PageBreak />
          <Notes notes={data.notes} hashPrepend={hashPrepend}/>
        </>
      }
    </>
  )
}

export default StandardLayout;