import React, {useState, useContext, useRef} from 'react';
import {useHistory} from 'react-router';
import {useSpring, animated} from 'react-spring';

import {DataContext} from '../index';

import {SearchPlaceholderText, SearchNoResultsText} from '../data';

function Search({isOpen, close}) {
  const SiteData = useContext(DataContext);

  const searchRef = useRef();

  const [processing, setProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState(false);

  const keyUp = (e) => {
    if(e.keyCode === 13 && searchTerm.trim().length > 0)
    {
      setResults(false);
      setProcessing(true);
      let results = SiteData.siteIndex.filter((si)=>si.searchTerm.includes(searchTerm.trim().toLowerCase().replace(' ', '')));
      window.requestAnimationFrame(()=>{
        setProcessing(false);
        setResults(results);
      });
    }
  }

  const searchClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
    if(!open && searchRef.current)
    { 
      searchRef.current.focus();
    }
  }

  const slideIn = useSpring(
    {
      transform: open ? 'translateX(0%)' : 'translateX(100%)'
    }
  );

  const closeAll = ()=>{
    close();
    setOpen(false);
  }

  return SiteData.siteIndex ?
    <>
      <button tabIndex={isOpen ? 0 : -1} onClick={searchClick} className={`no-decoration navigation-icon navigation-icon-search rwxs-m-t-xs rwxi-${open ? 'rchev' : 'search'} --white --lg`} title="Search" aria-label="Search"></button>
      <animated.div className={`search ${processing ? 'rwx-loading' : ''} rwxc-background-grey`} style={slideIn}>
        <div className="search-box rwxs-p-md rwx-split center">
          <input ref={searchRef} tabIndex={(open && isOpen) ? 0 : -1} type="search" onKeyUp={keyUp} className="rwxs-p-sm rwxc-border-b-silver-lg h4 rwxc-color-silver rwxc-background-grey" onChange={(e)=>setSearchTerm(e.target.value)} value={searchTerm} placeholder={SearchPlaceholderText} />
        </div>
        <div style={{visibility: (isOpen && open) ? 'visible' : 'hidden'}} aria-hidden={(!isOpen && !open)} className="search-results rwxs-p-l-xl rwxs-p-r-xl rwxs-p-t-md rwxs-p-b-md rwxs-p-l-md-rsp rwxs-p-r-md-rsp">
          <div className="rwx-split explicit rwxs-p-no">
            {
              results.length > 0 && results.map((r, i)=>
                <div className="rwx-split-half" key={`search-result-${i}`}>
                  <SearchResult index={i} {...r} close={closeAll}/>
                </div>
              )
            }
            {
              results.length === 0 &&  <div className="rwx-split-full">
                <SearchResult index={0} link={null} result="No Results" resultDescriptor={SearchNoResultsText} center={true} />
              </div>
            }
          </div>
        </div>
      </animated.div>
    </>
  :
    null
}

function SearchResult({index, link, resultType, result, resultDescriptor, close, type, center=false}) {
  const history = useHistory();
  const srin = useSpring(
    {
      from: {
        transform: 'translateY(-50%)',
        opacity: 0
      },
      to: {
        transform: 'translateY(0)',
        opacity: 1
      },
      delay: index * 200,
      config:{mass: 3, tension: 120, friction: 8},
    }
  )

  const clicked = () => {
    if(!link) return;
    close();
    history.push(link);
  }

  return (
    <animated.button style={srin} onClick={clicked} className={`search-result rwxs-p-md no-decoration rwxs-m-b-xl ${center ? 'rwxt-text-center' : ''} ${type}`}>
      {resultType && <p><strong className="impact">{resultType}</strong></p>}
      <h5 className="rwxt-light">{result}</h5>
      <p className="rwxs-m-b-no">{resultDescriptor}</p>
    </animated.button>
  )
}

export default Search;