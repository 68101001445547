import React from 'react';

import Method from '../structural/Method';
import StaticCanvas from '../structural/StaticCanvas';
import PageBreak from '../structural/PageBreak';
import CodeSnippet from '../structural/CodeSnippet';
import Step from '../structural/Step';

import Back from '../components/Back';

import {HelperImportStepHeading} from '../data';

/*
  Layout:
    Back -> Instructions -> alternative half / full Method

  Props:
    data: REQUIRED - (FOLLOW STANDARDDATASCHEMA IN README)
    hashPrepend: Prepends a particular string to the ID of sections and components - useful if you will have component/sections with a duplicate id

  Used data keys:
    methods
    snippet
*/

function HelperLayout({data, hashPrepend}) {

  const HelperMethods = ({m, left}) => {
    return m.demo ? 
      <>
        {
          left ?
            <>
              <div className="rwx-split-half">
                <Method {...m} hashPrepend={hashPrepend}/>
              </div>
              <div className="rwx-split-half">
                <StaticCanvas fn={m.demo} />
              </div>
            </>
          :
            <>
              <div className="rwx-split-half">
                <StaticCanvas fn={m.demo} />
              </div>
              <div className="rwx-split-half">
                <Method {...m} />
              </div>
            </>
        }
      </>
    :
      <div className="rwx-split-full">
        <Method {...m} />
      </div>
  }

  return (
    <>
      <Back />
      <PageBreak />
      {data.snippet && <Step index={0} heading={HelperImportStepHeading} content={<CodeSnippet language="javascript" data={data.snippet} />} noarrow={true}/>}
      <PageBreak />
      {
        data.methods && data.methods.map((m, i)=>
          <div className="rwx-split explicit non-fluid rwxs-m-b-xl" key={`Helpers-Method-${i}`}>
            <HelperMethods m={m} left={i%2===0}/>
          </div>
        )
      }
    </>
  )
}

export default HelperLayout;