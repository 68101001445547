import React from 'react';
import CodeSnippet from './CodeSnippet';
import OptionList from '../components/OptionList';

function Method({name, returnType, description, parameters, example, acceptsObject, hashPrepend}) {
	return (
		<section className="rwxc-border-t-light-blue-md rwxs-p-t-sm" id={`${hashPrepend ? `${hashPrepend}-` : ''}${name.replace('.', '')}`} >
			<h6 className="rwxs-m-b-sm">{name}</h6>
			<p className="small rwxs-m-b-no"><strong>Returns: </strong><i>{returnType}</i></p>
			{parameters && <p className="small rwxs-m-b-no">(</p>}
			{parameters && acceptsObject && <p className="small rwxs-m-b-no">{`{`}</p>}
			{
				parameters && parameters.map((p,i)=><React.Fragment key={`method-${name}-parameter-${i}`}>
						<p className={`small rwxs-m-b-no rwxs-p-l-sm ${p.options ? 'dinline' : ''}`}>
							<strong>{p.name}: </strong>
							{!p.options && <i>{p.type}</i>}
						</p>
						{
							p.options && <div className="dinline rwxs-m-l-xs"><OptionList options={p.options} string={`{${p.type}}`} /></div>
						}
					</React.Fragment>
				)
			}
			{parameters && acceptsObject && <p className="small rwxs-m-b-no">{`}`}</p>}
			{parameters && <p className="small">)</p>}
			<p className={!parameters ? "rwxs-m-t-md" : ""}>{description}</p>
			{example && <CodeSnippet language="javascript" data={example} /> }
		</section>
	)
}

export default Method;