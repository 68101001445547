import React, {useEffect} from 'react';

import rwxSkrollHighjack from 'roseworx/js/modules/rwxSkrollHighjack';

import CodeSnippet from '../../structural/CodeSnippet';
import Step from '../../structural/Step';
import Method from '../../structural/Method';
import Note from '../../structural/Note';
import Description from '../../structural/Description';

import {ImportStep} from '../../common';

function SkrollHighjackPage({data}) {
	useEffect(()=>{
		const skhjk = new rwxSkrollHighjack(true, [...document.querySelectorAll('.code-snippet pre')], (scrollTop)=>console.log("scrollTop", scrollTop));
		return ()=>skhjk.unhook(false)
	}, []);

	const SKHJKWrapper = ({children, id, title}) => {
		return (
			<div style={{height: "100vh"}} className="rwxu-flex-center" id={id} data-rwx-skroll-highjack-section="true" data-rwx-skroll-highjack-section-title={title}>
				{children}
			</div>
		)
	}

	return (
		<div>
			<SKHJKWrapper id="description" title="description">
				<Description paragraphs={data.description} extra={<p className="rwxs-m-b-no"><strong className="impact">Scroll down</strong></p>}/>
			</SKHJKWrapper>
			<SKHJKWrapper id="import" title="Import">
				<div style={{width: "100%"}}>
					<Step index={0} noarrow={true} {...ImportStep(data.resourceName, data.roseworxPath, data.componentType)} />
				</div>
			</SKHJKWrapper>
			{
				data.steps.map((skhjk, i)=>
					<SKHJKWrapper key={`skhjk-step-${i}`} id={`Step${i}`} title={skhjk.heading}>
						<div style={{width: "100%"}}>
							<Step noarrow={true} index={i+1} {...skhjk} content={<CodeSnippet language={skhjk.language || 'javascript'} data={skhjk.snippet} />}/>
						</div>
					</SKHJKWrapper>
				)
			}
			{
				data.methods.map((m, i)=>
					<SKHJKWrapper key={`skhjk-method-${i}`} id={`Method${i}`} title={`${m.name} Method`}>
						<div className="rwx-split explicit non-fluid">
							<div className="rwx-split-full">
								<Method {...m} />
							</div>
						</div>
					</SKHJKWrapper>
				)
			}
			{
				data.notes.map((n, i)=>
					<SKHJKWrapper key={`skhjk-note-${i}`} id={`Note${i}`} title={`Note ${i+1}`}>
						<div className="rwx-split explicit non-fluid">
							<div className="rwx-split-full">
								<Note {...n} />
							</div>
						</div>
					</SKHJKWrapper>
				)
			}
		</div>
	)
}

export default SkrollHighjackPage;