import React from 'react';

import rwxTimelines from 'roseworx/js/components/rwxTimelines';

import StandardLayout from '../../layouts/StandardLayout';

function TimelinesPage({data}) {
	const HTML = ({id, config}) =>{
		return (
			<div id={id} className="rwx-timeline">
				<div className="rwx-timeline-tracker">
					<strong className="impact rwxc-color-blue rwxc-color-blue">Lorem Ipsum</strong>
					<p>
						<strong className="rwxc-color-silver">dolor sit amet, mei appareat qualisque repudiandae ne, esse putent accommodare vix no.</strong>
					</p>
					<ul className="--dotted">
						<li>Lorem</li>
						<li>Ipsum</li>
						<li>dolor sit amet</li>
					</ul>
				</div>
				<div className="rwx-timeline-tracker">
					<strong className="impact rwxc-color-blue">Lorem Ipsum</strong>
					<p>
						<strong className="rwxc-color-silver">dolor sit amet, mei appareat qualisque repudiandae ne, esse putent accommodare vix no.</strong>
					</p>
				</div>
				<div className="rwx-timeline-tracker">
					<strong className="impact rwxc-color-blue">Lorem Ipsum</strong>
					<p>
						<strong className="rwxc-color-silver">dolor sit amet, mei appareat qualisque repudiandae ne, esse putent accommodare vix no.</strong>
					</p>
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxTimelines} 
			LIVEHTML={HTML}
		/>
	)
}

export default TimelinesPage;