import React from 'react';

import StandardLayout from '../../layouts/StandardLayout';

import {parseClasses} from '../../helpers';

function UtilitiesPage({data}) {

	const HTML = ({id, config}) => {
		const parsed = parseClasses(config.classes);

		return (
			<div className="rwxu-flex-center">
				<div style={{width:"300px", height:"300px", position:"relative"}} className="rwxc-border-red">
					<div className={`${parsed} rwxc-border-silver-lg`} style={{height: parsed.includes('rwxu-flex-center') ? "100%" : ''}}>
						<strong className="impact">Roseworx</strong>
					</div>
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			LIVEHTML={HTML}
			useExamples={true}
		/> 
	)
}

export default UtilitiesPage;