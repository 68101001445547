import React from 'react';

import StandardLayout from '../../layouts/StandardLayout';

import {LoremIpsumLong} from '../../data';

import {parseClasses} from '../../helpers';

function TabsPage({data}) {

	const demoData = ['purple', 'orange', 'yellow', 'silver'];

	const HTML = ({id, config}) =>{
		const parsed = parseClasses(config.classes);
		return (
			<div className={`rwx-tabs rwxs-m-no ${parsed}`} id={id} role="tablist">
				{
					demoData.map((c, i)=>
						<div className="rwx-tabs-tab" key={`demo-tab-data-${i+1}`} data-rwx-tabs-title={`Tab ${i+1}`} role="tabpanel">
							<div className="rwx-split explicit">
								<div className={`rwx-split-half rwxc-background-${c}`}></div>
								<div className="rwx-split-half">
									<h2>Tab {i+1} Contents</h2>
									<p>{LoremIpsumLong}</p>
								</div>
							</div>
						</div>				
					)
				}
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={window.rwx.tabs}
			LIVEHTML={HTML}
		/> 
	)
}

export default TabsPage;