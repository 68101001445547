import React from 'react';

import rwxBitExplosions from 'roseworx/js/bitfx/rwxBitExplosions';

import {BITFXHTML} from '../../common';

import StandardLayout from '../../layouts/StandardLayout';

function BitExplosionsPage({data}) {
	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxBitExplosions} 
			LIVEHTML={BITFXHTML}
		/>
	)
}

export default BitExplosionsPage;