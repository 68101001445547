import React from 'react';

function Description({paragraphs, extra}) {

  return (
    <div className="rwx-split explicit non-fluid center">
      <div className="rwx-split-third-x2 rwxt-text-center">
        {
          paragraphs.map((i, ii)=><h4 key={`description-${ii}`} className="rwxt-light">{i}</h4>)
        }
        {extra}
      </div>
    </div>
  )
}

export default Description;