import React,{useEffect} from 'react';
import {icons} from '../../data-servlets/core/Type';

import TabbedLayout from '../../layouts/TabbedLayout';
import StandardLayout from '../../layouts/StandardLayout';

import {LoremIpsum} from '../../data';

import {parseClasses, parseDataAttributes} from '../../helpers';

function TypePage({data}) {
	const tabcontent = [
		{
			title: "Typography",
			content: <TypeSection data={data.type} hashPrepend="type" />,
			id: "type"
		},
		{
			title: "Links",
			content: <LinksSection data={data.links} hashPrepend="links" />,
			id: "links"
		},
		{
			title: "Lists",
			content: <ListsSection data={data.lists} hashPrepend="lists" />,
			id: "lists"
		},
		{
			title: "Cards",
			content: <CardsSection data={data.cards} hashPrepend="cards" />,
			id: "cards"
		},
		{
			title: "Icons",
			content: <IconsSection data={data.icons} hashPrepend="icons" />,
			id: "icons"
		}
	]

	return (
		<TabbedLayout tabs={tabcontent} />
	)
}

function TypeSection(props) {
	
	const HTML = ({config}) =>{
		const parsed = parseClasses(config.classes);

		return (
			<div>
				<div><h1 className={parsed}>Heading 1</h1></div>
				<div><h2 className={parsed}>Heading 2</h2></div>
				<div><h3 className={parsed}>Heading 3</h3></div>
				<div><h4 className={parsed}>Heading 4</h4></div>
				<div><h5 className={parsed}>Heading 5</h5></div>
				<div><h6 className={parsed}>Heading 6</h6></div>
				<div><p className={parsed}>Paragraph. {LoremIpsum} </p></div>
				<div><p className={parsed}><strong>Bold Paragraph. {LoremIpsum}</strong></p></div>
				<div><p className={parsed}>Paragraph with <sup>sup</sup>.</p></div>
			</div>
		)
	}

	return (
		<StandardLayout 
			rwxComponent={null} 
			LIVEHTML={HTML}
			useExamples={true}
			{...props}
		/>
	)
}

function LinksSection(props) {
	
	const HTML = ({config}) =>{
		const parsed = parseClasses(config.classes);
		return (
			<div className="rwxu-flex-center">
				<a className={parsed} href="https://roseworx.adyrose.co.uk" target="_blank" rel="noopener noreferrer">Link Link Link</a>
			</div>
		)
	}

	return (
		<StandardLayout 
			rwxComponent={null} 
			LIVEHTML={HTML}
			useExamples={true}
			{...props}
		/>
	)
}

function ListsSection(props) {

	const HTML = ({config}) =>{
		const parsed = parseClasses(config.classes);
		return (
			<div className="rwxu-flex-center">
				<ul className={parsed}>
					<li>List Item</li>
					<li>List Item</li>
					<li>List Item</li>
					<li>List Item</li>
					<li>List Item</li>
				</ul>
			</div>
		)
	}

	return (
		<StandardLayout 
			rwxComponent={null} 
			LIVEHTML={HTML}
			useExamples={true}
			{...props}
		/>
	)
}

function CardsSection(props) {
	
	const HTML = ({config}) =>{
		const parsed = parseClasses(config.classes);
		useEffect(()=>{
			window.rwx.globals.undismissableCards();
			window.requestAnimationFrame(()=>{
				window.rwx.globals.dismissableCards();
			})
		}, [config])
		return (
			<div className="rwxu-flex-center rwxs-m-l-xl rwxs-m-r-xl">
				<p className={parsed ? parsed : 'feature'} {...parseDataAttributes(config.dataAttributes)}>{LoremIpsum}</p>
			</div>
		)
	}

	return (
		<StandardLayout
			rwxComponent={null} 
			LIVEHTML={HTML}
			useExamples={true}
			{...props}
		/>
	)	
}

function IconsSection(props) {
	
	const HTML = ({config}) =>{
		const parsed = parseClasses(config.classes);
		return (
			<div className="rwx-split explicit">
				{
					icons.map((icon)=>
						<div className="rwx-split-fifth" key={icon}>
							<div className="rwxs-m-b-lg">
								<p className="rwxt-text-center"><i className={`rwxi-${icon} ${parsed}`}></i></p>
								<p className="rwxt-text-center">.rwxi-{icon}</p>
							</div>
						</div>
					)
				}
			</div>
		)
	}

	return (
		<StandardLayout
			rwxComponent={null} 
			LIVEHTML={HTML}
			useExamples={true}
			{...props}
		/>
	)	
}

export default TypePage;