const MistsSnippet = `
<div rwx-mist></div>
`;

const MistsChangeMistMixinSnippet = `
div.change-mist
{
  @include change-mist($black, 10px);
}
`;

const MistsData = {
  selector: 'rwx-mist',
  roseworxPath: 'effects',
  componentType: 'coreJSAndSCSS',
  snippet: MistsSnippet,
  manualControl: false,
  roseworxCore: true,
  renew: false,
  description: [
    "Roseworx Mist is a semi-transparent layer on top of content which visually indicates to the user that the element can be scrolled down or right.",
    "It will automatically detect whether an element has a scroll width/height larger than the element's width/height and apply a mist in the appropriate direction.",
    "Mists will only be shown if scroll is possible in either direction, it will transition out / in appropriately.",
    "Add a [rwx-mist] attribute to any element."
  ],
  scssMixins: [
    {
      snippet: MistsChangeMistMixinSnippet,
      title: "Change Mist",
      description: "Change the color and/or size of indiviudal mists. This example will change the color and size of the mist on a <div> with class .change-mist using $black as the color and 10px as the size. The size parameter is optional and can be omitted."
    }
  ],
  scssVariables: [
    {
      key: "$rwx-mist-size",
      description: "The size of the mist.",
      value: "56px",
      options: ["Any valid CSS length"]
    },
    {
      key: "$rwx-mist-color",
      description: "The color of the mist.",
      value: "$grey",
      options: ["Any valid CSS color"]
    },
  ]
}

export default MistsData;