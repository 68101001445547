import React from 'react';

import Instructions from '../sections/Instructions';
import Methods from '../sections/Methods';
import CodeSnippet from '../structural/CodeSnippet';
import Description from '../structural/Description';

import PageBreak from '../structural/PageBreak';

import {DemoWrapper, ImportStep} from '../common';

/*
  Layout
    Description -> Interactive Demo -> Instructions -> Methods

  Props:
    data: REQUIRED - (FOLLOW STANDARDDATASCHEMA IN README),
    component: REQUIRED - reference to the actual Roseworx resource - ONLY uses resourceName of this so can be simple object with resourceName as key - {resourceName: ''}
    HTML: OPTIONAL - React functional component ({id, config}), will get re-rendered with id and config selected from interactiveConfiguration
    namedExport: OPTIONAL - adds {} around the import
    hashPrepend: Prepends a particular string to the ID of sections and components - useful if you will have component/sections with a duplicate id
  
  Used data keys:
    description
    config
    roseworxPath
    componentType
    steps
    methods
    manualControl
    roseworxCore
    renew
*/

function SteppedLayout({data, HTML, component, namedExport=false, hashPrepend}) {

  data.steps.map((d)=>d.content = <CodeSnippet language="javascript" data={d.snippet} />)

  return (
    <>
      {
        data.description && <Description paragraphs={data.description} />
      }
      {
        HTML && <DemoWrapper LIVEHTML={HTML} id={component.resourceName} conf={data.config} />
      }
      <Instructions steps={[ImportStep(component.resourceName, data.roseworxPath, data.componentType, namedExport), ...data.steps]} />
      <PageBreak />
      {
        data.methods && 
        <Methods 
          methods={data.methods} 
          dontJoinToComponent={true} 
          manualControl={data.manualControl}
          roseworxCore={data.roseworxCore}
          renew={data.renew}
          component={false}
          hashPrepend={hashPrepend}
        />
      }
    </>
  )
}

export default SteppedLayout;