import React, {useEffect, useContext} from 'react';

import {DataContext} from '../index';

import useRoseworx from 'roseworx/js/useRoseworx';
import rwxBitSwarms from 'roseworx/js/bitfx/rwxBitSwarms';

import Brackets from '../components/Brackets';

import CodeSnippet from '../structural/CodeSnippet';

function CoreResources({data}) {
	const SiteData = useContext(DataContext);

	return SiteData.coreResources ?
		<ul className="--dotted">
			{
				SiteData.coreResources.map((p,i)=><li key={`core-resource-${i}`}>{p}</li>)
			}
		</ul>
	:
		null
}


function HomePage({data}) {
	useRoseworx(window.rwx.lavaLamps, 'lava-lamp-home');
	return (
		<>
			<div style={{width:"100vw", height:"100vh", position:"relative"}}>
			  <div className="rwxu-full-size">
			  	<Swarm />
			  	<Punctuation />
			  </div>
		  </div>
		  <div>
		  <div className="rwx-split explicit non-fluid">
	  		{
	  			data.sections.map((s, i)=><Section key={`home-section-${i}`} s={s} i={i} /> )
	  		}
	  	</div>
	  	<div className="lava-lamp" id="lava-lamp-home"></div>
	  	</div>
	  </>
	)
}

function Section({s, i}) {
	return (
		<section className="rwx-split-half">
			<div className="rwxs-p-md rwxc-border-silver">
				<p className="rwxs-m-b-xl rwxt-text-center"><strong className="impact rwxt-large">{s.title}</strong></p>
				{
					s.data.map((sd, ii)=>(
						<div key={`home-section-${i}-content-${ii}`} className="rwxs-m-b-md">
							{sd.text && <p>{sd.text}</p>}
							{sd.snippet && 
						  	<div className="rwxs-m-b-lg">
						  		<CodeSnippet language={sd.language || 'javascript'} data={sd.snippet}/>
						  	</div>
							}
							{
								sd.coreResources && <CoreResources />
							}
						</div>
					))
				}
			</div>
		</section>
	)
}

function Swarm() {
	useRoseworx(rwxBitSwarms, 'roseworx-intro');
	return (<div id="roseworx-intro" className="rwx-bit-swarm" data-rwx-bit-swarm-value="roseworx" data-rwx-bit-swarm-color="#e65c00" data-rwx-bit-swarm-orientation="slanted" data-rwx-bit-swarm-shape="square" data-rwx-bit-swarm-background-color="transparent"></div>)
}

function Punctuation() {
	useEffect(()=>{
		const b = new Brackets();
		return ()=>b.stop()
	}, [])
	return (<div id="brackets"></div>)
}

export default HomePage;