import React from 'react';

import HelperLayout from '../../layouts/HelperLayout';

function MiscHelpersPage({data}) {

  return (
    <HelperLayout data={data} />
  )
}

export default MiscHelpersPage;