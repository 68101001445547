import {FullSizeParentNote, AccesibilityNote, AddFixedForFullScreen} from '../../data';

const LuckyDipsSnippet = `
<div class="rwx-lucky-dip" rwx-lucky-dip id="{{ UNIQUE ID }}">
  <div class="rwx-lucky-dip-item" data-rwx-lucky-dip-value="{{ OPTION VALUE }}"><span>{{ DISPLAY NAME }}</span></div>
  <div class="rwx-lucky-dip-item" data-rwx-lucky-dip-value="{{ OPTION VALUE }}"><span>{{ DISPLAY NAME }}</span></div>
</div>
`;

const LuckyDipsData = {
  selector: "rwx-lucky-dip",
  componentType: "individualJSAndSCSS",
  roseworxPath: "components",
  manualControl: true,
  roseworxCore: true,
  renew: true,
  snippet: LuckyDipsSnippet,
  dependancy: {
    type: "scss",
    dependancy: "rwxAnimatedBorders",
    link: "/effects/animatedborders",
    roseworxPath: "effects"
  },
  config: {
    dataAttributes: [
      {
        key: "data-rwx-lucky-dip-value",
        applyToSelector: "[rwx-lucky-dip] .rwx-lucky-dip-item",
        value: "",
        type: "hidden",
        options: ["Any string"],
        description: "The value to report to the onSelected callback when the particular option is landed on."
      },
      {
        key: "data-rwx-lucky-dip-stop-text",
        value: "Stop",
        type: "text",
        options: ["Any string"],
        description: "The text for the stopper button.",
        display: "Stop text"
      },
      {
        key: "data-rwx-lucky-dip-auto-stop",
        value: "false",
        type: "checkbox",
        options: ["false", "true"],
        description: "Whether or not to automatically stop the lucky dip after data-rwx-lucky-dip-stop-auto-stop-delay, even if the button hasn't been clicked.",
        display: "Auto stop"
      },
      {
        key: "data-rwx-lucky-dip-auto-stop-delay",
        value: "10",
        type: "number",
        options: ["Any valid number"],
        description: "The time in seconds between when the lucky dip opens and when it automatically lands on an option, if data-rwx-lucky-dip-stop-auto-stop is set to true.",
        display: "Auto stop delay"
      }
    ]
  },
  methods: [
    {
      name: 'onSelected',
      returnType: 'String',
      description: 'Sets a callback to call when an option has been landed on with the options value as a parameter to the function.',
      example: ".onSelected('{ID}', (val)=>console.log(val))",
      interactable: false,
      parameters: [
        {
          name: 'ID',
          type: 'String'
        },
        {
          name: 'Callback',
          type: 'Function'
        }
      ]
    }
  ],
  scssVariables: [
    {
      key: "$rwx-lucky-dips-background-color",
      value: "$blue",
      description: "The background color of the lucky dip.",
      options: ["Any valid CSS color"]
    },
    {
      key: "$rwx-lucky-dips-color",
      value: "$orange",
      description: "The color of the box shadow around the items.",
      options: ["Any valid CSS color"]
    },
    {
      key: "$rwx-lucky-dips-item-color",
      value: "black",
      description: "The text color of the lucky dip items.",
      options: ["Any valid CSS color"]
    }
  ],
  notes: [
    {
      note: "This components' effects work best when there is more than 5 options.",
      icon: "warning"
    },
    FullSizeParentNote,
    AccesibilityNote,
    AddFixedForFullScreen('[rwx-lucky-dip]')
  ]
}

export default LuckyDipsData;