import React from 'react';

import {sizes, halignments, valignments} from '../../data-servlets/core/Grid';

import Instructions from '../../sections/Instructions';
import Configuration from '../../sections/Configuration';
import Notes from '../../sections/Notes';

import PageBreak from '../../structural/PageBreak';

import {ImportStep} from '../../common';

function GridPage({data}) {

	const GridParent = ({extra, children, height}) => {
		return (
			<div className={`rwxc-background-silver rwxs-m-b-lg rwx-split ${extra ? extra.join(' ') : ''} rwxs-p-t-xs rwxs-p-b-xs`} style={{minHeight: height ? height : ''}}>
				{children}
			</div>
		)
	}

	const GridItem = ({extra, children}) => {
		return (
			<div className={extra ? extra : ''}>
				<p className="rwxs-m-b-no rwxc-background-orange rwxs-p-sm rwxc-border-white"><i>{extra ? `div.${extra}` : 'Any element'}</i></p>
				{children && children}
			</div>
		)
	}

	const Heading = ({title, intro}) => {
		return (
			<div className="rwx-split non-fluid explicit">
				<div className="rwx-split-full">
					<p className="rwxs-m-b-no"><strong className="impact">{title}</strong></p>
					<p className="rwxs-m-b-no">{intro}</p>
				</div>
			</div>
		)
	}

	const Descriptor = ({text}) => {
		return (
			<p className="rwxc-color-silver rwxt-large rwxs-p-l-sm rwxs-p-t-sm"><i>{text}</i></p>
		)
	}

	const GridBlock = ({extra, height, items=0, itemextra, children, descriptor=true}) => {
		const griditems = [];
		for(let c=0;c<items;c++)
		{
			griditems.push(<GridItem key={`grid-item-${c}-text`} extra={Array.isArray(itemextra) ? itemextra[c] : itemextra}/>)
		}

		return (
			<>
				{descriptor && <Descriptor text={`div.rwx-split${extra ? `.${extra.join('.')}` : ''}${height ? ` [style="height:${height}"]` : ''}`} />}
				<GridParent extra={extra} height={height}>
					{griditems}
					{children && children}
				</GridParent>
			</>
		)
	}
	
	return (
		<>
			<Instructions steps={[ImportStep(null, "core", "coreSCSS")]} />
			<PageBreak />

			<Heading title="Implicit" intro="The grid system is built to quickly and easily turn an element into a grid container and position grid items. Grid items will naturally be the size of the content they hold." />
			<section className="rwxc-background-grey">
				<GridBlock text="div.rwx-split" items={3}/>
				{
					halignments.map((ha,i)=><GridBlock items={3} key={`halignment-${i}`} extra={[ha]} />)
				}
				{
					valignments.map((va,i)=><GridBlock items={3} key={`valignment-${i}`} extra={[va]} height="200px" />)
				}
			</section>
			<PageBreak />

			<Heading title="Explicit" intro="You can specify a fractional proportion of the grid width for each grid item to restrict it's width." />
			<section className="rwxc-background-grey">
				{	
					sizes.map((s, i)=><GridBlock key={`sizes-${i}`} extra={["explicit"]} items={s.numberFullRow} itemextra={`rwx-split-${s.name}`} />)
				}
				{
					sizes.filter((s)=>s.breakdown).map((s, i)=>
						(
							s.breakdown.map((b, ii)=><GridBlock key={`sizes-breakdown-${i}-${ii}`} extra={["explicit"]} items={b.length} itemextra={b.map((bd)=>bd ? `rwx-split-${s.name}-${bd}` : `rwx-split-${s.name}`)} />)
						)
					)
				}
				<GridBlock extra={["explicit"]} items={3} itemextra={['rwx-split-quarter', 'rwx-split-quarter', 'rwx-split-half']} />
			</section>
			<PageBreak />

			<Heading title="Nested & Align" intro="You can have a grid within a grid (nested grid). By default a nested grid will receive padding, use the align class to align it with the parent." />
			<section className="rwxc-background-grey">
				<Descriptor text="div.rwx-split.explicit" />
				<div className="rwx-split explicit rwxc-background-silver rwxs-p-t-xs rwxs-p-b-xs">
					<GridItem extra="rwx-split-fifth-x3">
						<div className="rwx-split explicit rwxc-border-r-light-blue-md rwxc-border-l-light-blue-md">
							<div className="rwx-split-full">
								<div className="rwxc-background-grey rwxs-p-b-xs rwxs-m-t-md rwxs-m-b-sm">
									<Descriptor text="div.rwx-split.explicit"/>
								</div>
							</div>
							<GridItem extra="rwx-split-third"/>
							<GridItem extra="rwx-split-third-x2">
								<div className="rwx-split explicit rwxc-border-r-light-blue-md rwxc-border-l-light-blue-md">
									<div className="rwx-split-full">
										<div className="rwxc-background-grey rwxs-p-b-xs rwxs-m-t-md rwxs-m-b-sm">
											<Descriptor text="div.rwx-split.explicit"/>
										</div>
									</div>
									<GridItem extra="rwx-split-half"/>
									<GridItem extra="rwx-split-half"/>
								</div>
							</GridItem>
						</div>
					</GridItem>
					<GridItem extra="rwx-split-fifth-x2">
						<div className="rwx-split explicit rwxc-border-r-light-blue-md rwxc-border-l-light-blue-md">
							<div className="rwx-split-full">
								<div className="rwxc-background-grey rwxs-p-b-xs rwxs-m-t-md rwxs-m-b-sm">
									<Descriptor text="div.rwx-split.explicit"/>
								</div>
							</div>
							<GridItem extra="rwx-split-third"/>
							<GridItem extra="rwx-split-third"/>
							<GridItem extra="rwx-split-third"/>
						</div>
					</GridItem>
				</div>
			</section>

			<section className="rwxc-background-grey">
				<Descriptor text="div.rwx-split.explicit" />
				<div className="rwx-split explicit rwxc-background-silver rwxs-p-t-xs rwxs-p-b-xs rwxs-m-b-md">
					<GridItem extra="rwx-split-fifth-x3">
						<div className="rwx-split explicit align">
							<div className="rwx-split-full">
								<div className="rwxc-background-grey rwxs-p-b-xs rwxs-m-t-md rwxs-m-b-sm">
									<Descriptor text="div.rwx-split.explicit.align"/>
								</div>
							</div>
							<GridItem extra="rwx-split-third"/>
							<GridItem extra="rwx-split-third-x2">
								<div className="rwx-split explicit align">
									<div className="rwx-split-full">
										<div className="rwxc-background-grey rwxs-p-b-xs rwxs-m-t-md rwxs-m-b-sm">
											<Descriptor text="div.rwx-split.explicit.align"/>
										</div>
									</div>
									<GridItem extra="rwx-split-half"/>
									<GridItem extra="rwx-split-half"/>
								</div>
							</GridItem>
						</div>
					</GridItem>
					<GridItem extra="rwx-split-fifth-x2">
						<div className="rwx-split explicit rwxc-border-r-light-blue-md rwxc-border-l-light-blue-md">
							<div className="rwx-split-full">
								<div className="rwxc-background-grey rwxs-p-b-xs rwxs-m-t-md rwxs-m-b-sm">
									<Descriptor text="div.rwx-split.explicit"/>
								</div>
							</div>
							<GridItem extra="rwx-split-third"/>
							<GridItem extra="rwx-split-third"/>
							<GridItem extra="rwx-split-third"/>
						</div>
					</GridItem>
				</div>
				<Descriptor text="div.rwx-split.explicit.align" />
				<div className="rwx-split explicit align rwxc-background-silver rwxs-p-t-xs rwxs-p-b-xs">
					<GridItem extra="rwx-split-full"/>
				</div>
			</section>
			<PageBreak/>

			<Heading title="Non Fluid" intro="By default the grid will be 100% width of its parent container, use this to confide the maximum width of the grid to the breakpoint of the curent viewport." />
			<section className="rwxc-background-grey">
				<GridBlock extra={["explicit", "non-fluid"]} items={2} itemextra={['rwx-split-half', 'rwx-split-half']} />
			</section>
			<PageBreak />

			<Heading title="No Wrap" intro="Implicit grid items will wrap if the content inside exceeds maxium width. Explicit items will wrap when the viewport goes below 'md' breakpoint. Adding non-fluid will prevent items from wrapping." />
			<section className="rwxc-background-grey">
				<GridBlock extra={["explicit", "no-wrap"]} items={3} itemextra={['rwx-split-fifth', 'rwx-split-fifth-x3', 'rwx-split-fifth']} />
			</section>
			<PageBreak />

			<Heading title="Wrap small" intro="Grid items by default will stack when the browser width goes below the 'md' breakpoint, you can override this so items stacks when the browser width goes below the 'sm' breakpoint." />
			<section className="rwxc-background-grey">
				<GridBlock extra={["explicit", "wrap-sm"]} items={3} itemextra={['rwx-split-fifth', 'rwx-split-fifth-x3', 'rwx-split-fifth']} />
			</section>
			<PageBreak />

			<Heading title="Wrap large" intro="Grid items by default will stack when the browser width goes below the 'md' breakpoint, you can override this so items stacks when the browser width goes below the 'lg' breakpoint." />
			<section className="rwxc-background-grey">
				<GridBlock extra={["explicit", "wrap-lg"]} items={3} itemextra={['rwx-split-fifth', 'rwx-split-fifth-x3', 'rwx-split-fifth']} />
			</section>
			<PageBreak />

			<Heading title="Vertical" intro="You can flip a grid and have it vertical as long as the grid parents' height is specifically set." />
			<section className="rwxc-background-grey">
				<GridBlock height="300px" extra={["vertical", "space-between"]} items={3} />
			</section>
			<PageBreak />

			<Configuration scssVariables={data.scssVariables} classes={data.config.classes} uid={data.selector}/>
			<PageBreak />

			<Notes notes={data.notes}/>
		</>
	)
}
export default GridPage;