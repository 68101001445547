import React from 'react';

function Selector({text}) {

  let arr = [];
  const hasOr = text.split(' || ');
  hasOr.map((ho)=>arr.push(ho.toLowerCase().includes('any element') ? [ho] : ho.split(' ')));

  return arr.map((a, i)=>
    <div key={`selector-${i}`} className={`css-selector rwxc-background-grey rwxs-p-xs rwxc-color-light-blue rwxt-text-center rwxc-border-light-blue ${i>0 ? 'rwxs-m-t-sm' : ''}`}>
      {
        a.map((t,ii)=><React.Fragment key={`css-selector-child-${ii}`}>
          {
            ii>0 && <span className="child">&#x203A;</span>
          }
          <span className={`${ii<a.length-1 ? 'small' : ''}`}>{t}</span>
        </React.Fragment>)
      }
    </div>
  )
}

export default Selector;