import {usableColors, colorsWhiteText, colorvalues} from './data';
import rwxMath from 'roseworx/js/helpers/rwxMathHelpers';
import rwxMisc from 'roseworx/js/helpers/rwxMiscHelpers';

let alreadyGot = [];
let alreadyGot2 = [];

const getRandomColor = () => {
	if(alreadyGot.length===usableColors.length) {
		alreadyGot = [];
	}
	const filtered = usableColors.filter((us)=>!alreadyGot.includes(us))
	const c = filtered[rwxMath.randomInt(0,filtered.length-1)];
	alreadyGot.push(c);
	return c;
}

const getRandomColorForWhiteType = () => {
	if(alreadyGot2.length===colorsWhiteText.length) {
		alreadyGot2 = [];
	}
	const filtered = colorsWhiteText.filter((us)=>!alreadyGot2.includes(us))
	const c = filtered[rwxMath.randomInt(0,filtered.length-1)];
	alreadyGot2.push(c);
	return c;
}

const getHexFromColor = (color) => {
	return colorvalues.find((c)=>c.color===color).value;
}

const parseDataAttributes = da => {
	if(!da)return {};
	let obj = {};
	da.map((c)=>{
		obj[c.key] = c.value;
		return false;
	});
	return obj;
}

const getDataAttributesAsString = (da, add) => {
	let val = parseDataAttributes(da);
	let s = add ? ` ${add}` : '';
	for(let key of Object.keys(val))
	{
		s += ` ${key}${val[key] ? `="${val[key]}"` : ''}`
	}
	return s || '';
}

const bracketsRegexp = new RegExp(/{([^}]+)}/);

const parseClasses = c => {
	if(!c)return "";
	let classes = "";
	c.map((c,i)=>{
		if(c.value==="true")classes+=`${i===0 ? '' : ' '}${c.key}`;
		else if(c.value!=="false" && bracketsRegexp.test(c.key))
		{
			let t = c.key;
			classes+=`${i===0 ? '' : ' '}${t.replace(bracketsRegexp, c.value)}`;
		}
		return false;
	});
	return classes;
}

const getSelectorFromClassList = cl => {
	if(!cl)return false;
	return cl.length>0 ? `.${cl.trim().replace(/ /g, '.')}` : '';
}

const getClassesAsString = (c, add) => {
	let val = parseClasses(c);
	if(!val && !add)return '';
	let arr = [];
	if(val)
	{
		arr.push(...val.split(' '));
	}

	if(add)
	{
		arr.push(...add.split(' '));
	}
	const joined = arr.filter((a)=>a);
	return `class="${joined.join(' ')}"`;
}

const cloneConfig = (c) => {
	let obj = {};
	Object.keys(c).map((ok)=>{
		obj[ok] = rwxMisc.safeCloneArrayOfObjects(c[ok]);
		return false
	});
	return obj;
}

export {
	getRandomColor,
	getRandomColorForWhiteType,
	parseClasses,
	parseDataAttributes,
	getSelectorFromClassList,
	getClassesAsString,
	getDataAttributesAsString,
	cloneConfig,
	bracketsRegexp,
	getHexFromColor
}