const UseRoseworxSnippet = `
import useRoseworx from 'roseworx/js/useRoseworx';
import rwxComponent from 'roseworx/js/{Path To Component}';

function MyRoseworxComponent() {
  useRoseworx(rwxComponent, '{id}');

  return (
    // If using hook theres no need to add the rwx component data attribute to the element.
    // HTML schema required for specific component, using same id passed to useRoseworx hook.
    <div id={id}>

    </div>
  )
}`;

const InstallSnippet = `
npm install roseworx
`;

const JSImportSnippet = `
import rwx from 'roseworx';
`;

const ScssImportSnippet = `
@import "~roseworx/scss/roseworx";
`;

const CompiledSnippet = `
roseworx/compiled/roseworx.app.css
roseworx/compiled/roseworx.app.js
`;

const HomeData = {
  sections: [
    {
      title: "Installation",
      data: [
        {
          snippet: InstallSnippet
        },
        {
          text: 'Roseworx is written in SCSS and ES6 javascript so you will need to run it through a SCSS compiler & babelification process if you want to support older browsers, e.g;'
        },
        {
          text: 'https://www.npmjs.com/package/sass'
        },
        {
          text: 'https://www.npmjs.com/package/@babel/core'
        },
        {
          text: 'Import the core SCSS file;',
          snippet: ScssImportSnippet,
          language: 'css'
        },
        {
          text: 'Import the core Javascript file;',
          snippet: JSImportSnippet
        },
        {
          text: `If you don't want Roseworx as part of your build process or don't want to customise it you can copy or use the compiled core files directly.`,
          snippet: CompiledSnippet,
          language: 'html'
        },
        {
          text: 'No IE here! Although most features of this framework will work in Internet Explorer, it has not specifically been built to support this browser.'
        }
      ]
    },
    {
      title: "What's included",
      data: [
        {
          text: 'Roseworx comes with a core framework import which provides a selection of most commonly used components for convenience, however if you dont want everything, you can import component javascript individually. Resources included in the core SCSS and JS file are;',
          coreResources: true,
        },
        {
          text: 'There is also lots of other components, modules, effects, etc which you can import individually.'
        }
      ]
    },
    {
      title: "Javascipt frameworks",
      data: [
        {
          text: `Roseworx resources can still be used in frameworks like React and Vue simply by using the .hook method when they are mounted and .unhook when they are unmounted.`
        },
        {
          text: `There is a React hook provided in the Roseworx source code to simplify this process;`,
          snippet: UseRoseworxSnippet
        },
        {
          text: `In order to use this hook, React must be in scope when imported.`
        }
      ]
    },
    {
      title: "Event tracking",
      data: [
        {
          text: `By default any resource that utilises animation will only execute once it is scrolled in to the user's view so they don't miss the initial effect. This can be overridden by using the data-rwx-manual-control attribute and then the commence method.`
        },
        {
          text: `Roseworx uses a smart scroll module which is throttled and groups all scroll monitoring into a single thread for faster execution. Once an element has initiated, the event is then removed completely from the scroll tracking and thus, does not use any additional resources.`
        }
      ]
    }
  ]
}

export default HomeData;