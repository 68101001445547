import React from 'react';

import useRoseworx from 'roseworx/js/useRoseworx';

import Selector from '../structural/Selector';

import OptionList from '../components/OptionList';

import {bracketsRegexp} from '../helpers';

function Table({id, values, name, noDefault=false, rootElement}) {
  useRoseworx(window.rwx.tables, id);
  const ApplyStatement = ({applyToSelector}) => {
    if(name === "Attribute")
    {
      return <Selector text={applyToSelector ? applyToSelector : `[${rootElement}]`} />
    }
    else if(name === "Class")
    {
      return <Selector text={applyToSelector ? applyToSelector : `.${rootElement}`} />
    }
    else
    {
      return null;
    }
  }

  //if name = attribute and options replace v.key with = "{}"
  //then test for brackets in v.key and send to <OptionList with options

  const HasOptions = ({string, options, useOptions}) => {
    let touse = (name === "Attribute" && options) ? `${string}="{value}"` : string;

    if(bracketsRegexp.test(touse) && useOptions !== "{}")
    {
      return <OptionList string={touse} options={options} useOptions={useOptions}/>
    }
    else
    {
      return <>{string}</>;
    }
  }

  return (
    <div className="rwx-table --colored" role="table" id={id}>
      <div className="rwx-table-data" role="row">
        <span role="cell" className="--x2">{name}</span>
        {!noDefault && <span role="cell">Default</span>}
        <span role="cell" className="--x3">Description</span>
        {rootElement && <span role="cell">Apply to element</span>}
      </div>
      {
        values.map((v,i)=><div key={`configuration-table-data-${i}`} className="rwx-table-data" role="row">
            <span role="cell" className="--x2"><HasOptions string={v.key} options={v.options} useOptions={v.useForOptions}/></span>
            {!noDefault && <span role="cell">{v.value}</span>}
            <span role="cell" className="--x3">{v.description}</span>
            {
              rootElement &&
              <span role="cell">
                <ApplyStatement applyToSelector={v.applyToSelector}/>
              </span>
            }
          </div>
        )
      }
    </div>
  )
}

export default Table;