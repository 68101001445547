import React from 'react';

import HubLink from '../components/HubLink';

import {getRandomColorForWhiteType} from '../helpers';

/*
  Layout:
    if sections specified;
      Section -> HubLinks
    else
      HubLinks

  Props:
    data: REQUIRED - (FOLLOW HUBDATASCHEMA IN README)

  Used data keys:
    links
    sections
*/

function HubLayout({data}) {

  return data.sections ?
      data.sections.map((tbs, i)=>
      <div key={`Toolbox-section-${i}`}>
        <HubSection data={tbs} sectionIndex={i}/>
      </div>
    )
  :
    <div className="rwx-split center explicit rwxs-p-t-lg rwxs-p-b-lg">
      {data.links && <HubLinks links={data.links} extraIndex={0} /> }
    </div>
}

function HubLinks({links, extraIndex, color}) {
  return links.map((l, i)=>
    <div className="rwx-split-third rwxs-m-b-xl rwxs-m-b-no-rsp" key={`Toolbox-Hub-Link-${extraIndex}-${i}`}>
      <HubLink link={l.link} description={l.description} heading={l.name} color={color || getRandomColorForWhiteType()} />
    </div>
  )
}

function HubSection({data, sectionIndex}) {
  const color = getRandomColorForWhiteType();
  return (
    <div className="rwx-split center explicit rwxs-p-t-lg rwxs-p-b-lg">
      <div className="rwx-split-full rwxs-m-b-md">
        <p className="rwxt-text-center"><strong className="impact">{data.name}</strong></p>
      </div>
      {data.links && <HubLinks links={data.links} extraIndex={sectionIndex} color={color}/> }
    </div>
  )
}

export default HubLayout;