import React from 'react';
import Section from '../structural/Section';

function Extra({children}) {

	return (
		<Section title="Extra">
			<div className="rwx-split explicit non-fluid">
				<div className="rwx-split-full">
					{children}
				</div>
			</div>
		</Section>
	)
}

export default Extra;