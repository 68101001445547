import React from 'react';

function Select({data, classes, readonly, disabled, invalidMessage}) {
	return (
		<div className={`rwx-form-item rwx-select ${classes===undefined ? '--inline valid' : classes}`}>
			<select name={data.key} id={data.key} key={data.value} defaultValue={data.value} readOnly={readonly} disabled={disabled}>
				{data.options.map((o)=>
					<option key={`option${o}`} value={o === "N/A" ? "false" : o}>{o}</option>
				)}
			</select>
			{data.display && <label htmlFor={data.key} title={data.description}>{data.display}</label>}
			{
				invalidMessage && <p className="invalid-message">{invalidMessage}</p>
			}
		</div>
	)
}

export default Select;