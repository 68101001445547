import React, {useRef} from 'react';

import useRoseworx from 'roseworx/js/useRoseworx';
import rwxMisc from 'roseworx/js/helpers/rwxMiscHelpers';

import Description from '../structural/Description';
import PageBreak from '../structural/PageBreak';

/*
  Layout:
    Description -> full width centered tabs

  Props:
    description: OPTIONAL - description
    tabs: REQUIRED - Array [
      {
        title:, // String - title for the tab
        content: // JSX Component - to render when tab is selected
        id: // String - Used for an empty div with id so the tab can be accessed via url hash - MUST BE A VALUE OF THE flatten array
      }
    ]
*/

function TabbedLayout({tabs, description}) {
  const id = useRef(rwxMisc.uniqueId());
  useRoseworx(window.rwx.tabs, id.current);
  return (
    <div>
      {description && <Description paragraphs={description}/> }
      <PageBreak />
      <div className="rwx-tabs --center" id={id.current} role="tablist">
        {
          tabs.map((t, i)=>
            <div className="rwx-tabs-tab" data-rwx-tabs-title={t.title} role="tabpanel" key={`tabs-${id.current}-${i}`}>
              {t.id && <div id={t.id} style={{position: 'fixed', top:'0px', left:'0px'}}></div>}
              {t.content}
            </div>
          )
        }
      </div>
    </div>
  )
}

export default TabbedLayout;