const ThemesData = {
  description: [
    "Roseworx comes with some theme variables which allow you to quickly and easily 'brand' or 'theme' some common aspects of your implementation of the framework.",
    "Setting these variables will save you lots of time by avoiding setting each variable they are used for seperately."
  ],
  sentence1: "Set this SCSS variable to any valid CSS color and it will set the value for all the following SCSS variables;",
  sentence2: "You can still then set any of these variables independently, overriding what was set by the theme.",
  scssVariables: [
    {
      key: "$theme-color-base",
      description: "Theme color base.",
      value: "$dark-grey",
      options: ["Any valid css color"]
    },
    {
      key: "$theme-color-secondary",
      description: "Theme color secondary.",
      value: "$grey",
      options: ["Any valid css color"]
    },
    {
      key: "$theme-color-tertiary",
      description: "Theme color tertiary.",
      value: "$light-blue",
      options: ["Any valid css color"]
    },
  ],
  themeMappings: {
    '$theme-color-base': [
      '$icon-color-base',
      '$card-border-color',
      '$table-color-base',
      '$form-color-base',
      '$button-color-base',
      '$slider-counter-active-color',
      '$tabs-title-color'
    ],
    '$theme-color-secondary': [
      '$card-background-color',
      '$table-color-secondary',
      '$form-color-secondary',
      '$slider-counter-inactive-color',
      '$tabs-seperator-color'
    ],
    '$theme-color-tertiary': [
      '$font-link-color',
      '$list-item-counter-color',
      '$tabs-active-color',
      '$table-color-highlight'
    ]
  }
}

export default ThemesData;