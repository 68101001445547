import {ToManyBitFxNote, MouseMoveNote, FullSizeParentNote, InfiniteAnimationMethods} from '../../data';

import getBitFxConfig,{BitFxSnippet} from './bitfx';

const BitExplosionsConfig = getBitFxConfig('data-rwx-bit-explosion',
  [
    {
      key: 'data-rwx-bit-explosion-secondary-color',
      type: 'text',
      options: ['Any valid HEX color'],
      display: "Secondary Bit Color",
      value: "#FFFFFF",
      description: "Sets the color of the background particles."
    }
  ]
);

const BitExplosionsData = {
  selector: "rwx-bit-explosion",
  config: BitExplosionsConfig,
  componentType: "individualJS",
  roseworxPath: "bitfx",
  manualControl: true,
  roseworxCore: true,
  renew: true,
  notes: [ToManyBitFxNote, MouseMoveNote, FullSizeParentNote],
  methods: InfiniteAnimationMethods
}

BitExplosionsData.snippet = BitFxSnippet(BitExplosionsData.selector, BitExplosionsData.config.dataAttributes);

export default BitExplosionsData;