import React from 'react';

import rwxOptionSelectors from 'roseworx/js/components/rwxOptionSelectors';

import StandardLayout from '../../layouts/StandardLayout';

import {parseDataAttributes} from '../../helpers';

function OptionSelectorsPage({data}) {

	const HTML = ({id, config}) =>{
		setTimeout(()=>{
			rwxOptionSelectors.onSelected(id, (v)=>alert(`You picked - ${v}`));
		}, 1000);

		const items = Array.from(Array(parseInt(config.demo.find((c)=>c.key==="number-of-options").value)).keys());
		const closeable = config.dataAttributes.find((c)=>c.key==="data-rwx-option-selector-closeable");
		return (
			<div className="rwxu-full-size">
				<div className="rwx-option-selector" id={id} {...parseDataAttributes([closeable])}>
					{
						items.map((i)=><div key={`option-selector-demo-item-${i}`} className="rwx-option-selector-item" data-rwx-option-selector-value={`Option ${i+1}`}><span>Option {i+1}</span></div>)
					}
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxOptionSelectors} 
			LIVEHTML={HTML}
		/>
	)
}

export default OptionSelectorsPage;