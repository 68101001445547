import React,{useContext} from 'react';

import {DataContext} from '../../index';

import Back from '../../components/Back';

import Table from '../../structural/Table';
import PageBreak from '../../structural/PageBreak';
import ScssMap from '../../structural/ScssMap';
import ScssMixin from '../../structural/ScssMixin';

import TabbedLayout from '../../layouts/TabbedLayout';

import {SCSSVariableMessage} from '../../data';

function ScssPage({data}) {
  const SiteData = useContext(DataContext);

  const tabcontent = [];

  SiteData.allSCSS && Object.keys(SiteData.allSCSS).map((k)=>{
    const a = SiteData.allSCSS[k];
    if(a.variables.length === 0 && a.mixins.length === 0 && a.maps.length === 0)return false
    tabcontent.push({
      title: k,
      id: `scss-${k}`,
      content: <div className="rwx-split explicit non-fluid">
        <div className="rwx-split-full">
          { a.variables.length > 0 && <>
              <p><strong className="impact">SCSS Variables</strong></p>
              <p className="note">{SCSSVariableMessage}</p>
              <Table name="SCSS Variable" values={a.variables} id={`toolbox-scss-${k}-variables`}/>
            </>
          }
          {
            a.mixins.length > 0 && <div className="rwxs-m-t-xl">
              <p><strong className="impact">SCSS Mixins</strong></p>
              {a.mixins.map((m, i)=><div className="rwxs-m-t-xl" key={`toolbox-scss-mixin-${k}-${i}`}><ScssMixin {...m} /></div>)}
            </div>
          }
          {
            a.maps.length > 0 && <div className="rwxs-m-t-xl">
              <p><strong className="impact">SCSS Maps</strong></p>
              {a.maps.map((m, i)=><div className="rwxs-m-t-xl" key={`toolbox-scss-map-${k}-${i}`}><ScssMap {...m} /></div>)}
            </div>
          }
        </div>
      </div>
    });
    return false;
  })

  return SiteData.allSCSS ? <>
      <Back />
      <PageBreak />
      <TabbedLayout tabs={tabcontent} description={data.description} />
    </>
  :
    null
}

export default ScssPage;