import React from 'react';

import rwxCountOMeters from 'roseworx/js/components/rwxCountOMeters';

import StandardLayout from '../../layouts/StandardLayout';

import {parseDataAttributes} from '../../helpers';

function CountOMetersPage({data}) {
	const HTML = ({id, config}) =>{
		return (
			<div className="rwx-split explicit center">
				<div className="rwx-split-quarter-x3">
					<div {...parseDataAttributes(config.dataAttributes)} id={id}></div>
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxCountOMeters} 
			LIVEHTML={HTML}
		/>
	)
}

export default CountOMetersPage;