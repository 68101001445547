import React, {useEffect, useRef} from 'react';
import rwxCanvas from 'roseworx/js/helpers/rwxCanvasHelpers';

import {DemoColor} from '../data';

import DemoBox from './DemoBox';

function StaticCanvas({fn}) {
  const canvasRef = useRef();
  const toRun = useRef(fn)
  useEffect(()=>{
    const cb = toRun.current;
    const canvas = canvasRef.current;
    const c = canvas.getContext('2d');
    const {width, height} = rwxCanvas.scale(canvas, c, 300, 300);
    c.strokeStyle = DemoColor;
    c.lineWidth = 3;
    cb(c, width, height);
  }, [])

  return (
    <DemoBox noParallax={true} small={true} isChild={true}>
      <div className="rwxu-flex-center">
        <canvas ref={canvasRef}></canvas>
      </div>
    </DemoBox>
  )
}

export default StaticCanvas;