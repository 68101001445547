import React from 'react';

import StandardLayout from '../../layouts/StandardLayout';

import {parseDataAttributes, parseClasses} from '../../helpers';

function TablesPage({data}) {
	const rows = Array.from(Array(20).keys());
	const columns = Array.from(Array(3).keys());

	const HTML = ({id, config}) =>{
		const rootClasses = [];
		const x2s = [];
		const x3s = [];

		config.classes.map((cc)=>{
			if(cc.key === "--x2")
			{
				x2s.push(cc);
			}
			else if(cc.key === "--x3")
			{
				x3s.push(cc);
			}
			else
			{
				rootClasses.push(cc);
			}
			return false;
		});

		const childClassCalculate = (i) =>{
			if(i===1)return parseClasses(x2s);
			if(i===2)return parseClasses(x3s);
			return null;
		}

		return (
			<div id={id} className={`rwx-table ${parseClasses(rootClasses)}`} role="table" {...parseDataAttributes(config.dataAttributes)}>
				{
					rows.map((r,i)=>
						<div className="rwx-table-data" role="row" key={`demo-table-data-row-${i}`}>
							{
								columns.map((c, ii)=>
									<span role="cell" key={`demo-table-data-column-${i}-${ii}`} className={childClassCalculate(ii)}>
										<span>Table Data</span>
									</span>
								)
							}
						</div>
					)
				}
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={window.rwx.tables} 
			LIVEHTML={HTML}
		/> 
	)
}

export default TablesPage;