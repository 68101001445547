import {AccesibilityNote} from '../../data';

  const TabsSnippet = `
<div class="rwx-tabs" id="{{ UNIQUE ID }}" role="tablist" rwx-tabs>
  <div class="rwx-tabs-tab" data-rwx-tabs-title="{{ TAB TITLE }}" role="tabpanel">
    {{ TAB CONTENT }}
  </div>
  <div class="rwx-tabs-tab" data-rwx-tabs-title="{{ TAB TITLE }}" role="tabpanel">
    {{ TAB CONTENT }}
  </div>
</div>`;

const TabsData = {
  selector: "rwx-tabs",
  coreAccessName: "tabs",
  componentType: "coreJSAndSCSS",
  roseworxPath: "core",
  manualControl: false,
  roseworxCore: true,
  renew: false,
  snippet: TabsSnippet,
  config: {
    dataAttributes: [
      {
        key: 'data-rwx-tabs-initial',
        value: "false",
        description: "The default initial tab is the first one, use this to set it to a different one.",
        applyToSelector: "[rwx-tabs] .rwx-tabs-tab"
      },
      {
        key: 'data-rwx-tabs-title',
        value: "false",
        description: "The title of the tab.",
        applyToSelector: "[rwx-tabs] .rwx-tabs-tab"
      },
    ],
    classes: [
      {
        key: '--center',
        type: 'radio',
        value: "false",
        display: "Center",
        description: "Align tab headings centrally."
      },
      {
        key: '--right',
        type: 'radio',
        value: "false",
        display: "Right",
        description: "Align tab headings to the right."
      },
    ]
  },
  scssVariables: [
    {
      key: "$tabs-title-color",
      description: "The font color of tab titles.",
      value: "$dark-grey",
      options: ["Any valid CSS color"]
    },
    {
      key: "$tabs-active-color",
      description: "The color of the decoration for an active tab.",
      value: "$light-blue",
      options: ["Any valid CSS color"]
    },
    {
      key: "$tabs-seperator-color",
      description: "The color of the seperator between tab titles and the tab content. Also the color of the border around the tab titles.",
      value: "$grey",
      options: ["Any valid CSS color"]
    },
  ],
  methods: [
    {
      name: 'goToTab',
      returnType: 'void',
      description: 'Call this method to programatically go to a particular tab.',
      example: ".goToTab('{ID}', {TABNUMBER})",
      interactable: true,
      interactableInputs: [
        {
          key: 'getTabNumber',
          type: 'number',
          value: "false"
        },
      ],
      parameters: [
        {
          name: 'ID',
          type: 'String'
        },
        {
          name: 'Tab Number',
          type: 'Number'
        }
      ]
    },
    {
      name: 'addEvent',
      returnType: 'void',
      description: 'Call this method to run a function when a tab has changed.',
      example: ".addEvent('{ID}', {TABNUMBER}, '{EVENT}', '{FUNCTION}')",
      interactable: false,
      parameters: [
        {
          name: 'ID',
          type: 'String'
        },
        {
          name: 'Tab Number',
          type: 'Number'
        },
        {
          name: 'Event',
          type: `'tabShow' || 'tabHide'`
        },
        {
          name: 'Function',
          type: 'Function'
        }
      ]
    },
  ],
  notes: [
    {
      note: 'Tabs come with automatic hash checking. If the URL has a hash parameter in it, rwxTabs will check themselves to see if they hold an element inside them with that id and automatically open the corresponding tab. Try going to this page with #opentabfour appended to the url, it will open Tab four because a heading inside it has an id="opentabfour".',
      icon: 'star'
    },
    {
      note: 'You can nest tabs and use a [rwx-tabs] component inside another [rwx-tabs] component. It will receive slightly different styles to identify it as a "sub-tab".',
      icon: 'star'
    },
    AccesibilityNote,
  ]
}

export default TabsData;