import React, {useState, useEffect, useRef} from 'react';

import {NavLink} from 'react-router-dom';

import {useSpring, animated} from 'react-spring';

import rwxAnimatedBorders from 'roseworx/js/effects/rwxAnimatedBorders';
import useRoseworx from 'roseworx/js/useRoseworx';

import {nav} from '../siteSchema';
import Search from '../components/Search';

const navigationIdentifier = 'nav-slider';

function Navigation() {
	useRoseworx(window.rwx.sliders, navigationIdentifier);
	const navRef = useRef();
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
	const slideSpring = useSpring(
		{
			transform: open ? 'translateY(0%)' : 'translateY(-100%)', 
			config:{mass: 1, tension: 160, friction: 10},
			delay:300
		}
	);

	const slide = useSpring(
		{
			transform: open ? 'translateY(0%)' : 'translateY(-100%)'
		}
	)

	const cross1 = useSpring(
		{
			transform: open ? `rotate(${open2 ? '-28deg' : '-45deg'}) translateY(${open2 ? '-6px' : '0px'})` : 'rotate(0deg) translateY(0px)',
			config:{mass: 1, tension: 170, friction: 11}
		}
	);

	const out = useSpring(
		{
			transform: open ? 'translateY(-100px)' : 'translateY(0px)'
		}
	)

	const cross2 = useSpring(
		{
			transform: open ? `rotate(${open2 ? '28deg' : '45deg'}) translateY(${open2 ? '6px' : '0px'})` : 'rotate(0deg) translateY(0px)',
			config:{mass: 1, tension: 170, friction: 11}
		}
	);

	const buttonClick = () => {
		if(open2 && open)
		{
			setOpen2(false);
			window.rwx.sliders.goToSlide(navigationIdentifier, 1);			
		}
		else
		{
			setOpen(!open);
		}
	}

	const first = useRef(true);
	const ev = useRef((e)=>{
		if(!navRef.current)return;
		if(e.target === navRef.current || navRef.current.contains(e.target))return
		setOpen(false)
	});

	useEffect(()=>{
		if(first.current){
			first.current = false;
			return;
		}
		if(open)
		{
			document.body.addEventListener('click', ev.current);
		}
		else
		{
			document.body.removeEventListener('click', ev.current);
		}
	}, [open])


	const showNavItemCondition = (i) => {
		return (i.text.charAt(0)!=="_" && i.text !== "Home")
	}

	return (
		<div ref={navRef}>
			<button onClick={buttonClick} className="no-decoration navigation-icon navigation-control rwxc-background-orange" value="Menu" title="Menu">
				<animated.span style={cross1} className="rwxc-background-white"></animated.span>
				<animated.span style={out} className="rwxc-background-white"></animated.span>
				<animated.span style={cross2} className="rwxc-background-white"></animated.span>
			</button>
			<animated.nav className="navigation rwxc-background-orange" style={slide}>
				<NavLink to="/" style={{visibility: open ? 'visible' : 'hidden'}} onClick={()=>setOpen(false)} aria-hidden={!open} className="no-decoration navigation-icon navigation-icon-home rwxs-m-t-xs rwxi-home --white --lg" title="Home" aria-label="Home" tabIndex="0"></NavLink>
				<Search isOpen={open} close={()=>setOpen(false)} />
				<animated.div style={{visibility: open ? 'visible' : 'hidden' ,...slideSpring}} aria-hidden={!open} className="rwx-slider rwxs-m-no" id={navigationIdentifier} data-rwx-slider-auto-slide="false" data-rwx-slider-counters="false">
					{
						nav.map((ns, i)=>{
							return <NavList key={`navslider${i}`}>
								{
									ns.map((s, id)=> showNavItemCondition(s) && <NavItem {...s} key={`navsliderslide${i}${id}`} subnav={setOpen2} navopen={setOpen} />)
								}
							</NavList>
						})
					}
				</animated.div>
			</animated.nav>
		</div>
	)
}

function NavItem({text, slideNumber, link, subnav, navopen}) {
	const id = text.toLowerCase().replace(' ', '');
	useRoseworx(rwxAnimatedBorders, id);
	const slide = () => {
		subnav(true);
		window.rwx.sliders.goToSlide(navigationIdentifier, slideNumber);
	}

	const navigate = () => {
		navopen(false);
	}

	return (
		<li>
			{
				!link ?
					<button id={id} className="no-decoration rwxu-pointer h6 rwx-animated-border" onClick={slide}>{text}</button>
				:
				 	<NavLink id={id} to={link} onClick={navigate} className="no-decoration h6 rwx-animated-border"><span>{text}</span></NavLink>
			}
		</li>
	)
}

function NavList({children}) {
	return (
		<div className="rwx-slider-slide">
			<ul className="no-decoration rwxt-text-center rwx-split vertical space-around">
				{children}
			</ul>
		</div>
	)
}

export default Navigation;