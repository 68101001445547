import {ToManyBitFxNote, MouseMoveNote, FullSizeParentNote, InfiniteAnimationMethods} from '../../data';

import getBitFxConfig,{BitFxSnippet} from './bitfx';

const BitBlackHolesConfig = getBitFxConfig('data-rwx-bit-black-hole',
  [
    {
      key: 'data-rwx-bit-black-hole-secondary-color',
      type: 'text',
      options: ['Any valid HEX color'],
      display: "Secondary Bit Color",
      value: "#FFFFFF",
      description: "Sets the color of the background particles."
    },
    {
      key: 'data-rwx-bit-black-hole-disable-trail',
      type: 'checkbox',
      options: ['true', 'false'],
      display: "Disable Trail",
      value: "false",
      description: "Disables the trailing effect."
    },
  ],
  ['nofill', 'shape']
);

const BitBlackHolesData = {
  selector:"rwx-bit-black-hole",
  componentType: "individualJS",
  roseworxPath: "bitfx",
  manualControl: true,
  roseworxCore: true,
  renew: true,
  config: BitBlackHolesConfig,
  notes: [
    ToManyBitFxNote,
    MouseMoveNote,
    FullSizeParentNote,
    {
      note: "You do not have to specify a data-rwx-bit-black-hole-value on this element if you just want the black hole effect with no text.",
      icon: "hint"
    }
  ],
  methods: InfiniteAnimationMethods
}

BitBlackHolesData.snippet = BitFxSnippet(BitBlackHolesData.selector, BitBlackHolesData.config.dataAttributes);

export default BitBlackHolesData;