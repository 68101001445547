const ToolboxData = {
  sections: [
    {
      name: "Javascipt Helper methods",
      links: [
        {
          name: "Canvas Helpers",
          link: "/toolbox/canvashelpers",
          description: "A few helper methods to aid in canvas development."
        },
        {
          name: "Geometry Helpers",
          link: "/toolbox/geometryhelpers",
          description: "An array of useful methods for common geometry calculations."
        },
        {
          name: "Math Helpers",
          link: "/toolbox/mathhelpers",
          description: "Some useful math functions."
        },
        {
          name: "DOM Helpers",
          link: "/toolbox/domhelpers",
          description: "Helper methods specific to DOM manipulation."
        },
        {
          name: "Misc Helpers",
          link: "/toolbox/mischelpers",
          description: "Miscellaneous helper methods."
        }
      ]
    },
    {
      name: "Style customisations",
      links: [
        {
          name: "CSS",
          link: "/toolbox/css",
          description: "Utility & modifier classes."
        },
        {
          name: "SCSS",
          link: "/toolbox/scss",
          description: "SCSS customisations."
        },
        {
          name: "Themes",
          link: "/toolbox/themes",
          description: "Theme the Roseworx framework."
        }
      ]
    }
  ]
}

export default ToolboxData;