import React from 'react';

import rwxSlideTickers from 'roseworx/js/components/rwxSlideTickers';

import StandardLayout from '../../layouts/StandardLayout';

import {UseLiveInteractionToViewComponent} from '../../common';

function SlideTickersPage({data}) {
	const HTML = ({id, config}) =>{
		return (
			<>
				<div className="rwxu-full-size">
					<div className="rwx-slide-ticker" id={id}>
						<span className="rwx-slide-ticker-value">Roseworx</span>
					</div>
				</div>
				<UseLiveInteractionToViewComponent />
			</>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxSlideTickers} 
			LIVEHTML={HTML}
		/> 
	)
}


export default SlideTickersPage;