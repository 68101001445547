const easings = [
  'linear', 
  'easeInQuad', 
  'easeOutQuad', 
  'easeInOutQuad', 
  'easeInCubic', 
  'easeOutCubic', 
  'easeInOutCubic', 
  'easeInQuart', 
  'easeOutQuart', 
  'easeInOutQuart', 
  'easeInQuint', 
  'easeOutQuint', 
  'easeInOutQuint', 
  'easeInFriction',
  'easeInElasticFriction',
  'easeInElastic',
  'easeInElasticLoose',
  'easeOutElasticFriction', 
  'easeOutElastic',
  'easeOutElasticLoose',
  'easeInOutElastic', 
  'easeOutBack'
];

const BasicSnippet = `
const animation = new rwxAnimation(
  { 
    from: 0, 
    to: 1, 
    easing: 'linear', 
    duration: 1000 
  }
);
`;

const FunctionSnippet = `
const getNumber = (parameter) => {
  // This will only get executed when the animation starts
  // Helpful if you want to get the position or measurements
  // of something at this exact moment in time.
  return parameter==="to" ? 10 : 30;
};

const animation = new rwxAnimation(
  { 
    from: ()=>getNumber('from'), 
    to: ()=>getNumber('to'), 
    easing: 'easeOutQuad', 
    duration: 1000 
  }
);
`;

const ControlSnippet = `

const animation = new rwxAnimation(
  { 
    from: 0, 
    to: 1, 
    easing: 'linear', 
    duration: 3000 ,
    control: {cp1: 20, cp2: 20}
  }
);
`;

const ArraySnippet = `
const getNumber = () => {
  return 10
};

const getControlPoint = () => {
  return 30;
}

const animation = new rwxAnimation(
  { 
    from: [0, ()=>getNumber(), 100], 
    to: [-10, 300, ()=>getNumber()],
    control: [
      {cp1: 30, cp2: 40},
      {cp1: 20, cp2: ()=>getControlPoint()},
      {cp1: ()=>getControlPoint(), cp2: 99}
    ]
    easing: ['linear', 'easeInQuad', 'easeOutQuad'], 
    duration: 1000 
  }
);
`;

const LoopCompleteSnippet = `
const animation = new rwxAnimation(
  { 
    from: 0, 
    to: 1, 
    easing: 'linear', 
    duration: 1000,
    loop: true,
    complete: ()=>console.log("animation complete")
  }
);
`;

const ArrayIndexSnippet = `
const animation = new rwxAnimation(
  { 
    from: [0, 10, 33, 40, 70, 88, 100] 
    to: [1, 0, 0], 
    easing: 'easeInOutQuart', 
    duration: 1000
  }
);
`;

const AnimateSnippet = `
const animate = () => {
  animation.animate((val) => {
    // Do something with the animation value
    console.log(val);
  });
  window.requestAnimationFrame(animate);
}
animate();
`;

const AnimateMultipleSnippet = `
const animate = () => {
  animation.animate((x, y, z) => {
    // Do something with the animation values
    console.log(x, y, z);
  });
  window.requestAnimationFrame(animate);
}
animate();
`;

const GetEasingSnippet = `
const animate = () => {
  animation.animate((v) => {console.log(v);});
  console.log(animation.getEasingValue());
  window.requestAnimationFrame(animate);
}
animate();
`;

const GetProgressSnippet = `
const animate = () => {
  animation.animate((v) => {console.log(v);});
  console.log(animation.getProgress());
  window.requestAnimationFrame(animate);
}
animate();
`;

const ResetSnippet = `
animation.reset();
`;

const PauseSnippet = `
animation.pause();
`;

const PlaySnippet = `
animation.play();
`;

const AnimationSnippet = `
let stopAnimation = false;

const animation = new rwxAnimation(
  { 
    from: [10, 20, -30], 
    to: 1, 
    easing: 'linear', 
    duration: 1000,
    complete: ()=>{stopAnimation = true;}
  }
);

const animate = () => {
  if(!stopAnimation)
  {
    animation.animate((val1, val2, val3) => {
      // Do something with the animation values
      console.log(val1, val2, val3);
    });
  }
  window.requestAnimationFrame(animate);
}
animate();
`;

const AnimationSingleData = {
  roseworxPath: 'modules',
  componentType: 'individualJS',
  manualControl: false,
  roseworxCore: false,
  renew: false,
  config: {
    demo : [
      {
        key: 'duration',
        type: 'number',
        display: "Duration",
        value: "3000",
        description: "Duration of the animation in milliseconds."
      },
      {
        key: 'easing',
        type: 'select',
        display: "Easing",
        options: easings,
        value: "linear",
        description: `Easing of the animation.`
      },
      {
        key: 'loop',
        type: 'checkbox',
        display: "Loop",
        value: "true",
        description: "Repeats the animation over and over."
      }
    ],
  },
  methods: [
    {
      name: 'Constructor',
      returnType: 'Class',
      description: 'Construct an animation.',
      acceptsObject: true,
      parameters: [
        {
          name: 'from',
          type: 'Number || Function || Array',
        },
        {
          name: 'to',
          type: 'Number || Function || Array'
        },
        {
          name: 'duration',
          type: 'Number || Array'
        },
        {
          name: 'easing',
          type: `String`,
          options: easings
        },
        {
          name: 'control',
          type: 'Object {cp1: Number || Function, cp2: Number || Function} || Array'
        },
        {
          name: 'loop',
          type: 'Boolean'
        },
        {
          name: 'complete',
          type: 'Function'
        }
      ],
      example: BasicSnippet
    },
    {
      name: 'animate',
      returnType: 'Void',
      description: 'Run an animation. Must be executed inside an animation loop.',
      parameters: [
        {
          name: 'function',
          type: 'Function'
        }
      ],
      example: AnimateSnippet
    },
    {
      name: 'getEasingValue',
      returnType: 'Number',
      example: GetEasingSnippet,
      description: 'Returns the easing value (0-1) for the current animation frame.'
    },
    {
      name: 'getProgress',
      returnType: 'Number',
      example: GetProgressSnippet,
      description: 'Returns the percentage the animation has completed (0-100) for the current animation frame.'
    },
    {
      name: 'reset',
      returnType: 'Void',
      example: ResetSnippet,
      description: 'Resets an animation to the start. Useful when an animation has ran and you want to run it again.'
    },
    {
      name: 'pause',
      returnType: 'Void',
      example: PauseSnippet,
      description: 'Pauses an animation.'
    },
    {
      name: 'play',
      returnType: 'Void',
      example: PlaySnippet,
      description: 'Plays an animation after it has been paused.'
    }
  ],
  steps: [
    {
      heading: "Construct an animation",
      extra: [
        "This basic animation will animate a value from 0 to 1 in 1 second over a linear easing.", 
        "Duration is always in milliseconds.", 
        "Numbers can either be positive or negative."
      ],
      snippet: BasicSnippet
    },
    {
      heading: "from & to as functions",
      extra: [
        "You can use functions to return a value, however, they must return a number.", 
        "Functions are executed at runtime instead of when you construct the animation. This allows you to retrieve values at the point of execution."
      ],
      snippet: FunctionSnippet
    },
    {
      heading: "Control points",
      extra: [
        "The control object allows you to animate your from value to your to value via 2 control points.", 
        "This gives you the flexibilty of animating over bezier curves instead of direct point A to point B transitions."
      ],
      snippet: ControlSnippet
    },
    {
      heading: "Arrays",
      extra: [
        "from, to, easing & control can also all be an array.", 
        "This example will animate 3 values all at the same time.", 
        "The position of the value in the from array syncs up with the position in the other arrays.", 
        "The array can hold numbers, functions or a mix of both."
      ],
      snippet: ArraySnippet
    },
    {
      heading: "Loop & Complete",
      extra: [
        "Setting loop to true will make the animation continuously run over and over again.", 
        "Set a complete function and it will be ran when the animation has completed.", 
        "If loop is set to true, the complete function will never get executed."
      ],
      snippet: LoopCompleteSnippet
    },
    {
      heading: "Array index matching",
      extra: [
        "If the from value is an array, the index of each value will try to match the index of to, easing and control arrays.",
        "However if no index is found, the first index of the array will be used OR if no array is found, the value will be used.",
        "In this example, ALL the from values will use the 'easeInOutQuart' easing. The first 3 from values will take the to value from the same index as themselves. As there is no index match for the remaining from values, they will use the to value '1' as it's the first index in the to array.",
        "The same rules apply to the control array, if one is specified."
      ],
      snippet: ArrayIndexSnippet
    },
    {
      heading: "Execution",
      extra: [
        "To execute an animation you need to create an animation loop using window.requestAnimationFrame.",
        "You then call the animate method on the variable you stored the contructor in.",
        "The animate method will be called with the value of the animation as a parameter."
      ],
      snippet: AnimateSnippet
    },
    {
      heading: "Execution of multiple values",
      extra: [
        "If, in the constructor, you specified an array of values to the from key, the animate method will be called with the same number of parameters as items in that array.",
        "E.G in this example, the from had an array of 3 values so the animate method has 3 parameters.",
        "You can name the parameters whatever you like."
      ],
      snippet: AnimateMultipleSnippet
    },
    {
      heading: "Putting it all together",
      extra: [
        "It is up to you to stop the execution of the animate method within window.requestAnimationFrame or it will keep running indefinitely.",
        "It is recommended you put some form of 'stop flag' in the complete callback for each animation, unless, of course, you set loop to true and want it running indefinitely."
      ],
      snippet: AnimationSnippet
    }
  ],
}

const BasicChainSnippet = `
let animations = [{
    from: [70, 50],
    to: [50, 30],
    easing: ['linear', 'easeOutBack'],
    duration: 1000,
  },
  {
    from: 50,
    to: -50,
    easing: 'easeInQuad',
    duration: 3000,
  }
];
const chain = new rwxAnimationChain({
    sequence: animations
});
`;

const OmitFromSnippet = `
let animations = [{
    from: [70, 50],
    to: [50, 30],
    easing: ['linear', 'easeOutBack'],
    duration: 1000,
  },
  {
    // the from values will be [50, 30] from the previous animations to
    to: -50,
    easing: 'easeInQuad',
    duration: 3000,
  }
];
const chain = new rwxAnimationChain({
    sequence: animations
});
`;

const DelayChainSnippet = `
let animations = [{
    from: [70, 50],
    to: [50, 30],
    easing: ['linear', 'easeOutBack'],
    duration: 1000,
    delay: 1000
  },
  {
    from: 300,
    to: 0,
    control: {cp1: 25, cp2: 400},
    easing: 'easeInQuad',
    duration: 3000,
  },
  {
    from: 33,
    to: -50,
    easing: 'easeInOutQuint',
    duration: 500,    
  },
  {
    from: [50, 60, 70],
    to: 1000,
    control: [
      {
        cp1: 60,
        cp2: 40
      },
      {
        cp2: 40,
        cp1: 80
      },
      {
        cp1: 0,
        cp2: 140
      }
    ],
    delay: 2000,
    easing: ['easeInCubic', 'linear'],
    duration: 3000,
  },
  {
    from: 50,
    to: -50,
    easing: 'easeInQuad',
    duration: 3000,
  }
];
const chain = new rwxAnimationChain({
    sequence: animations
});
`;

const LoopCompleteChainSnippet = `
let animations = [{
    from: 70,
    to: 50,
    easing: 'linear',
    duration: 1000,
    complete: () => console.log("animation 1 complete")
  },
  {
    from: 50,
    to: -50,
    easing: 'easeInQuad',
    duration: 3000,
  }
];
const chain = new rwxAnimationChain({
  sequence: animations,
  loop: true,
  complete: () => console.log("animation chain complete")
});
`;

const AnimateChainSnippet = `
const animate = () => {
  animation.animate([
    (val) => {
      // Do something with the animation value from first animation
      console.log(val);
    },
    (val) => {
      // Do something with the animation value from second animation
      console.log(val);
    }
  ]);
  window.requestAnimationFrame(animate);
}
animate();
`;

const AnimationChainSnippet = `
let stop = false;
let animations = [{
    from: [70, 50],
    to: [50, 30],
    easing: ['linear', 'easeOutBack'],
    duration: 1000,
  },
  {
    from: 50,
    to: -50,
    easing: 'easeInQuad',
    duration: 3000,
  }
];
const chain = new rwxAnimationChain({
    sequence: animations,
    complete: ()=>{stop=true;}
});

const animate = () => {
  if(!stop)
  {
    // Number of functions in array matches the number of animations in the sequence array
    animation.animate([
      (val, val2) => {
      // Do something with the animation values from first animation
      console.log(val);
      },
      (val) => {
      // Do something with the animation value from second animation
      console.log(val);
      }
    ]);
  }
  window.requestAnimationFrame(animate);
}
animate();
`

const AnimationChainData = {
  roseworxPath: 'modules',
  componentType: 'individualJS',
  manualControl: false,
  roseworxCore: false,
  renew: false,
  config: {
    demo : [
      {
        key: 'chainduration1',
        type: 'number',
        display: "Duration 1",
        value: "3000",
        description: "Duration for the first animation in milliseconds."
      },
      {
        key: 'chaineasing1',
        type: 'select',
        display: "Easing 1",
        options: easings,
        value: "linear",
        description: `Easing for the first animation.`
      },
      {
        key: 'chaindelay1',
        type: 'number',
        display: "Delay 1",
        value: "false",
        description: `Delay for the first animation.`
      },
      {
        key: 'chainduration2',
        type: 'number',
        display: "Duration 2",
        value: "3000",
        description: "Duration for the second animation in milliseconds."
      },
      {
        key: 'chaineasing2',
        type: 'select',
        display: "Easing 2",
        options: easings,
        value: "linear",
        description: `Easing for the second animation.`
      },
      {
        key: 'chaindelay2',
        type: 'number',
        display: "Delay 2",
        value: "false",
        description: `Delay for the second animation .`
      },
      {
        key: 'chainloop',
        type: 'checkbox',
        display: "Loop",
        value: "true",
        description: "Repeats the animation over and over."
      }
    ],
  },
  methods: [
    {
      name: 'Constructor',
      returnType: 'Class',
      description: 'Construct an animation chain.',
      acceptsObject: true,
      parameters: [
        {
          name: 'sequence',
          type: 'Array of objects with the same options as rwxAnimation. With the addition of delay and the subtraction of loop.',
        },
        {
          name: 'loop',
          type: 'Boolean'
        },
        {
          name: 'complete',
          type: 'Function'
        }
      ],
      example: BasicChainSnippet
    },
    {
      name: '.animate',
      returnType: 'Void',
      description: 'Run an animation chain. Must be executed inside an animation loop.',
      parameters: [
        {
          name: 'Array of functions',
          type: 'Array'
        }
      ],
      example: AnimateChainSnippet
    },
    {
      name: 'reset',
      returnType: 'Void',
      example: ResetSnippet,
      description: 'Resets an animation chain to the start. Useful when an animation chain has ran and you want to run it again.'
    },
    {
      name: 'pause',
      returnType: 'Void',
      example: PauseSnippet,
      description: 'Pauses an animation chain.'
    },
    {
      name: 'play',
      returnType: 'Void',
      example: PlaySnippet,
      description: 'Plays an animation chain, after it has been paused.'
    }
  ],
  steps: [
    {
      heading: "Construct an animation chain",
      extra: [
        "The animation chain constructor allows you to create a sequence of animations that will get executed one after another.",
        "Think of each object in the sequence array as a rwxAnimation. The same index matching rules apply and you can use the same properties except the loop property, this has no effect inside a chain."
      ],
      snippet: BasicChainSnippet  
    },
    {
      heading: "Omitting from",
      extra: [
        "For animations after the first one, you can optionally omit the from property and rwxAnimation will use the to values from the previous animation."
      ],
      snippet: OmitFromSnippet
    },
    {
      heading: "Delay",
      extra: [
        "You can delay the execution of any animation inside a chain independently.",
        "It's important to understand that the delay is how many milliseconds before the current animation is executed, not how long until the next one is executed.",
        "In this example, there will be 1000 millisecond delay before the first animation is executed, the chain continuously proceeds until the 3rd one finishes, there will then be a 2000 millisecond delay until the 4th animation executes."
      ],
      snippet: DelayChainSnippet
    },
    {
      heading: "Loop & Complete",
      extra: [
        "Setting loop to true will make the animation chain continuously run over and over again.", 
        "Set a complete function and it will be ran when the animation chain has completed.", 
        "If loop is set to true, the complete function on the chain will never get executed.",
        "loop property set on each individual animation has NO effect as it is set on the chain instead.",
        "You can still set a complete function on each animation and it will still get executed when that particular animation has completed."
      ],
      snippet: LoopCompleteChainSnippet
    },
    {
      heading: "Execution",
      extra: [
        "The animate method for a chain accepts an array of functions, one for each animation in the chain. The functions accept the same amount of parameters as from values for that particular animation."
      ],
      snippet: AnimateChainSnippet
    },
    {
      heading: "Putting it all together",
      extra: [
        "It is up to you to stop the execution of the animate method within window.requestAnimationFrame or it will keep running indefinitely.",
        "It is recommended you put some form of 'stop flag' in the complete callback for each animation chain, unless, of course, you set loop to true and you want it running indefinitely."
      ],
      snippet: AnimationChainSnippet
    }
  ]
}

const AnimationData = {
  flatten: ['animation', 'animationChain'],
  description: [
    `The rwxAnimation module allows you to transition 1 or multiple number values to another number value with different easing options over a specified amount of time.`,
    `You can then use the parsed values to move html elements, SVG's, objects on a canvas, etc.. and create neat effects quickly and easily.`
  ],
  animation: AnimationSingleData,
  animationChain: AnimationChainData,
}

export default AnimationData;