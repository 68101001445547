import React from 'react';

import rwxPhotoTiles from 'roseworx/js/components/rwxPhotoTiles';

import StandardLayout from '../../layouts/StandardLayout';

import {parseDataAttributes, getRandomColor} from '../../helpers';

function PhotoTilesPage({data}) {

	const Background = () => {
		const color = getRandomColor();
		return (
			<div tabIndex="0" role="button" className={`rwxc-background-${color}`}></div>
		)
	}

	const Image = ({index, size}) => {
		return (
			<img src={`https://picsum.photos/${size}/${size}?v=${index+1}`} alt="Demonstration" />
		)
	}

	const HTML = ({id, config}) =>{
		return (
			<div className="rwx-phototile" {...parseDataAttributes(config.dataAttributes)} id={id}>
				<Image index={0} size={350} />
				<Image index={1} size={550} />
				<Background />
				<Image index={2} size={400} />
				<Image index={3} size={300} />
				<Background />
				<Image index={2} size={350} />
				<Image index={3} size={500} />
				<Background />
				<Image index={2} size={350} />
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxPhotoTiles} 
			LIVEHTML={HTML}
		/>
	)
}

export default PhotoTilesPage;