import React from 'react';

import HubLayout from '../layouts/HubLayout';

function EffectsPage({data}){
  return (
    <HubLayout data={data} />
  )
}

export default EffectsPage;