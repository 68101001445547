import {NoJSRequiredNote} from '../../data';

  const FormsSnippet = [
  {
    descriptor: 'Form',
    html: `
<form class="rwx-form" id="{{ UNIQUE ID }}" rwx-form>
  {{RWX FORM ITEMS}}
  <button type="submit">{{ SUBMIT FORM }}</button>
</form>
`
  },
  {
    descriptor: 'Input field',
    html: `
<div class="rwx-form-item">
  <input type={{ INPUT TYPE }} name="{{ NAME OF FIELD }}" id="{{ UNIQUE ID }}" />
  <label for="{{ SAME AS INPUT ID }}">{{ LABEL TEXT }}</label>
  <p class="invalid-message">{{ INVALID MESSAGE TEXT }}</p>
</div>
`
  },
  {
    descriptor: 'Checkbox',
    html: `
<div class="rwx-form-item rwx-checkbox">
  <input type="checkbox" name="{{ NAME OF FIELD }}" id="{{ UNIQUE ID }}" />
  <label for="{{ SAME AS INPUT ID }}">{{ LABEL TEXT }}</label>
  <p class="invalid-message">{{ INVALID MESSAGE TEXT }}</p>
</div>
`
  },
  {
    descriptor: 'Toggle',
    html: `
<div class="rwx-form-item rwx-toggle">
  <input type="checkbox" name="{{ NAME OF FIELD }}" id="{{ UNIQUE ID }}" />
  <label for="{{ SAME AS INPUT ID }}">{{ LABEL TEXT }}</label>
  <p class="invalid-message">{{ INVALID MESSAGE TEXT }}</p>
</div>
`
  },
  {
    descriptor: 'Text Area',
    html: `
<div class="rwx-form-item">
  <textarea name="{{ NAME OF FIELD }}" id="{{ UNIQUE ID }}" rows="{{ HOW MANY ROWS OF TEXT }}"></textarea>
  <label for="{{ SAME ID AS TEXTAREA }}">{{ LABEL TEXT }}</label>
  <p class="invalid-message">{{ INVALID MESSAGE TEXT }}</p>
</div>
`
  },
  {
    descriptor: 'Select List',
    html: `
<div class="rwx-form-item rwx-select">
  <select name="{{ NAME OF FIELD }}" id="{{ UNIQUE ID }}">
    <option value="{{ OPTION VALUE }}">{{ OPTION TEXT }}</option>
    <option value="{{ OPTION VALUE }}">{{ OPTION TEXT }}</option>
    <option value="{{ OPTION VALUE }}">{{ OPTION TEXT }}</option>
  </select>
  <label for="{{ SAME ID AS SELECT}}">{{ LABEL TEXT }}</label>
</div>
`
  },
  {
    descriptor: 'Radio Group',
    html: `
<div class="rwx-form-radio-group" id="{{ UNIQUE ID }}">
  <p>{{ RADIO GROUP LABEL TEXT }}</p>
  <div class="rwx-form-item rwx-radio">
    <input type="radio" name="{{ SAME NAME AS OTHER RADIO ITEMS IN SAME RADIO GROUP }}" id="{{ UNIQUE ID }}" />
    <label for="{{ SAME AS INPUT ID }}">{{ RADIO GROUP ITEM LABEL TEXT }}</label>
  </div>
  <div class="rwx-form-item rwx-radio">
    <input type="radio" name="{{ SAME NAME AS OTHER RADIO ITEMS IN SAME RADIO GROUP }}" id="{{ UNIQUE ID }}" />
    <label for="{{ SAME AS INPUT ID }}">{{ RADIO GROUP ITEM LABEL TEXT }}</label>
  </div>
  <div class="rwx-form-item rwx-radio">
    <input type="radio" name="{{ SAME NAME AS OTHER RADIO ITEMS IN SAME RADIO GROUP }}" id="{{ UNIQUE ID }}" />
    <label for="{{ SAME AS INPUT ID }}">{{ RADIO GROUP ITEM LABEL TEXT }}</label>
  </div>
</div>
`
  }
];

const FormsChangeInputColorMixinSnippet = `
.rwx-form-item.change-input-color
{
  @include change-input-color($black, $white);
}
`;


const FormsData = {
  selector: 'rwx-form',
  componentType: "coreJSAndSCSS",
  coreAccessName: "forms",
  roseworxPath: "core",
  manualControl: false,
  roseworxCore: true,
  renew: false,
  snippet: FormsSnippet,
  config: {
    demo: [
      {
        key: 'readonly',
        type: 'checkbox',
        value: "false",
        display: "Read only",
        description: "Read only input.",
      },
      {
        key: 'disabled',
        type: 'checkbox',
        value: "false",
        display: "Disabled",
        description: "Disabled input.",
      },
    ],
    classes: [
      {
        key: '--inline',
        type: 'checkbox',
        value: "false",
        display: "Inline",
        description: "Makes the input inline with the label.",
        applyToSelector: ".rwx-form .rwx-form-item || .rwx-form .rwx-form-radio-group"
      },
      {
        key: 'required',
        type: 'checkbox',
        value: "false",
        display: "Required",
        description: "Makes the input a required field.",
        applyToSelector: ".rwx-form .rwx-form-item"
      },
      {
        key: 'valid',
        type: 'radio',
        value: "false",
        display: "Valid",
        description: "Makes the input have valid styles.",
        applyToSelector: ".rwx-form .rwx-form-item"
      },
      {
        key: 'invalid',
        type: 'radio',
        value: "false",
        display: "Invalid",
        description: "Makes the input have invalid styles.",
        applyToSelector: ".rwx-form .rwx-form-item"
      },
    ]
  },
  methods: [
    {
      name: 'customSubmitFn',
      returnType: 'void',
      description: 'By default, once the submit button is pressed on a form, the data will be submitted normally to the url specified in the action attribute on the rwx-form node. However, this method allows you to provide an overiding function for any form. The custom function supplied will be called when the form is submitted with a parameter of type Object with key value pairs for each field - in the format of {fieldname: value}.',
      example: ".customSubmitFn('{ID}', (values)=>console.log(values))",
      interactable: false,
      parameters: [
        {
          name: 'ID',
          type: 'String'
        },
        {
          name: 'Function',
          type: 'Function'
        }
      ]
    },
  ],
  scssMixins: [
    {
      snippet: FormsChangeInputColorMixinSnippet,
      title: "Change Input Color",
      description: "Change the color of indiviudal form fields. This example will change the input color of the .rwx-form-item with class .change-input-color using $black as $form-color-base and $white as $form-color-secondary."
    }
  ],
  scssVariables: [
    {
      key: "$form-color-base",
      description: "Used as the color of form item labels, form item input text & the color of 'checked' toggles, checkboxes and radio buttons.",
      value: "$dark-grey",
      options: ["Any valid CSS color"]
    },
    {
      key: "$form-color-secondary",
      description: "Used as the border color for for items, background color of 'disabled' & 'readonly' fields & the color of 'unchecked' toggles.",
      value: "$grey",
      options: ["Any valid CSS color"]
    },
    {
      key: "$form-color-valid",
      description: "Color of the input border if a field is valid (has the .valid class on the .rwx-form-item element).",
      value: "$black",
      options: ["Any valid CSS color"]
    },
    {
      key: "$form-color-invalid",
      description: "Color of the input border and the invalid message if a field is invalid (has the .invalid class on the .rwx-form-item element).",
      value: "$red",
      options: ["Any valid CSS color"]
    },
    {
      key: "$form-item-size",
      description: "The minimum height of the input fields.",
      value: "50px",
      options: ["Any valid CSS length"]
    },
    {
      key: "$form-inline-input-size",
      value: "67%",
      options: ["A valid percentage of 100"],
      description: "When the --inline flag is set on a form field, the inputs are given a specific width in order to keep them at the same indentation as other --inline inputs. This adjusts the percentage that the --inline input fields take up."
    }
  ],
  notes: [
    NoJSRequiredNote('form')
  ]
}

export default FormsData;