import React from 'react';

import StandardLayout from '../layouts/StandardLayout';

import Back from '../components/Back';

import Description from '../structural/Description';
import PageBreak from '../structural/PageBreak';

/*
  Layout:
    Back -> Description -> StandardLayout

  Props:
    data: REQUIRED - (FOLLOW STANDARDDATASCHEMA IN README)
    HTML: OPTIONAL - React functional component ({id, config}), will get re-rendered with id and config selected from interactiveConfiguration
    component: OPTIONAL - reference to the actual Roseworx resource
    hashPrepend: Prepends a particular string to the ID of sections and components - useful if you will have component/sections with a duplicate id 

  Used data keys (merge with StandardLayout keys):
    description
*/

function EffectLayout({data, HTML, component, hashPrepend}) {
  return (
    <>
      <Back />
      <PageBreak />
      <Description paragraphs={data.description} />
      <StandardLayout 
        data={data} 
        LIVEHTML={HTML}
        rwxComponent={component}
        useExamples={true}
        hashPrepend={hashPrepend}
      />
    </>
  )
}

export default EffectLayout;