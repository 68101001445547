import React from 'react';

import rwxPreload from 'roseworx/js/modules/rwxPreload';

import SteppedLayout from '../../layouts/SteppedLayout';

function PreloadPage({data}) {
	return (
		<SteppedLayout data={data} component={rwxPreload}/>
	)
}

export default PreloadPage;