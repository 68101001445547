import React from 'react';

import Section from '../structural/Section';
import Note from '../structural/Note';

function Notes({notes, hashPrepend}) {

	const calculateSize = (str) => {
		return str.length > 250 ? 'rwx-split-full' : 'rwx-split-half';
	}

	return (
		<Section title="Notes" hashPrepend={hashPrepend} classes="notes-section">
			<div className="rwx-split explicit center">
				{
					notes && notes.map((n, i)=>
						<div className={`${calculateSize(n.note)} rwxs-m-t-lg`} key={`n-${i}`}>
							<Note {...n} />
						</div>
					)
				}
			</div>
		</Section>
	)
}

export default Notes;