import {AddFixedForFullScreen} from '../../data';

const NoticeBoxesSnippet = `
<div class="rwx-notice-box" rwx-notice-box id="{{ UNIQUE ID }}">
  {{ ANY CONTENT }}
</div>
`;

const NoticeBoxesChangeColorMixinSnippet = `
.rwx-notice-box.change-color
{
  @include change-notice-box-color($blue);
}
`;

const NoticeBoxesData = {
  selector: 'rwx-notice-box',
  componentType: "individualJSAndSCSS",
  roseworxPath: "components", 
  manualControl: false,
  roseworxCore: true,
  renew: false,
  snippet: NoticeBoxesSnippet,
  config: {
    dataAttributes: [
      {
        key: 'data-rwx-notice-box-auto-close-delay',
        type: 'number',
        options: ['Any valid number'],
        display: "Auto close delay",
        value: "5",
        description: "The delay in seconds before the notice box closes automatically, if it has been set to do so in the notify method."
      },
    ],
    classes: [
      {
        key: '--bottom',
        type: 'checkbox',
        value: "false",
        display: "Bottom orientation",
        description: "Position the notice box to the bottom instead of top."
      }
    ]
  },
  methods: [
    {
      name: 'notify',
      returnType: 'Void',
      description: 'Opens the notice box with optional parameters. If autoclose is set to true, the notice box will automatically close within the amount of seconds set by data-rwx-notice-box-auto-close-delay. If a callback function is specified, it will be called when the notice box is closed.',
      example: ".notify('{ID}', {AUTOCLOSE}, '{CALLBACK}')",
      interactable: true,
      interactableInputs: [
        {
          key: 'getAutoClose',
          type: 'checkbox',
          value: "false",
          display: "Auto close"
        },
      ],
      parameters: [
        {
          name: 'ID',
          type: 'String'
        },
        {
          name: 'Autoclose',
          type: 'Boolean'
        },
        {
          name: 'Callback',
          type: 'Function'
        }
      ]
    },
    {
      name: 'unnotify',
      returnType: 'Void',
      description: 'Closes the notice box.',
      example: ".unnotify('{ID}')",
      interactable: true,
      parameters: [
        {
          name: 'ID',
          type: 'String'
        }
      ]
    },
  ],
  scssMixins: [
    {
      snippet: NoticeBoxesChangeColorMixinSnippet,
      title: "Change Notice Box Color",
      description: "Change the color of individual notice boxes. This example will change the notice box with class .change-color to $blue."
    }
  ],
  notes: [
    {
      note: "This component requires the parent node to have a relative position or it will use the nearest one.",
      icon: "warning"
    },
    AddFixedForFullScreen('[rwx-notice-box]')
  ]
}

export default NoticeBoxesData;