const MiscHelpersSnippet = `
import rwxMisc from 'roseworx/js/helpers/rwxMiscHelpers';
`;

const UniqueIdSnippet = `
const Result = rwxMisc.uniqueId();
// Result: _4kmfajof3vx
`;

const SafeCloneObjectSnippet = `
const obj = { a: "b", c: "d", e: "f" };
const Result = rwxMisc.safeCloneObject(obj);
obj.a = "g";
// Result: {"a":"b","c":"d","e":"f"}
// obj: {"a":"g","c":"d","e":"f"}
`;

const SafeCloneArraySnippet = `
const arr = ['a', 'b', 'c', 'd', 'e', 'f'];
const Result = rwxMisc.safeCloneArray(arr);
arr[3] = "g";
// Result: ["a","b","c","d","e","f"]
// arr: ["a","b","c","g","e","f"]
`;

const SafeCloneArrayOfObjectsSnippet = `
const arr = [{ a: "b" }, { c: "d" }, { e: "f" }];
const Result = rwxMisc.safeCloneArrayOfObjects(arr);
arr[0] = { g: "h" };
arr[2].e = "i";
// Result: [{"a":"b"},{"c":"d"},{"e":"f"}]
// arr: [{"g":"h"},{"c":"d"},{"e":"i"}]
`;

const ShuffleArraySnippet = `
const arr = ['a', 'b', 'c', 'd', 'e', 'f'];
const Result = rwxMisc.shuffleArray(arr);
// Result: ["b","c","e","d","a","f"]
`;

const RandomValueFromArraySnippet = `
const arr = ['a', 'b', 'c', 'd', 'e', 'f'];
const Result = rwxMisc.randomValueFromArray(arr);
// Result: 'e'
`;

const SetCookieSnippet = `
const cookieName = "RoseWorx";
const cookieValue = "Is Great";
const expires = 31;
rwxMisc.setCookie(cookieName, cookieValue, expires);
`;

const GetCookieSnippet = `
const cookieName = "RoseWorx";
const Result = rwxMisc.getCookie(cookieName);
// Result: Value of cookie
`;

const MiscHelpersData = {
  snippet: MiscHelpersSnippet,
  methods: [
    {
      name: 'uniqueId',
      returnType: 'String',
      description: 'Generates a random 10 character hexadecimal ID prefixed with an underscore.',
      example: UniqueIdSnippet
    },
    {
      name: 'safeCloneObject',
      returnType: 'Object',
      description: 'Safely clones an object, removing all references to the original object.',
      example: SafeCloneObjectSnippet,
      parameters: [
        {
          name: 'Reference Object',
          type: 'Object'
        }
      ]
    },
    {
      name: 'safeCloneArray',
      returnType: 'Array',
      description: 'Safely clones an array, removing all references to the original array.',
      example: SafeCloneArraySnippet,
      parameters: [
        {
          name: 'Reference Array',
          type: 'Array'
        }
      ]
    },
    {
      name: 'safeCloneArrayOfObjects',
      returnType: 'Array',
      description: 'Safely clones an array of objects one level deep, removing all references to the original array and first level objects.',
      example: SafeCloneArrayOfObjectsSnippet,
      parameters: [
        {
          name: 'Reference Array',
          type: 'Array'
        }
      ]
    },
    {
      name: 'shuffleArray',
      returnType: 'Void',
      description: 'Randomly shuffles an array.',
      example: ShuffleArraySnippet,
      parameters: [
        {
          name: 'Array',
          type: 'Array'
        }
      ]
    },
    {
      name: 'randomValueFromArray',
      returnType: 'Array item',
      description: 'Randomly picks a value from an array.',
      example: RandomValueFromArraySnippet,
      parameters: [
        {
          name: 'Array',
          type: 'Array'
        }
      ]
    },
    {
      name: 'setCookie',
      returnType: 'Void',
      description: 'Sets a cookie for the given amount of days. Omit expiry length to have the cookie expire at the end of the session.',
      example: SetCookieSnippet,
      parameters: [
        {
          name: 'Cookie Name',
          type: 'String'
        },
        {
          name: 'Cookie Value',
          type: 'String'
        },
        {
          name: 'Expiry Length (In Days)',
          type: 'Number'
        },
      ]
    },
    {
      name: 'getCookie',
      returnType: 'String',
      description: 'Retrieve a cookie with the given cookie name.',
      example: GetCookieSnippet,
      parameters: [
        {
          name: 'Cookie Name',
          type: 'String'
        }
      ]
    },
  ]
};

export default MiscHelpersData;