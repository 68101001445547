import {ToManyBitFxNote, MouseMoveNote, FullSizeParentNote, InfiniteAnimationMethods} from '../../data';

import getBitFxConfig,{BitFxSnippet} from './bitfx';

const joins = ['atom', 'molecule', 'nucleus'];

const BitSystemsConfig = getBitFxConfig('data-rwx-bit-system',
  [
    {
      key: 'data-rwx-bit-system-disable-init',
      type: 'checkbox',
      options: ['true', 'false'],
      display: "Disable init",
      value: "false",
      description: "Disables the initial appearing animation of the bits."
    },
    {
      key: 'data-rwx-bit-system-join',
      type: 'select',
      options: ['false', ...joins],
      display: "Join bits",
      value: "false",
      description: "Join the bits together with a semi transparent line in different formations."
    }
  ],
  ['value', 'orientation']
);

const BitSystemsData = {
  selector: "rwx-bit-system",
  config: BitSystemsConfig,
  componentType: "individualJS",
  roseworxPath: "bitfx",
  manualControl: true,
  roseworxCore: true,
  renew: true,
  notes: [ToManyBitFxNote, MouseMoveNote, FullSizeParentNote],
  methods: [
    {
      name: 'explode',
      returnType: 'void',
      description: 'This method explodes the particles out of view',
      example: ".explode('{ID}')",
      interactable: true,
      parameters: [
        {
          name: 'ID',
          type: 'String'
        }
      ]
    },
    {
      name: 'implode',
      returnType: 'void',
      description: 'This method implodes the particles back into view, only if they have been exploded.',
      example: ".implode('{ID}')",
      interactable: true,
      parameters: [
        {
          name: 'ID',
          type: 'String'
        }
      ]
    },
    ...InfiniteAnimationMethods
  ]
}

BitSystemsData.snippet = BitFxSnippet(BitSystemsData.selector, BitSystemsData.config.dataAttributes);

export default BitSystemsData;