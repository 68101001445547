import React, {useEffect, useRef} from 'react';

import { rwxAnimation, rwxAnimationChain } from 'roseworx/js/modules/rwxAnimation';

import TabbedLayout from '../../layouts/TabbedLayout';
import SteppedLayout from '../../layouts/SteppedLayout';

function AnimationPage({data}){

	const tabcontent = [
		{
			title: "Animation",
			content: <Animation data={data.animation} hashPrepend='animation'/>,
			id: 'animation'
		},
		{
			title: "Animation Chain",
			content: <AnimationChain data={data.animationChain} hashPrepend='animationChain'/>,
			id: 'animationChain'
		}
	];

	return (
		<TabbedLayout description={data.description} tabs={tabcontent} />
	)
}

function Animation(props) {

	const LIVEHTML = ({id, config}) => {
		const animationRef = useRef();

		useEffect(()=>{
			const easing  = config.demo.find((c)=>c.key==="easing").value;
			const duration = config.demo.find((c)=>c.key==="duration").value;
			const loop = config.demo.find((c)=>c.key==="loop").value;
			let stopAnimation = false;
			const animation = new rwxAnimation(
				{ 
					from: 10, 
					to: 80, 
					easing: easing, 
					duration: parseInt(duration),
					loop: loop!=="false"
				}
			);

			const animate = () =>{
				if(stopAnimation)return;
				if(animationRef.current)
				{
					animation.animate((t)=>{
						animationRef.current.style.top = `${t}%`;
					});
				}
				window.requestAnimationFrame(animate);
			}
			animate();
			return ()=>stopAnimation=true;
		}, [config])

		return (
			<div className="rwxu-full-size">
				<span className="rwxc-background-red rwxu-absolute-center" style={{width:"60px", top:"20%", height: "60px", borderRadius: "30px"}} ref={animationRef}></span>
			</div>
		)
	}

	return (
		<SteppedLayout {...props} HTML={LIVEHTML} component={{resourceName:'rwxAnimation'}} namedExport={true} />
	)
}

function AnimationChain(props) {
	const LIVEHTML = ({id, config}) =>{
		const animationRef = useRef();

		useEffect(()=>{
			const easing1  = config.demo.find((c)=>c.key==="chaineasing1").value;
			const duration1 = config.demo.find((c)=>c.key==="chainduration1").value;
			const delay1 = config.demo.find((c)=>c.key==="chaindelay1").value;
			const easing2  = config.demo.find((c)=>c.key==="chaineasing2").value;
			const duration2 = config.demo.find((c)=>c.key==="chainduration2").value;
			const delay2 = config.demo.find((c)=>c.key==="chaindelay2").value;
			const loop = config.demo.find((c)=>c.key==="chainloop").value;
			let stopAnimation = false;

			const animation = new rwxAnimationChain({
				sequence: [
					{ 
						from: 10, 
						to: 80, 
						easing: easing1,
						delay: delay1==="false" ? false : parseInt(delay1),
						duration: parseInt(duration1),
					},
					{
						from: 20,
						to: 80,
						easing: easing2,
						delay: delay2==="false" ? false : parseInt(delay2),
						duration: parseInt(duration2),
					}
				],
				loop: loop!=="false"
			});

			const animate = () =>{
				if(stopAnimation)return;
				if(animationRef.current)
				{
					animation.animate([
						(t)=>{animationRef.current.style.top = `${t}%`;animationRef.current.style.left = `20%`;},
						(l)=>{animationRef.current.style.left = `${l}%`;},
					])
				}
				window.requestAnimationFrame(animate);
			}
			animate();
			return ()=>stopAnimation=true;
		}, [config])

		return (
			<div className="rwxu-full-size">
				<span className="rwxc-background-red rwxu-absolute-center" style={{width:"60px", top:"20%", left:"20%", height: "60px", borderRadius: "30px"}} ref={animationRef}></span>
			</div>
		)
	}
	
	return (
		<SteppedLayout {...props} HTML={LIVEHTML} component={{resourceName:'rwxAnimationChain'}} namedExport={true}/>
	)
}

export default AnimationPage;