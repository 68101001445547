import rwxGeometry from 'roseworx/js/helpers/rwxGeometryHelpers';
import rwxCanvas from 'roseworx/js/helpers/rwxCanvasHelpers';

const CanvasHelpersSnippet = `
import rwxCanvas from 'roseworx/js/helpers/rwxCanvasHelpers';
`;

const ScaleSnippet = `
const canvas = document.getElementById('idofcanvas');
const ctx = canvas.getContext('2d');
const cwidth = 400;
const cheight = 400;
const {pixelRatio, width, height} = rwxCanvas.scale(canvas, ctx, cwidth, cheight);
`;

const DrawSectorSnippet = `
rwxCanvas.drawSector(ctx, { x: 150, y: 150 }, 150, rwxGeometry.toRadians(270), rwxGeometry.toRadians(360));
`;

const DrawSectorDemo = (ctx,w,h) => {
  rwxCanvas.drawSector(ctx, { x: 150, y: 150 }, 150, rwxGeometry.toRadians(270), rwxGeometry.toRadians(360));
}

const DrawArcSnippet = `
rwxCanvas.drawArc(ctx, { x: 150, y: 150 }, 150, 50, rwxGeometry.toRadians(180), rwxGeometry.toRadians(240));
`;

const DrawArcDemo = (ctx,w,h) => {
  rwxCanvas.drawArc(ctx, { x: 150, y: 150 }, 150, 50, rwxGeometry.toRadians(180), rwxGeometry.toRadians(240));
}

const CanvasHelpersData = {
  snippet: CanvasHelpersSnippet,
  methods: [
    {
      name: 'scale',
      returnType: 'Object {pixelRatio: Number, width: Number, height: Number}',
      description: 'Scales a canvas element to the specified width and height based on the users device pixel ratio so the user gets the best visual experience for the device they are viewing on.',
      example: ScaleSnippet,
      parameters: [
        {
          name: 'Canvas Node',
          type: 'HTMLElement'
        },
        {
          name: 'Context',
          type: 'CanvasRenderingContext2D'
        },
        {
          name: 'Width',
          type: `Number`   
        },
        {
          name: 'Height',
          type: `Number`   
        }
      ]
    },
    {
      name: 'drawSector',
      returnType: 'Void',
      description: 'Draws a circle sector on the supplied canvas rendering context.',
      example: DrawSectorSnippet,
      parameters: [
        {
          name: 'Context',
          type: 'CanvasRenderingContext2D'
        },
        {
          name: 'Sector Center Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Sector Outer Radius',
          type: 'Number'
        },
        {
          name: 'Start Angle (In Radians)',
          type: 'Number'
        },
        {
          name: 'End Angle (In Radians)',
          type: 'Number'
        }
      ],
      demo: DrawSectorDemo
    },
    {
      name: 'drawArc',
      returnType: 'Void',
      description: 'Draws a circle arc on the supplied canvas rendering context.',
      example: DrawArcSnippet,
      parameters: [
        {
          name: 'Context',
          type: 'CanvasRenderingContext2D'
        },
        {
          name: 'Arc Center Point',
          type: 'Object {x: Number , y: Number}'
        },
        {
          name: 'Arc Outer Radius',
          type: 'Number'
        },
        {
          name: 'Arc Depth',
          type: 'Number'
        },
        {
          name: 'Start Angle (In Radians)',
          type: 'Number'
        },
        {
          name: 'End Angle (In Radians)',
          type: 'Number'
        }
      ],
      demo: DrawArcDemo
    }
  ]
}

export default CanvasHelpersData;