import CodeSnippet from './CodeSnippet';

function ScssMixin({title, snippet, description}) {
	return (
		<div>
			<div className="rwx-split explicit align center-vertically">
				<div className="rwx-split-half">
					<CodeSnippet language="css" data={snippet} />
				</div>
				<div className="rwx-split-half">
					<p className="h6 rwxt-text-center">{title}</p>
					<p>{description}</p>
				</div>
			</div>
		</div>
	)
}

export default ScssMixin;