import React from 'react';

import StandardLayout from '../../layouts/StandardLayout';

import {parseClasses} from '../../helpers';

function ColorsPage({data}) {

	const HTML = ({config}) =>{
		const toParse = config.classes.filter((cc)=>cc.key==="rwxc-background-{color}" || cc.key==="rwxc-color-{color}");
		const borderColor = config.classes.find((cc)=>cc.key==="rwxc-border-{color}").value;
		const direction = config.classes.find((cc)=>cc.key==="rwxc-border-{direction}-{color}").value;
		const size = config.classes.find((cc)=>cc.key==="rwxc-border-{direction}-{color}-{size}").value;
		let str = `rwxc-border-${borderColor}`;
		if(borderColor!=="false")
		{
			if(direction!=="false" && size==="false")str=`rwxc-border-${direction}-${borderColor}`;
			else if(size!=="false" && direction==="false")str=`rwxc-border-${borderColor}-${size}`;
			else if(direction!=="false" && size!=="false")str=`rwxc-border-${direction}-${borderColor}-${size}`;
		}

		const parsed = parseClasses(toParse);

		return (
			<div className="rwxu-flex-center">
				<div className={`${parsed} rwxu-flex-center rwxs-p-sm ${str}`} style={{width:"200px", height:"200px"}}>
					<p className="rwxs-m-b-no h3">Color</p>
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={null} 
			LIVEHTML={HTML}
			useExamples={true}
		/>
	)
}

export default ColorsPage;