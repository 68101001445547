const GridGalleriesSnippet = `
<div class="rwx-grid-gallery" rwx-grid-gallery id="{{ UNIQUE ID }}">
  <div class="rwx-grid-gallery-item">
    <h5 class="rwx-grid-gallery-heading">{{ HEADING }}</h5>
    <div role="img" class="rwx-grid-gallery-overlay" style="background-image: url('{{ BACKGROUND IMAGE URL }}');"></div>
  </div>
  <div class="rwx-grid-gallery-item">
    <h5 class="rwx-grid-gallery-heading">{{ HEADING }}</h5>
    <div role="img" class="rwx-grid-gallery-overlay" style="background-image: url('{{ BACKGROUND IMAGE URL }}');"></div>
  </div>
  <div class="rwx-grid-gallery-item">
    <h5 class="rwx-grid-gallery-heading">{{ HEADING }}</h5>
    <div role="img" class="rwx-grid-gallery-overlay" style="background-image: url('{{ BACKGROUND IMAGE URL }}');"></div>
    <a href="{{ LINK }}" target="_blank">{{ LINK NAME }}</a>
  </div>
</div>
  `;

const GridGalleriesData = {
  selector: 'rwx-grid-gallery',
  componentType: 'individualJS',
  roseworxPath: 'core',
  manualControl: true,
  roseworxCore: true,
  renew: true,
  snippet: GridGalleriesSnippet,
  notes: [
    {
      note: "The maximum number of items inside a grid gallery is 14. If you need more then create a new rwx-grid-gallery component.",
      icon: "warning"
    },
    {
      note: "Use the css selector .rwx-grid-gallery-heading to change the background and text color of the heading if there is not enough contrast with the background image.",
      icon: "hint"
    },
    {
      note: `You can easily make a grid item clickable by inserting an <a> tag inside the .rwx-grid-gallery-item.`,
      icon: "star"
    }
  ]
}

export default GridGalleriesData;