import React, {useState, useRef, useEffect} from 'react';

import {useHistory} from 'react-router';

import {getHexFromColor} from '../helpers';

import {useSpring, animated} from 'react-spring';

function HubLink({link, description, heading, color}) {
  const history = useHistory();
  const styleRef = useRef();
  const acolor = useRef(color);
  const [focus, setFocus] = useState(false);
  const [style, setStyle] = useState({});

  const focussed = useSpring(
    {
      transform: focus ? 'scale(1.1)' : 'scale(1)', 
      config:{mass: 3, tension: 200, friction: 10}
    }
  );
  const line = useSpring(
    {
      width: focus ? '100%' : '0%', 
      left: focus ? '0%' : '50%',
    }
  );

  useEffect(()=>{
    const bo = styleRef.current.getBoundingClientRect();
    const width = bo.width;
    const height = bo.height;
    const c = getHexFromColor(acolor.current);
    setStyle({borderWidth: `0 ${width}px ${height}px 0`, borderColor:`transparent ${c} transparent transparent`})
  }, []);

  // onKEyDown if enter or space
  return (
    <animated.div onClick={()=>history.push(link)} className={`hub-link rwx-split no-wrap space-between vertical rwxc-border-${color}-md`} style={focussed} ref={styleRef} onFocus={()=>setFocus(true)} onBlur={()=>setFocus(false)} onMouseEnter={()=>setFocus(true)} onMouseLeave={()=>setFocus(false)}>
      <div className="rwx-split end explicit align">
        <div className="rwx-split-third-x2">
          <p className="rws-m-b-no h4 rwxt-text-right rwxs-p-md rwxc-color-white">{heading}</p>
        </div>
      </div>
      <div className="rwx-split explicit align">
        <div className="rwx-split-fifth-x3">
          <p className="rws-m-b-no rwxs-p-md">{description}</p>
        </div>
      </div>
      <div className="ident" style={style}></div>
      <animated.div className={`underline rwxc-background-${color}`} style={line}></animated.div>
      <button className="rwxu-full-size no-decoration" aria-label={heading} title={heading}></button>
    </animated.div>
  )
}

export default HubLink