const DOMHelpersSnippet = `
import rwxDOM from 'roseworx/js/helpers/rwxDOMHelpers';
`;

const HasAncestorSnippet = `
const element = document.getElementById('hasAncestorExample');
const Result = rwxDOM.hasAncestor(element, '.hasThisClass');
// Result: If ancestor with '.hasThisClass' is found this will be the found HTMLElement.
// Result: If no ancestor with '.hasThisClass' is found this will be false.
`;

const DOMHelpersData = {
  snippet: DOMHelpersSnippet,
  methods: [
    {
      name: 'hasAncestor',
      returnType: 'HTML Element || false',
      description: 'Checks to see if the specified element has an ancestor that matches the specified selector.',
      example: HasAncestorSnippet,
      parameters: [
        {
          name: 'Element',
          type: 'HTMLElement'
        },
        {
          name: 'Any valid CSS selector',
          type: 'String'
        }
      ]
    },
  ]
};

export default DOMHelpersData;