import {colors, colorvalues} from './data-servlets/core/Colors';

const colorsWhiteText = [
	'green',
	'red',
	'blue',
	'orange',
	'light-blue',
	'brown',
	'purple',
	'dark-grey',
	'pink',
	'silver'
];

const transitions = [
	"slow-in",
	"slow-in-out",
	"fast-in",
	"base",
	"out-back",
	"out-back-fast",
	"out-back-slow",
	"zip",
	"smooth"
];

const usableColors = colors.filter((c)=>c!=='black'&&c!=='white'&&c!=='grey');
const DemoColor = "#FF33CC";

const ToManyBitFxNote = {
	note: 'Be wary of performance issues when using too many BitFx components on one page.',
	icon: 'warning'
}

const MouseMoveNote = {
	note: 'This is an interactive component which responds to mouse movement.',
	icon: 'star'
}

const FullSizeParentNote = {
	note: `This component will scale to the full size of it's parent element.`,
	icon: 'note'
}

const InteractiveResponsiveNote = {
	note: 'Scale the screen size up and down to see the responsive classes at work in the interactive demo.',
	icon: 'star'
}

const NoJSRequiredNote = (component) => {
	return {
		note: `You do not have to import the JS file or use the [rwx-${component}] attribute. You can implement RWX ${component}s with no functionality and have styles only.`,
		icon: 'star'
	}
}

const AddFixedForFullScreen = (component) => {
	return {
		note: `Add position:fixed; to the ${component} element and it will be positioned relative to the viewport instead of it's parent element - providing no transform is applied to an ancestor of it.`,
		icon: 'hint'
	}
}

const AccesibilityNote = {
	note: 'This component is accessible and can be fully navigated using keyboard navigation.',
	icon: 'accessibility'
}

const HelperImportStepHeading = `Import the helper file...`;
const ImportStepHeading = `Import the code...`;
const MarkupStepHeading = `...Put this html in your document`;
const MarkupStepExtra = `Replace any {{ }} values with your personal preferences.`
const ConfigureStepHeading = `Personalise...`;
const ConfigureStepText = `Use the below options to configure the component the way you want.`;
const ExamplesStepHeading = `...Examples`;
const ExamplesStepExtra = `Here are some examples to demonstrate the implementation.`;

const LiveConfigurationTitle = `Play about with the configuration options to see how it looks.`;
const LiveFunctionTitle = `Run live functions on the element to see how it behaves.`;

const IDRecommendation = `If you intend on using the below methods, it is highly recommended you put a unique ID attribute on the element so you can identify which specific component you are running the methods on. If no ID attribute is present on the element at time of instantiation, Roseworx will internally assign it one and you will not know what it is.`;
const AfterPageLoad = 'Methods must be called on or after the window load event.';

const SCSSVariableMessage = `Make sure to specify these variables BEFORE the import of the roseworx SCSS file to ensure you override default.`;
const CoreAccessMessage = (ca, c)=>{
	return `If you imported the core roseworx JS file instead of the individual component file, access to these methods is through rwx.${ca} OR the global object window.rwx.${ca} not ${c}.`
}
const ComponentThatDoesntShowOnInitMessage = `Use the live interaction in the bottom left to see the component.`;

const IndividualSCSSImportText = `Import the individual SCSS file.`;
const IndividualJSImportText = `Import the individual JS file.`;
const CoreSCSSImportText = `Import the core SCSS file (if you haven't already).`;
const CoreJSImportText = `Either import the core js file (if you haven't already) or if you don't want the whole framework, import the individual component js file. The core SCSS file is required.`;

const OptionListHeading = `Possible Values`;

const LoremIpsum = `Lorem ipsum dolor sit amet, mei appareat qualisque repudiandae ne, esse putent accommodare vix no. Ad consul saperet vim. Diam nihil suscipiantur id eum, ius labore legimus cu, ex per ubique scribentur. Ne vix modo alienum, pri ad dolorum mediocrem conclusionemque. Vel accumsan officiis tincidunt ex, ad modus ceteros quaerendum sea.`;
const LoremIpsumLong = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;

const SearchPlaceholderText = `Search...`;
const SearchNoResultsText = `Sorry nothing matched your search, please try again.`;

const CopySectionLinkText = `Copy link to this section.`

const ManualControlMethod = {
	name: 'commence',
	returnType: 'void',
	description: `By default this component will commence only when it is scrolled in to the user's view. To prevent this behaviour - apply a data-rwx-manual-control attribute to the element. You can then use this commence method to programatically control when it starts.`,
	example: ".commence('{ID}');",
	parameters: [
		{
			name: 'ID',
			type: 'String'
		}
	]
}

const RenewMethod = {
	name: 'renew',
	returnType: 'void',
	description: `Reset the component to it's initial state and restarts it.`,
	example: ".renew('{ID}');",
	interactable: true,
	parameters: [
		{
			name: 'ID',
			type: 'String'
		}
	]
}

const HookMethod = {
	name: 'hook',
	returnType: 'void',
	description: 'This method converts the element with the specified ID to a Roseworx component. Roseworx scans the DOM on page load searching for elements with component data attributes, however, when using frameworks like React or VUE it is useful to be able to hook components with the specified ID after they have rendered or conditionally hook components based on dynamic data. You can omit the component data attribute if using this.',
	example: ".hook('{ID}');",
	parameters: [
		{
			name: 'ID',
			type: 'String'
		}
	]
}

const UnhookMethod = {
	name: 'unhook',
	returnType: 'void',
	description: 'Detaches Roseworx functionality from the component with the specified ID.',
	example: ".unhook('{ID}');",
	parameters: [
		{
			name: 'ID',
			type: 'String'
		}
	]
}

const PauseMethod = {
	name: 'pause',
	returnType: 'void',
	description: 'Pauses the animation loop for this component.',
	example: ".pause('{ID}');",
	interactable: true,
	parameters: [
		{
			name: 'ID',
			type: 'String'
		}
	]
}

const PlayMethod = {
	name: 'play',
	returnType: 'void',
	description: 'Starts the animation loop for this component.',
	example: ".play('{ID}');",
	interactable: true,
	parameters: [
		{
			name: 'ID',
			type: 'String'
		}
	]
}

const InfiniteAnimationMethods = [PauseMethod, PlayMethod];
const CoreMethods = [HookMethod, UnhookMethod];

export {
	colors,
	transitions,
	colorvalues,
	colorsWhiteText,
	usableColors,
	ToManyBitFxNote,
	MouseMoveNote,
	FullSizeParentNote,
	AddFixedForFullScreen,
	InteractiveResponsiveNote,
	NoJSRequiredNote,
	HelperImportStepHeading,
	ImportStepHeading,
	MarkupStepHeading,
	MarkupStepExtra,
	ConfigureStepHeading,
	ConfigureStepText,
	ExamplesStepHeading,
	ExamplesStepExtra,
	AccesibilityNote,
	SCSSVariableMessage,
	CoreAccessMessage,
	IDRecommendation,
	ComponentThatDoesntShowOnInitMessage,
	AfterPageLoad,
	LiveConfigurationTitle,
	LiveFunctionTitle,
	IndividualSCSSImportText,
	IndividualJSImportText,
	CoreJSImportText,
	CoreSCSSImportText,
	CopySectionLinkText,
	LoremIpsum,
	LoremIpsumLong,
	OptionListHeading,
	SearchPlaceholderText,
	SearchNoResultsText,
	ManualControlMethod,
	RenewMethod,
	CoreMethods,
	InfiniteAnimationMethods,
	DemoColor
};
