import {NoJSRequiredNote} from '../../data';

  const TablesSnippet = `
<div class="rwx-table" role="table" rwx-table id="{{ UNIQUE ID }}">
  <div class="rwx-table-data" role="row">
    <span role="cell">{{ TABLE DATA TEXT }}</span>
    <span role="cell">{{ TABLE DATA TEXT }}</span>
  </div>
  <div class="rwx-table-data" role="row">
    <span role="cell">{{ TABLE DATA TEXT }}</span>
    <span role="cell" class="--x2">{{ TABLE DATA TEXT }}</span>
  </div>
  <div class="rwx-table-data" role="row">
    <span role="cell">{{ TABLE DATA TEXT }}</span>
    <span role="cell" class="--x3">{{ TABLE DATA TEXT }}</span>
  </div>
</div>
  `;

const TablesData = {
  selector: "rwx-table",
  componentType: "coreJSAndSCSS",
  coreAccessName: "tables",
  roseworxPath: "core",
  manualControl: false,
  roseworxCore: true,
  renew: false,
  snippet: TablesSnippet,
  config: {
    dataAttributes: [
      {
        key: 'data-rwx-table-no-stick',
        type: 'checkbox',
        value: "false",
        options: ['true', 'false'],
        display: "No Stick",
        description: "Whether or not the table headings stick when scrolled."
      },
      {
        key: 'data-rwx-table-no-mist',
        type: 'checkbox',
        value: "false",
        options: ['true', 'false'],
        display: "No Mist",
        description: "Whether or not to display the 'misting' effect indicating the table has more content."
      },
    ],
    classes: [
      {
        key: '--slanted',
        type: 'checkbox',
        value: "false",
        display: "Slanted headers",
        description: "Slant the table headers."
      },
      {
        key: '--vertical',
        type: 'checkbox',
        value: "false",
        display: "Vertical",
        description: "Switches table headers from horizontal first row to vertical first column."
      },
      {
        key: '--highlightable',
        type: 'checkbox',
        value: "false",
        display: "Highlightable",
        description: "When hovered, the table rows will highlight with the value of SCSS variable $table-color-highlight."
      },
      {
        key: '--dual-headings',
        type: 'checkbox',
        value: "false",
        display: "Dual Headings",
        description: "Makes first row and first column table headers."
      },
      {
        key: '--full-grid',
        type: 'checkbox',
        value: "false",
        display: "Full grid",
        description: "Table borders become a full grid style."
      },
      {
        key: '--colored',
        type: 'checkbox',
        value: "false",
        display: "Colored",
        description: "Every even rows background will color with the value of SCSS variable $table-color-highlight."
      },
      {
        key: '--vertical-line',
        type: 'checkbox',
        value: "false",
        display: "Vertical line",
        description: "Switches the 'emphasis line' from horizontal to vertical."
      },
      {
        key: '--x2',
        type: 'checkbox',
        value: "false",
        display: "Data size x2",
        description: "Instead of equal size, this will make a particualar table data double the size of others.",
        applyToSelector: ".rwx-table .rwx-table-data span"
      },
      {
        key: '--x3',
        type: 'checkbox',
        value: "false",
        display: "Data size x3",
        description: "Instead of equal size, this will make a particualar table data triple the size of others.",
        applyToSelector: ".rwx-table .rwx-table-data span"
      },
    ] 
  },
  scssVariables: [
    {
      key: "$table-color-base",
      description: "Used for tables header lines.",
      value: "$dark-grey",
      options: ["Any valid CSS color"]
    },
    {
      key: "$table-color-secondary",
      description: "Used for the shadow of 'sticky' headers & the color of a normal table cells' border.",
      value: "$grey",
      options: ["Any valid CSS color"]
    },
    {
      key: "$table-color-highlight",
      description: "The color of even rows if --colored class modifier is applied & the color that rows are highlighted if --highlightable class modifier is applied.",
      value: "rgba($light-blue, 0.2)",
      options: ["Any valid CSS color"]
    },
    {
      key: "$table-height-limit",
      description: "The height limit for the table to allow scrolling, if [rwx-table] attribute is present. To change this on an indiviudal table basis, apply an overriding max-height style to the element.",
      value: "500px",
      options: ["Any valid CSS length"]
    },
    {
      key: "$table-cell-width-limit",
      description: "The minimum width for each table cell up to 'md' breakpoint.",
      value: "200px",
      options: ["Any valid CSS length"]
    },
  ],
  notes: [
    {
      note: "A .rwx-table-data row can be an <a> tag with .no-decoration class if the table row needs to be clickable. (Works well with the .--highlightable modifier class on the rwx-table).",
      icon: "hint"
    },
    {
      note: ".--slanted modifier class does not work well with long table headers.",
      icon: "warning"
    },
    NoJSRequiredNote('table'),
    {
      note: "Applying the rwx-table attribute will restrict the tables height to a configurable size. This will allow the table itself to be scrollable if it exceeds that height (or it would never be scrollable and just grow down the page).",
      icon: 'note'
    }
  ]
}

export default TablesData;