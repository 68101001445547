import React from 'react';

import rwxSlideshows from 'roseworx/js/components/rwxSlideshows';

import StandardLayout from '../../layouts/StandardLayout';

import {LoremIpsumLong} from '../../data';

function SlideshowsPage({data}) {
	const slides = Array.from(Array(10).keys());

	const Slide = ({index}) => {
		return (
			<div className="slide">
				<h1 className="slide-title">Slide {index+1}</h1>
				<div className="slide-content rwxs-p-lg">
					<p>{LoremIpsumLong}</p>
				</div>
			</div>
		)
	}

	const HTML = ({id, config}) =>{
		return (
			<div className="rwxu-full-size">
				<div id={id} className="rwx-slideshow">
					{
						slides.map((i)=><Slide key={`slide-demo-${i}`} index={i}/>)
					}
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxSlideshows} 
			LIVEHTML={HTML}
		/>
	)
}

export default SlideshowsPage;