import EffectsPage from './pages/Effects.js';
import HomePage from './pages/Home.js';
import ToolboxPage from './pages/Toolbox.js';
import AnimatedBordersPage from './pages/_effects/AnimatedBorders.js';
import FadePage from './pages/_effects/Fade.js';
import LavaLampsPage from './pages/_effects/LavaLamps.js';
import LoadingPage from './pages/_effects/Loading.js';
import MistsPage from './pages/_effects/Mists.js';
import SlidePage from './pages/_effects/Slide.js';
import CanvasHelpersPage from './pages/_toolbox/CanvasHelpers.js';
import CssPage from './pages/_toolbox/Css.js';
import DOMHelpersPage from './pages/_toolbox/DOMHelpers.js';
import GeometryHelpersPage from './pages/_toolbox/GeometryHelpers.js';
import MathHelpersPage from './pages/_toolbox/MathHelpers.js';
import MiscHelpersPage from './pages/_toolbox/MiscHelpers.js';
import ScssPage from './pages/_toolbox/Scss.js';
import ThemesPage from './pages/_toolbox/Themes.js';
import BitBlackHolesPage from './pages/bitfx/BitBlackHoles.js';
import BitExplosionsPage from './pages/bitfx/BitExplosions.js';
import BitSwarmsPage from './pages/bitfx/BitSwarms.js';
import BitSystemsPage from './pages/bitfx/BitSystems.js';
import CountOMetersPage from './pages/components/CountOMeters.js';
import GridGalleriesPage from './pages/components/GridGalleries.js';
import LuckyDipsPage from './pages/components/LuckyDips.js';
import NoticeBoxesPage from './pages/components/NoticeBoxes.js';
import NumberSwitchersPage from './pages/components/NumberSwitchers.js';
import OptionSelectorsPage from './pages/components/OptionSelectors.js';
import PhotoTilesPage from './pages/components/PhotoTiles.js';
import SlideshowsPage from './pages/components/Slideshows.js';
import SlideTickersPage from './pages/components/SlideTickers.js';
import TimelinesPage from './pages/components/Timelines.js';
import ButtonsPage from './pages/core/Buttons.js';
import ColorsPage from './pages/core/Colors.js';
import FormsPage from './pages/core/Forms.js';
import GridPage from './pages/core/Grid.js';
import SlidersPage from './pages/core/Sliders.js';
import SpacingsPage from './pages/core/Spacings.js';
import TablesPage from './pages/core/Tables.js';
import TabsPage from './pages/core/Tabs.js';
import TypePage from './pages/core/Type.js';
import UtilitiesPage from './pages/core/Utilities.js';
import AnimationPage from './pages/modules/Animation.js';
import PreloadPage from './pages/modules/Preload.js';
import SkrollHighjackPage from './pages/modules/SkrollHighjack.js';
import SkrollXPage from './pages/modules/SkrollX.js';

const nav = [[{
            slideNumber: 2,
            text: '_effects',
          },{
            slideNumber: 3,
            text: '_toolbox',
          },{
            slideNumber: 4,
            text: 'Bitfx',
          },{
            slideNumber: 5,
            text: 'Components',
          },{
            slideNumber: 6,
            text: 'Core',
          },{
            link: '/effects',
            text: 'Effects',
            component: EffectsPage,
            dataName: 'EffectsData'
          },{
            link: '/home',
            text: 'Home',
            component: HomePage,
            dataName: 'HomeData'
          },{
            slideNumber: 7,
            text: 'Modules',
          },{
            link: '/toolbox',
            text: 'Toolbox',
            component: ToolboxPage,
            dataName: 'ToolboxData'
          }],[{
            link: '/_effects/animatedborders',
            text: 'Animated Borders',
            component: AnimatedBordersPage,
            dataName: 'AnimatedBordersData'
          },{
            link: '/_effects/fade',
            text: 'Fade',
            component: FadePage,
            dataName: 'FadeData'
          },{
            link: '/_effects/lavalamps',
            text: 'Lava Lamps',
            component: LavaLampsPage,
            dataName: 'LavaLampsData'
          },{
            link: '/_effects/loading',
            text: 'Loading',
            component: LoadingPage,
            dataName: 'LoadingData'
          },{
            link: '/_effects/mists',
            text: 'Mists',
            component: MistsPage,
            dataName: 'MistsData'
          },{
            link: '/_effects/slide',
            text: 'Slide',
            component: SlidePage,
            dataName: 'SlideData'
          }],[{
            link: '/_toolbox/canvashelpers',
            text: 'Canvas Helpers',
            component: CanvasHelpersPage,
            dataName: 'CanvasHelpersData'
          },{
            link: '/_toolbox/css',
            text: 'Css',
            component: CssPage,
            dataName: 'CssData'
          },{
            link: '/_toolbox/domhelpers',
            text: 'D O M Helpers',
            component: DOMHelpersPage,
            dataName: 'DOMHelpersData'
          },{
            link: '/_toolbox/geometryhelpers',
            text: 'Geometry Helpers',
            component: GeometryHelpersPage,
            dataName: 'GeometryHelpersData'
          },{
            link: '/_toolbox/mathhelpers',
            text: 'Math Helpers',
            component: MathHelpersPage,
            dataName: 'MathHelpersData'
          },{
            link: '/_toolbox/mischelpers',
            text: 'Misc Helpers',
            component: MiscHelpersPage,
            dataName: 'MiscHelpersData'
          },{
            link: '/_toolbox/scss',
            text: 'Scss',
            component: ScssPage,
            dataName: 'ScssData'
          },{
            link: '/_toolbox/themes',
            text: 'Themes',
            component: ThemesPage,
            dataName: 'ThemesData'
          }],[{
            link: '/bitfx/bitblackholes',
            text: 'Bit Black Holes',
            component: BitBlackHolesPage,
            dataName: 'BitBlackHolesData'
          },{
            link: '/bitfx/bitexplosions',
            text: 'Bit Explosions',
            component: BitExplosionsPage,
            dataName: 'BitExplosionsData'
          },{
            link: '/bitfx/bitswarms',
            text: 'Bit Swarms',
            component: BitSwarmsPage,
            dataName: 'BitSwarmsData'
          },{
            link: '/bitfx/bitsystems',
            text: 'Bit Systems',
            component: BitSystemsPage,
            dataName: 'BitSystemsData'
          }],[{
            link: '/components/countometers',
            text: 'Count O Meters',
            component: CountOMetersPage,
            dataName: 'CountOMetersData'
          },{
            link: '/components/gridgalleries',
            text: 'Grid Galleries',
            component: GridGalleriesPage,
            dataName: 'GridGalleriesData'
          },{
            link: '/components/luckydips',
            text: 'Lucky Dips',
            component: LuckyDipsPage,
            dataName: 'LuckyDipsData'
          },{
            link: '/components/noticeboxes',
            text: 'Notice Boxes',
            component: NoticeBoxesPage,
            dataName: 'NoticeBoxesData'
          },{
            link: '/components/numberswitchers',
            text: 'Number Switchers',
            component: NumberSwitchersPage,
            dataName: 'NumberSwitchersData'
          },{
            link: '/components/optionselectors',
            text: 'Option Selectors',
            component: OptionSelectorsPage,
            dataName: 'OptionSelectorsData'
          },{
            link: '/components/phototiles',
            text: 'Photo Tiles',
            component: PhotoTilesPage,
            dataName: 'PhotoTilesData'
          },{
            link: '/components/slideshows',
            text: 'Slideshows',
            component: SlideshowsPage,
            dataName: 'SlideshowsData'
          },{
            link: '/components/slidetickers',
            text: 'Slide Tickers',
            component: SlideTickersPage,
            dataName: 'SlideTickersData'
          },{
            link: '/components/timelines',
            text: 'Timelines',
            component: TimelinesPage,
            dataName: 'TimelinesData'
          }],[{
            link: '/core/buttons',
            text: 'Buttons',
            component: ButtonsPage,
            dataName: 'ButtonsData'
          },{
            link: '/core/colors',
            text: 'Colors',
            component: ColorsPage,
            dataName: 'ColorsData'
          },{
            link: '/core/forms',
            text: 'Forms',
            component: FormsPage,
            dataName: 'FormsData'
          },{
            link: '/core/grid',
            text: 'Grid',
            component: GridPage,
            dataName: 'GridData'
          },{
            link: '/core/sliders',
            text: 'Sliders',
            component: SlidersPage,
            dataName: 'SlidersData'
          },{
            link: '/core/spacings',
            text: 'Spacings',
            component: SpacingsPage,
            dataName: 'SpacingsData'
          },{
            link: '/core/tables',
            text: 'Tables',
            component: TablesPage,
            dataName: 'TablesData'
          },{
            link: '/core/tabs',
            text: 'Tabs',
            component: TabsPage,
            dataName: 'TabsData'
          },{
            link: '/core/type',
            text: 'Type',
            component: TypePage,
            dataName: 'TypeData'
          },{
            link: '/core/utilities',
            text: 'Utilities',
            component: UtilitiesPage,
            dataName: 'UtilitiesData'
          }],[{
            link: '/modules/animation',
            text: 'Animation',
            component: AnimationPage,
            dataName: 'AnimationData'
          },{
            link: '/modules/preload',
            text: 'Preload',
            component: PreloadPage,
            dataName: 'PreloadData'
          },{
            link: '/modules/skrollhighjack',
            text: 'Skroll Highjack',
            component: SkrollHighjackPage,
            dataName: 'SkrollHighjackData'
          },{
            link: '/modules/skrollx',
            text: 'Skroll X',
            component: SkrollXPage,
            dataName: 'SkrollXData'
          }]];

const routes = nav.flat().filter((n)=>n.link)

export {nav, routes};