import React from 'react';

import rwxNumberSwitchers from 'roseworx/js/components/rwxNumberSwitchers';

import StandardLayout from '../../layouts/StandardLayout';

import {parseDataAttributes, parseClasses} from '../../helpers';

function NumberSwitchersPage({data}) {
	const HTML = ({id, config}) =>{

		return (
			<div className="rwxu-flex-center">
				<div className={`rwx-number-switcher ${parseClasses(config.classes)}`} id={id} {...parseDataAttributes(config.dataAttributes)}>
					<i className="rwxi-star"></i>
				</div>
			</div>
		)
	}

	return (
		<StandardLayout 
			data={data} 
			rwxComponent={rwxNumberSwitchers} 
			LIVEHTML={HTML}
		/> 
	)
}

export default NumberSwitchersPage;