import React, {useState} from 'react';

import {useCodeHighlight, useCopy} from '../hooks';

function CodeSnippet({language, data, copyLeft}) {
	const copy = useCopy();
	const [clicked, setClicked] = useState(false);
	const d = useCodeHighlight(data, language);

	const click = ()=>{
		setClicked(true);
		setTimeout(()=>{setClicked(false)}, 500);
		copy(data, language);
	}

	return (
		<div className={`code-snippet ${clicked ? 'clicked' : ''}`} onClick={click}>
			<pre className={`language-${language} radius`}>
				<code className={`language-${language}`} dangerouslySetInnerHTML={{__html: d}}></code>
			</pre>
			<div className={`code-snippet-copy-container ${copyLeft ? 'left' : ''}`}>
			<button className={`code-snippet-copy no-decoration rwxc-background-white ${copyLeft ? 'left' : ''}`}>
				<i className="rwxi-copy-fill --orange" />
			</button>
			</div>
		</div>
	)
}

export default CodeSnippet;